import {qs} from 'helpers'
import React, {useMemo} from 'react'
import {MdClose} from 'react-icons/all'
import {useHistory, useLocation} from 'react-router-dom'
import {Button} from '@evet/library'

export const AppliedFilters = ({activeFilter, Postfix = null, ...props}) => {
  const {exceptions = [], hocParams = null} = props
  const history = useHistory()
  const {search} = useLocation()
  const params = useMemo(
    () => (hocParams ? {...hocParams} : qs.parse(search)),
    [hocParams, search]
  )
  const allExceptions = [
    ...exceptions,
    ...Object.keys(params).filter(i => i.endsWith('_labels')),
  ]
  const clearableFilters = Object.entries(params).filter(
    ([key, _]) => !allExceptions.includes(key)
  )

  const arrays = useMemo(
    () =>
      Object.entries(params)
        .filter(([_, val]) => typeof val === 'object')
        .map(([key, _]) => key),
    [params]
  )

  const handleClear = () => {
    history.push('?')
  }

  const removeSelectedFilter = e => {
    const {key, val} = e.currentTarget.dataset
    if (arrays.includes(key) && params[key].length > 1) {
      params[key].splice(params[key].indexOf(val), 1)

      const labels = JSON.parse(params[key + '_labels'] || '{}')
      if (Object.keys(labels).length) {
        delete labels[val]
        params[key + '_labels'] = JSON.stringify(labels)
      }
    } else {
      delete params[key]
      delete params[key + '_labels']
    }

    history.push('?' + qs.stringify(params))
  }

  return (
    <div className="applied-filters ivy left stretch  per-100">
      {clearableFilters.map(([key, val]) => {
        const labels = JSON.parse(params[key + '_labels'] || '{}')

        return arrays.includes(key) ? (
          val.map(i => (
            <div
              key={'applied-filters-' + key + i}
              className="item"
              data-key={key}
              data-val={i}
              onClick={removeSelectedFilter}>
              <div className="filtered vertical left">
                <span className="key">{labels['__queryName'] || key}</span>
                <span>{labels[i] || i}</span>
              </div>
              <div className="delete-button">
                <MdClose />
              </div>
            </div>
          ))
        ) : (
          <div
            key={'applied-filters-' + key + val}
            className="item"
            data-key={key}
            data-val={val}
            onClick={removeSelectedFilter}>
            <div className="filtered vertical left">
              <span className="key">{labels['__queryName'] || key}</span>
              <span>{String(labels[val] || val)}</span>
            </div>
            <div className="delete-button">
              <MdClose />
            </div>
          </div>
        )
      })}
      {clearableFilters.length ? (
        <React.Fragment>
          <Button className="basic-button mr-10" onClick={handleClear}>
            Clear Filter
          </Button>
          {Postfix && <Postfix />}
        </React.Fragment>
      ) : null}
    </div>
  )
}
