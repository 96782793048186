import React from 'react'
import TabMenuPage from '../../components/TabMenuPage'
import {inject, observer} from 'mobx-react'
import {FaStoreAlt} from 'react-icons/all'

const AdvertisersSales = ({data}) => (
  <div id="scrollBar" className="highest-rated-base top-brands">
    <table className="list-table large">
      <thead>
        <tr>
          <td>Property</td>
          <td>Sales</td>
        </tr>
      </thead>
      <tbody>
        {/*TODO KEY*/}
        {/*TODO This will need to be come as array, not object*/}
        {Object.values(data || {}).map((i, index) => (
          <tr key={'advertisersSales-' + index}>
            <td>{i.publisher_name}</td>
            <td>{i.publisher_sum}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
)

const PublisherAdvertiser = ({data}) => (
  <div id="scrollBar" className="highest-rated-base top-pages">
    <table className="list-table large">
      <thead>
        <tr>
          <td>Advertiser</td>
          <td># of Publisher</td>
        </tr>
      </thead>
      <tbody>
        {[...Array(10)].map(i => (
          <tr>
            <td>?</td>
            <td>?</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
)
const ApprovedNetworkEarning = ({data}) => (
  <div id="scrollBar" className="highest-rated-base top-pages">
    <table className="list-table large">
      <thead>
        <tr>
          <td>Network</td>
          <td>Invoice</td>
        </tr>
      </thead>
      <tbody>
        {/*TODO KEY*/}
        {(data || []).map((i, index) => (
          <tr key={'ApprovedNetworkEarning-' + index}>
            <td>?</td>
            <td>{i.commission_amount_usd}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
)

export const HighestRated = inject(
  'UIStore',
  'AdvertiserStore',
  'FilterStore'
)(
  observer(props => {
    const {networkEarnings, advertiserSales} = props
    const tabs = [
      {
        label: {name: 'TOP Advertiser Sales', icon: <FaStoreAlt />},
        content: <AdvertisersSales data={advertiserSales} />,
      },
      {
        label: {name: 'TOP Publisher Advertiser', icon: <FaStoreAlt />},
        content: <PublisherAdvertiser />,
      },
      {
        label: {name: 'Approved Network Earning', icon: <FaStoreAlt />},
        content: <ApprovedNetworkEarning data={networkEarnings} />,
      },
    ]
    return <TabMenuPage className="p-0" tabs={tabs} />
  })
)
