import {inject, observer} from 'mobx-react'
import React from 'react'
import {Button, Modal} from 'components'
import {
  Form,
  Input as CustomInput,
  Select as CustomSelect,
  Submit,
} from '@evet/library'
import * as Yup from 'yup'

const STEP = 2

export const Publisher = inject('FilterStore')(
  observer(
    ({
      nextStep,
      prevStep,
      onClose,
      data = {},
      initialStep,
      isEditing,
      ...props
    }) => {
      const {countries, categories} = props.FilterStore

      const defaultValues = {
        'publisher.country_code': data.country_code || '',
        'publisher.status': data.status || '',
        'publisher.name': data.name || '',
        'publisher.service_areas': data.service_areas
          ? data.service_areas
          : data.country_code
          ? [data.country_code]
          : [],
        'publisher.categories': data.categories || [],
        ...data,
      }

      const schema = Yup.object().shape({
        publisher: Yup.object({
          name: Yup.string().required('required'),
          country_code: Yup.string().required('required'),
          service_areas: Yup.array().required('required'),
          categories: Yup.array().required('required'),
        }),
      })

      return (
        <Modal
          onClose={onClose}
          title={isEditing ? 'Edit Publisher' : 'Add Publisher'}>
          <Form
            schema={schema}
            defaultValues={defaultValues}
            onSubmit={nextStep}
            className="vertical left top p-10">
            <div className="per-100 vertical between top">
              <div className="vertical top  per-100">
                <div className="horizon between top per-100 mt-10">
                  <CustomInput
                    className={isEditing ? 'per-32' : 'per-48'}
                    label="Name"
                    name="publisher.name"
                  />
                  <CustomSelect
                    className={isEditing ? 'per-32' : 'per-48'}
                    label="Region"
                    name="publisher.country_code"
                    options={countries}
                  />

                  {isEditing && (
                    <CustomSelect
                      className="per-32"
                      label="Status"
                      name="publisher.status"
                      options={[
                        {label: 'Approved', value: 'APPROVED'},
                        {label: 'Pending', value: 'PENDING'},
                        {label: 'Joined', value: 'JOINED'},
                      ]}
                    />
                  )}
                </div>
                <div className="horizon between top per-100 mt-10">
                  <CustomSelect
                    className="per-48"
                    label="Services Area (*multiple)"
                    name="publisher.service_areas"
                    options={countries}
                    multi
                    dropdownType="alwaysOpen"
                  />
                  <CustomSelect
                    className="per-48"
                    label="Categories"
                    name="publisher.categories"
                    options={categories}
                    multi
                    dropdownType="alwaysOpen"
                  />
                </div>
              </div>

              <div className="horizon right middle per-100 mt-10">
                {STEP > initialStep && (
                  <Button className="secondary-button" onClick={prevStep}>
                    Back
                  </Button>
                )}
                <Submit className="ml-6">
                  {isEditing ? 'Update' : 'Continue'}
                </Submit>
              </div>
            </div>
          </Form>
        </Modal>
      )
    }
  )
)
