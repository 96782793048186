import {qs, queryStringFormatter} from '@evet/library'
import {Filter} from 'components'
import {AppliedFilters} from 'components/Filter/AppliedFilters'
import React from 'react'
import {useLocation} from 'react-router-dom'
import {Check, DateRange, Searchable, Selectable, Text} from './Elements'

// import moment from 'moment'

const DATE_RANGES = [
  'date_range',
  'date_start',
  'date_end',
  'group_date_range',
  'group_date_start',
  'group_date_end',
]

const APPLIED_FILTER_EXCEPTIONS = [
  'report_type',
  'group_by',
  'offset',
  'field',
  ...DATE_RANGES,
]

export const DynamicFilter = ({
  children,
  elements,
  prefix = null,
  activeFilter,
  SaveFilter,
  hide = false,
  defaultParams = {},
  appliedFilterExceptions = [],
}) => {
  const {search} = useLocation()

  const params = queryStringFormatter({
    params: {...defaultParams, ...qs.parse(search)},
    array: elements.filter(i => i.multi).map(i => i.field),
    boolean: elements.filter(i => i.type === 'check').map(i => i.field),
  })

  return (
    <React.Fragment>
      {children}
      <Filter
        disableAppliedFilters
        className={'vertical center top ' + (hide && 'd-hide')}
        type="horizon"
        defaultValues={{
          ...params,
          has_return: params.has_return === 'true',
        }}
        submitOnChange={false}>
        <div className="section per-100 pb-0 mb-0">
          <div className="transactions-filter-grid per-100 ">
            {prefix}
            {elements.map(i => (
              <div key={'filter_' + i.field} className="item">
                {i.type === 'date_range' ? (
                  <DateRange
                    label={i.label}
                    name={i.field}
                    // ranges={i.options.map(i => ({
                    //   label: i.name,
                    //   rangeValue: i.value,
                    //   exactDates: i.values.map(i => new DateTime(i)),
                    // }))}
                    // defaultValues={i.field.map(i => params[i])}
                    // disabledDate={{
                    //   after: moment().endOf('month').endOf('days').toDate(),
                    // }}
                  />
                ) : i.type === 'selectable' ? (
                  <Selectable
                    label={i.name || i.label}
                    name={i.field}
                    // placeholder={i.name}
                    multi={i.multi}
                    options={i.options.map(i => ({label: i.name, ...i}))}
                  />
                ) : i.type === 'searchable' ? (
                  <Searchable
                    label={i.name || i.label}
                    name={i.field}
                    // placeholder={i.name}
                    multi={i.multi}
                  />
                ) : i.type === 'check' ? (
                  <Check
                    label={i.name || i.label}
                    name={i.field}
                    // placeholder={i.name}
                  />
                ) : (
                  <Text
                    label={i.name || i.label}
                    name={i.field}
                    // placeholder={i.name}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      </Filter>
      <div className="mb-20">
        <AppliedFilters
          activeFilter={activeFilter}
          Postfix={SaveFilter} // A component for the end if the list
          hocParams={params}
          exceptions={[
            ...APPLIED_FILTER_EXCEPTIONS,
            ...appliedFilterExceptions,
          ]}
          withSave
        />
      </div>
    </React.Fragment>
  )
}
