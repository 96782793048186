import {
  Form,
  Input as CustomInput,
  Select as CustomSelect,
  Submit,
} from '@evet/library'
import {Modal} from 'components'
import {inject, observer} from 'mobx-react'
import React from 'react'

export const PropertyAdd = inject(
  'FilterStore',
  'ClientStore'
)(
  observer(props => {
    const {to} = props
    const {countries} = props.FilterStore

    const handleSubmit = async data => await props.ClientStore.addContact(data)
    return (
      <Modal to={to} title="New Property">
        <Form
          defaultValues={{}}
          onSubmit={handleSubmit}
          className="per-100 vertical between top p-10 border-box">
          <div className="horizon between per-100 top">
            <div className="vertical left per-48 mt-10">
              <CustomInput className="per-100" label="Name" name="name" />
              <CustomInput
                className="per-100 mt-10"
                label="Phone"
                name="phone"
              />
              <CustomInput
                className="per-100 mt-10"
                label="Email"
                name="email"
              />
              <CustomInput
                className="per-100 mt-10"
                label="Region"
                name="region"
              />
            </div>
            <div className="vertical left per-48 mt-10">
              <CustomInput className="per-100" label="City" name="city" />
              <CustomInput
                className="per-100 mt-10"
                label="Address"
                name="address"
              />
              <CustomSelect
                className="per-100 mt-10"
                label="Country"
                name="country_code"
                options={countries}
              />
            </div>
          </div>
          <div className="vertical right per-100 mt-10">
            <Submit>Submit</Submit>
          </div>
        </Form>
      </Modal>
    )
  })
)
