import {action, computed, makeObservable, observable} from 'mobx'
import {Network as Model} from '../models'
import {NetworkServices as Service} from '../services'

export default class NetworkStore {
  _list = new observable.map()
  state = 'initial'
  meta = {}

  constructor(Stores) {
    this.stores = Stores

    makeObservable(this, {
      _list: observable.deep,
      state: observable,
      meta: observable,
      getItemById: observable,

      list: computed,

      read: action,

      fetchSuccess: action.bound,
      handleError: action.bound,
    })
  }

  get list() {
    return [...this._list.values()]
  }

  read({id = null, params = {}}) {
    if (!id) return
    if (!this._list.has(id)) this.state = 'getting'
    return Service.read({id, params}).then(
      res => this.fetchSuccess({id, ...res}),
      this.handleError
    )
  }

  fetchSuccess(res) {
    // if (res.items) {
    // 	this._list = new observable.map()
    // 	res.items.forEach(i => {
    // 		const item = new Model(i || {})
    // 		this._list.set(item.id, item)
    // 	})
    // }
    const item = new Model(res || {})
    this._list.set(item.id, item)
    this.state = 'done'
  }

  handleError(error) {
    this.state = 'error'
    return this.stores.SystemMessageStore.handleError(error)
  }

  getItemById = id => {
    return this._list.get(id) || new Model({})
  }
}
