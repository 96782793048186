import React, {useState} from 'react'
import {MdKeyboardArrowDown} from 'react-icons/md'
// import {Link} from 'react-router-dom'
import {Item} from './Item'
import {commissionParser} from 'helpers'
import {LazyLoad} from 'components'
import {MoreLess} from '@evet/library'
import {Button} from 'components'
import noLogo from 'images/no-logo.png'

export const Card = props => {
  const [isOpen, setOpen] = useState(false)
  const {data} = props
  const {id, name} = data
  const network_advertiser = data.primary_network_advertiser || {}

  const [networkCommissionText, evetCommissionText] = commissionParser(
    network_advertiser || {}
  )

  const media = data.logo_url ? '//' + data.logo_url : noLogo
  const showMore = () => {
    setOpen(!isOpen)
  }

  return (
    <div className={isOpen ? 'advertiser-card opened' : 'advertiser-card'}>
      <div className="card-base">
        <div className="title">
          <LazyLoad>
            <img alt="" src={media} />
          </LazyLoad>
          <div className="detail">
            <span>ID {data.id}</span>
            <p>{data.name}</p>
            <a href={data.website} target="_blank" rel="noreferrer noopener">
              {data.website}
            </a>
          </div>
        </div>
        <div className="data">
          <div className="item vertical left middle ">
            <table className=" list-table-card">
              <tbody>
                <tr>
                  <td className="sz-12">Network</td>
                  <td className="sz-12">
                    :
                    <MoreLess
                      maxLimit="12"
                      content={(
                        network_advertiser.network_id || ''
                      ).toUpperCase()}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="sz-12">Network Comm</td>
                  <td className="sz-12">: {networkCommissionText}</td>
                </tr>
                <tr>
                  <td className="sz-12">Share Percentage</td>
                  <td className="sz-12">
                    : {Number(network_advertiser.share_percentage || 0) + '%'}
                  </td>
                </tr>
                <tr>
                  <td className="sz-12">Shared Evet Comm</td>
                  <td className="sz-12">: {evetCommissionText}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="item vertical left">
            <span>OFFERS</span>
            <Button
              to={
                '/offers?advertiser_id=' +
                id +
                '&advertiser_id_labels=' +
                JSON.stringify({__queryName: 'Advertiser', [id]: name})
              }
              className="basic-button mt-6">
              {data.link_count || '-'}
            </Button>
          </div>
          <div className="item">
            <span>NETWORKS</span>
            <p className="mt-6">{(data.network_advertisers || []).length}</p>
          </div>
          <div className="item">
            <span>SALES</span>
            <p className="mt-6">{data.sales || '-'}</p>
          </div>
          <div className="action horizon middle">
            <Button onClick={showMore} className="basic-button">
              <MdKeyboardArrowDown
                className={isOpen ? 'dropdown-icon top' : 'dropdown-icon down'}
              />
              More
            </Button>
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="dropdown">
          <div className="explanation per-100">
            <div className="info per-40">
              <h2>INFO</h2>
              <div className="info-table ">
                <div className="column">
                  <span>URL</span>
                  <p>
                    :{' '}
                    <a
                      href={data.website}
                      target="_blank"
                      rel="noreferrer noopener">
                      {data.website}
                    </a>
                  </p>
                </div>
                <div className="column">
                  <span>CONTACT</span>
                  <p>: {data.name}</p>
                </div>
                <div className="column">
                  <span>EMAIL</span>
                  <p>: {data.email}</p>
                </div>
                <div className="column">
                  <span>PHONE</span>
                  <p>: {data.phone}</p>
                </div>
                {props.category && (
                  <div className="column">
                    <span>CATEGORY</span>
                    <p>: {data.category}</p>
                  </div>
                )}
                <div className="column">
                  <span>NETWORK EARNINGS</span>
                  <p>: {data.networkEarnings ? data.networkEarnings : '-'} </p>
                </div>
              </div>
            </div>
            <div className="description per-58">
              <h2>DESCRIPTION</h2>
              <div id="scrollBar" className="scroll-area">
                <p>{data.description}</p>
              </div>
            </div>
          </div>
          <Item id={data.id} />
        </div>
      )}
    </div>
  )
}
