import {Filter, HeadLine} from 'components'
import {AsyncFilterSection, FilterSection} from 'filter'
import {queryStringFormatter, ToggleMenu} from '@evet/library'
import {inject, observer} from 'mobx-react'
import {Loader, qs, Slider} from '@evet/library'
import React from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import useDeepCompareEffect from 'use-deep-compare-effect'
import {AdvertiserCard} from './AdvertiserCard'

const listType = [
  {label: 'ALL', value: 'ALL'},
  {label: 'EXCLUSIVE', value: 'EXCLUSIVE'},
  {label: 'UPDATED COMMISSION', value: 'UPDATED_COMMISSION'},
]

const QUERY_ARRAYS = []
// const APPLIED_FILTER_EXCEPTIONS = ['offset']

export const Commission = inject(
  'FilterStore',
  'LibraryStore',
  'AdvertiserStore'
)(
  observer(props => {
    const history = useHistory()
    const location = useLocation()
    const params = qs.parse(location.search)

    const i = 1

    useDeepCompareEffect(() => {
      props.FilterStore.readCountries()
      props.FilterStore.readNetworks()
    }, [props.FilterStore, params])
    const {countries, networks} = props.FilterStore

    return (
      <section className="vertical center">
        <HeadLine title="Commission" className="sz-30 fw-300">
          <div className="options horizon right middle">
            <div className="item" />
          </div>
        </HeadLine>
        {i !== 1 ? (
          <Loader />
        ) : (
          <div className="container mt-20 horizon top between">
            <div className="list-page">
              <div className="side-filter">
                <Filter
                  defaultValues={{
                    country: '',
                    network: '',
                    slider: [20, 40],
                    //...params,
                    ...queryStringFormatter({params, array: QUERY_ARRAYS}),
                  }}
                  params={params}
                  navigate={history.push}>
                  <FilterSection
                    name="network"
                    label="Network"
                    options={networks}
                  />
                  <FilterSection
                    name="country"
                    label="Country"
                    options={countries}
                  />
                  <AsyncFilterSection
                    multi
                    name="advertiser_id"
                    label="Advertiser"
                    store={props.AdvertiserStore}
                  />
                  <AsyncFilterSection
                    multi
                    name="network_advertiser_id"
                    label="Network Advertiser"
                    store={props.LibraryStore}
                  />
                  <FilterSection label="Slider">
                    <Slider
                      name="slider"
                      options={{max: 100, min: 0}}
                      className="per-100"
                    />
                  </FilterSection>
                </Filter>
              </div>
              <div className="results">
                <div className="horizon left per-100 mb-10">
                  <ToggleMenu
                    navigate={history.push}
                    params={params}
                    size="small"
                    options={listType}
                    defaultValue={params.type || 'ALL'}
                  />
                </div>
                {params.type === 'UPDATED_COMMISSION' ? (
                  <AdvertiserCard type="networks" />
                ) : (
                  <AdvertiserCard type="publishers" />
                )}
              </div>
            </div>
          </div>
        )}
      </section>
    )
  })
)
