import {action, computed, makeObservable, observable} from 'mobx'
import {FilterServices as Service} from '../services'
import {Filter as Model} from 'models'

export default class FilterStore {
  state = 'initial'
  _list = new observable.map()

  countries = []
  networks = []
  categories = []
  types = []

  constructor(Stores) {
    this.stores = Stores

    makeObservable(this, {
      state: observable,
      _list: observable,
      list: computed,

      read: action,
      create: action,
      update: action,
      delete: action,
      fetchSuccess: action.bound,
      handleError: action.bound,

      countries: observable,
      networks: observable,
      categories: observable,
      types: observable,
      networkLabels: computed,
      readCountries: action,
      readNetworks: action,
      readCategories: action,
      readTypes: action,
    })
  }

  create = async data => {
    if (!data) return false
    return await Service.create(data)
      .then(res => {
        // this.read({})

        //todo bro todo
        // const resItem = new Model(res.item || {})
        // const report = this._list.get(resItem.report_type_id || resItem.report_type)
        // report.saved_reports.push(resItem);
        // this._list.set(report.report_type, report)
        // console.log(report, this._list)
        return res
      })
      .catch(error => this.stores.SystemMessageStore.handleError(error))
  }

  read = async ({service = () => null}) => {
    this.state = 'listing'
    return await service().then(this.fetchSuccess, this.handleError)
  }

  update = async ({id, data}) => {
    return await Service.update(id, data)
      .then(res => {
        //this._item = null;
        if (res.item) {
          const item = new Model(res.item || {})
          this._list.set(item.id, item)
        }
        return res
      })
      .catch(error => this.stores.SystemMessageStore.handleError(error))
  }

  delete = async ({id}) => {
    return await Service.delete(id)
      .then(res => {
        console.log(this._list)
        this._list.delete(id)
        return res
      })
      .catch(error => this.stores.SystemMessageStore.handleError(error))
  }

  fetchSuccess = res => {
    if (res) {
      this._list = new observable.map()
      res.items.forEach(i => {
        const item = new Model(i || {})
        this._list.set(item.report_type, item)
      })
    }
    return !!(this.state = 'done')
  }

  handleError = error => {
    this.state = 'error'
    return this.stores.SystemMessageStore.handleError(error)
  }

  get list() {
    return [...this._list.values()]
  }

  get networkLabels() {
    const networkDictionary = {}
    this.networks.forEach(i => {
      networkDictionary[i.value] = i.label
    })
    return networkDictionary
  }

  async readCountries() {
    if (!this.countries.length)
      Service.countries().then(res => {
        if (res && res.items) {
          this.countries = res.items.map(i => ({
            value: i.country_code,
            label: i.name,
          }))
        }
      }, this.handleError)
  }

  async readNetworks() {
    if (!this.networks.length)
      Service.networks().then(res => {
        if (res && res.items) {
          this.networks = res.items.map(i => ({
            value: i.network_id,
            label: i.name,
          }))
        }
      }, this.handleError)
  }

  async readCategories() {
    if (!this.categories.length)
      Service.categories().then(res => {
        if (res && res.items) {
          this.categories = res.items.map(i => ({
            value: Number(i.id),
            label: i.name,
          }))
        }
      }, this.handleError)
  }

  async readTypes() {
    if (!this.types.length)
      await Service.types().then(res => {
        this.types = res.items.map(i => ({
          id: i.id,
          value: i.id,
          label: i.name,
        }))
      }, this.handleError)
  }
}
