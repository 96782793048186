import React from 'react'
import {useLocation} from 'react-router-dom'
import {Button, DropDown} from '@evet/library'
import {RiDeleteBin6Line} from 'react-icons/all'

const Item = props => {
  const {data, onDelete, onChange} = props
  const {name = '', query_string, id} = data

  const handleDelete = e => {
    e.stopPropagation()
    e.preventDefault()
    onDelete(id)
  }

  const handleChange = () => {
    onChange(query_string)
  }

  return (
    <div className="select-filter-menu" onClick={handleChange}>
      <span>{name} </span>
      <Button
        className="basic-button"
        data-id={id}
        onClick={handleDelete}
        confirm={'Delete ' + name + '?'}>
        <RiDeleteBin6Line />
      </Button>
    </div>
  )
}

export const SelectFilter = ({
  label = 'Saved Elements',
  className = '',
  list = [],
  onChange = null,
  onDelete = null,
}) => {
  const {search} = useLocation()
  const selected =
    (list.find(i => i.query_string === search) || {}).name || 'Select...'

  return (
    <React.Fragment>
      <p className="sz-12 mb-6">Select Saved Report Type</p>
      <DropDown label={selected} className="per-100">
        {list.map(i => (
          <Item
            key={i.query_string}
            data={i}
            onDelete={onDelete}
            onChange={onChange}
          />
        ))}
      </DropDown>
    </React.Fragment>
  )
}
