import {inject, observer} from 'mobx-react'
import React from 'react'
import {RiStickyNoteLine} from 'react-icons/all'
import {CommissionDetail} from './CommissionDetail'
import {CommissionLogs} from './CommissionLogs'

export const NetworkList = inject('UIStore')(
  observer(props => {
    const commissionLogList = () => {
      const title = "Commission Log's"
      const content = <CommissionLogs />
      const size = 'medium-size'
      props.UIStore.modalOpen({
        title,
        content,
        size,
        type: '',
        onClose: null,
      })
    }
    const commissionDetail = () => {
      const title = 'Commission Detail'
      const content = <CommissionDetail />
      const size = 'medium-size'
      props.UIStore.modalOpen({
        title,
        content,
        size,
        type: '',
        onClose: null,
      })
    }
    return (
      <div id="scrollBar" className="commission-log-table">
        <table className="list-table-modal">
          <thead>
            <tr>
              <td>NETWORK</td>
              <td>COMMISSION</td>
              <td>STATUS</td>
              <td>Log's</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>CJ</td>
              <td>
                <div className="horizon left middle">
                  %30
                  <button
                    onClick={commissionDetail}
                    className="btn basic ml-10 p-0 text-underline">
                    detail
                  </button>
                </div>
              </td>
              <td>Active</td>
              <td>
                <button
                  onClick={commissionLogList}
                  className="btn table-button">
                  <RiStickyNoteLine />
                  view
                </button>
              </td>
              <td>
                <button className="btn basic p-0 text-underline">Select</button>
              </td>
            </tr>
            <tr>
              <td>PPJ</td>
              <td>
                <div className="horizon left middle">
                  %20
                  <button
                    onClick={commissionDetail}
                    className="btn basic ml-10 p-0 text-underline">
                    detail
                  </button>
                </div>
              </td>
              <td>Active</td>
              <td>
                <button
                  onClick={commissionLogList}
                  className="btn table-button">
                  <RiStickyNoteLine />
                  view
                </button>
              </td>
              <td>
                <span className="sz-12">Selected</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  })
)
