export const FilterStatusList = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'ACTIVE',
    label: 'Active',
  },
  {
    value: 'PENDING',
    label: 'Pending',
  },
  {
    value: 'NOT_JOINED',
    label: 'Not Joined',
  },
  {
    value: 'DECLINED',
    label: 'Declined',
  },
]

export const FilterLinkTypes = [
  {
    value: 'TEXT',
    label: 'Text Link',
  },
  {
    value: 'BANNER',
    label: 'Banner',
  },
  {
    value: 'PRODUCT',
    label: 'Product',
  },
]
export const FilterTagList = [
  {
    value: 'SALE',
    label: 'Sale',
  },
  {
    value: 'FREE_SHIPPING',
    label: 'Free Shipping',
  },
  {
    value: 'COUPON',
    label: 'Coupon',
  },
  {
    value: 'SEASONAL',
    label: 'Seasonal',
  },
  {
    value: 'FREE_GIFTS',
    label: 'Free Gifts',
  },
]

export const OfferStatusList = [
  {
    label: 'Active',
    value: 'ACTIVE',
  },
  {
    label: 'Waiting',
    value: 'WAITING',
  },
  {
    label: 'Ignored',
    value: 'IGNORED',
  },
]

export const NetworkStatusList = [
  {
    label: 'Pending',
    value: 'PENDING',
  },
  {
    label: 'Joined',
    value: 'JOINED',
  },
  {
    label: 'Not Joined',
    value: 'NOT_JOINED',
  },
  {
    label: 'Declined',
    value: 'DECLINED',
  },
]

export const InternalStatusList = [
  {
    label: 'Connected',
    value: 'CONNECTED',
  },
  {
    label: 'Free',
    value: 'FREE',
  },
  {
    label: 'Ignored',
    value: 'IGNORED',
  },
  {
    label: 'Rejected',
    value: 'REJECTED',
  },
]

export const PublisherLibraryStatusList = [
  {
    label: 'Pending',
    value: 'PENDING',
  },
  {
    label: 'Ignored',
    value: 'IGNORED',
  },
]
