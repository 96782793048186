import {inject, observer} from 'mobx-react'
import React, {useState} from 'react'
import {useHistory, useLocation, useParams} from 'react-router-dom'
import * as Yup from 'yup'
import {
  Form,
  Input as CustomInput,
  Select as CustomSelect,
  Submit,
} from '@evet/library'
import {qs} from 'helpers'
import {Modal} from 'components'
import {Item} from 'pages/Advertiser/AdvertiserLibrary/Modals/Add/Item'
import {Similars} from 'pages/Advertiser/AdvertiserLibrary/Modals/Add/Smilars/Similars'

export const AddModal = inject(
  'AdvertiserStore',
  'LibraryStore',
  'FilterStore'
)(
  observer(props => {
    const {advertiserID: id} = useParams()
    const history = useHistory()
    const data = props.LibraryStore.getListItemByID(id)

    const [loading, setLoading] = useState(false)
    const [showForm, setShowForm] = useState(false)
    const [logo] = useState(null)
    const location = useLocation()
    const params = qs.parse(location.search)

    const schema = Yup.object().shape({
      name: Yup.string().required('Required'),
      website: Yup.string().required('Required'),
    })

    const {countries} = props.FilterStore
    //
    // const handleFile = e => {
    //   e.preventDefault()
    //   const input = e.target
    //   const file = input.files[0]
    //   const preview = document.getElementById('preview')
    //   const reader = new FileReader()
    //   reader.onload = (img => e => {
    //     setLogo(e.target.result)
    //     img.src = e.target.result
    //   })(preview)
    //   reader.readAsDataURL(file)
    // }

    const onSubmit = async data => {
      // https://ghost-api.staging.evet.com/api/admin/swagger/
      data.network_advertiser_id = id //Number(id)
      data.logo = logo
      // delete data.countries
      setLoading(true)
      if (await props.AdvertiserStore.create(data)) {
        props.LibraryStore.read({params}).then(() => history.goBack())
      } else setLoading(false)
    }

    const handleCreate = () => setShowForm(true)

    return (
      <Modal title="Add Advertiser">
        {showForm ? (
          <Form
            defaultValues={{
              countries: (data.countries || []).map(i => i.country_code),
              name: data.name,
              website: data.website,
              description: data.description,
              share_percentage: Number(data.share_percentage || 0),
              network_comm_percent: Number(
                ((data.commission_summary || {}).percentage || {}).max || 0
              ),
            }}
            className="per-100 vertical top p-10 border-box"
            onSubmit={onSubmit}
            schema={schema}>
            <div className="vertical center top per-100">
              <div className="per-100 horizon between top mt-20">
                <CustomInput className=" per-32" label="Name" name="name" />
                <CustomInput
                  className=" per-32"
                  label="Website"
                  name="website"
                />
                <div className="vertical left top per-32">
                  <CustomSelect
                    multi
                    className="per-100"
                    label="Countries"
                    name="countries"
                    options={countries}
                  />
                </div>
              </div>
              <div className="horizon between middle per-100 mt-20">
                {/*<Row networkCommission={props.commission_summary} />*/}
              </div>
            </div>

            <CustomInput
              rows="2"
              className="mt-20"
              label="Description"
              name="description"
            />
            <Submit className="per-100 mt-10 horizon center" loading={loading}>
              Create
            </Submit>
          </Form>
        ) : (
          <div className="p-10 add-advertisers in-modal">
            {/*SIMILAR ADVERTISERS*/}
            <Similars id={id} />

            {props.status !== 'CONNECTED' && (
              <React.Fragment>
                <h2 className="">CREATE NEW</h2>
                <table className="list-table">
                  <thead>
                    <tr>
                      <td>Advertiser</td>
                      <td>Region</td>
                      <td>Currency</td>
                      <td>Commission</td>
                      <td>Network</td>
                      <td />
                    </tr>
                  </thead>
                  <tbody>
                    {id && (
                      <Item id={id} data={data} handleCreate={handleCreate} />
                    )}
                  </tbody>
                </table>
              </React.Fragment>
            )}
          </div>
        )}
      </Modal>
    )
  })
)
