import {inject, observer} from 'mobx-react'
import React from 'react'
import {
  AiOutlineOrderedList,
  FaNetworkWired,
  FaSignOutAlt,
  FiMessageSquare,
  GrTransaction,
  MdLibraryBooks,
  GrUserSettings,
} from 'react-icons/all'
import {Link, useHistory} from 'react-router-dom'
import {DropDown, Header, qs} from '@evet/library'

// const allNetworks = [
//   {label: 'Pepperjam', to: '/networks/pepperjam'},
//   {label: 'Network 2', to: '/'},
//   {label: 'Network 3', to: '/'},
//   {label: 'Network 4', to: '/'},
//   {label: 'Network 5', to: '/'},
//   {label: 'Network 6', to: '/'},
//   {label: 'Network 7', to: '/'},
//   {label: 'Network 8', to: '/'},
// ]
export const CustomHeader = inject('AuthStore')(
  observer(props => {
    const history = useHistory()
    const handleHome = () => {
      history.push('/')
    }
    // const [networks, setNetworks] = useState(allNetworks.slice(0, 5))
    // const handleNetworks = e => {
    //   e.stopPropagation()
    //   setNetworks(allNetworks)
    // }
    const test = qs.stringify({
      internal_status: 'FREE',
      network_status: 'JOINED',
      internal_status_labels: JSON.stringify({__queryName: "Evet's Status"}),
      network_status_labels: JSON.stringify({__queryName: 'Network Status'}),
    })

    const {me} = props.AuthStore
    return (
      <Header brand={me.product.name} onBrandClick={handleHome}>
        <div className="left">
          <DropDown label="Advertiser">
            <Link to="/advertisers">
              <FaNetworkWired /> Advertisers
            </Link>
            <Link to={'/advertiser-library?' + test}>
              <MdLibraryBooks /> <span>Advertiser Library</span>
            </Link>
            <Link to="/offers">
              <FiMessageSquare /> Offers
            </Link>
          </DropDown>

          <DropDown label="Publisher">
            <Link to="/clients">
              <FaNetworkWired /> Clients
            </Link>
            <Link to="/publisher-library">
              <MdLibraryBooks /> Publisher Library
            </Link>
            <Link to="/commission">
              <AiOutlineOrderedList /> Commission
            </Link>
            <Link to="/user-management">
              <GrUserSettings /> User Management
            </Link>
          </DropDown>

          <DropDown label="Reporting">
            <Link to="/reporting?report_type=performance_date">
              <FaNetworkWired /> Reporting
            </Link>
            {/*<Link to="/network-reporting">*/}
            {/*  <MdLibraryBooks /> Network Performance*/}
            {/*</Link>*/}
            {/*<Link to="/advertiser-reporting">*/}
            {/*  <FiMessageSquare /> Advertiser Performance*/}
            {/*</Link>*/}
            {/*<Link to="/publisher-reporting">*/}
            {/*  <AiOutlineOrderedList /> Publisher Performance*/}
            {/*</Link>*/}
            <Link to="/transactions?report_type=transaction_list">
              <GrTransaction /> Transaction
            </Link>
          </DropDown>

          {/*<DropDown label="Networks" className="">*/}
          {/*  <div className="scrollable">*/}
          {/*    {networks.map((i, index) => (*/}
          {/*      <Link key={index + i.to} to={i.to}>*/}
          {/*        <FaNetworkWired /> {i.label}*/}
          {/*      </Link>*/}
          {/*    ))}*/}
          {/*    {networks.length <= 5 && (*/}
          {/*      <div className="p-10 horizon center middle">*/}
          {/*        <button className="btn basic-button" onClick={handleNetworks}>*/}
          {/*          see all*/}
          {/*        </button>*/}
          {/*      </div>*/}
          {/*    )}*/}
          {/*  </div>*/}
          {/*</DropDown>*/}
        </div>
        <div className="right">
          {me && me.id && (
            <DropDown
              icon={me.name[0]}
              label={me.name}
              description={`ID: ${me.id}`}
              className="right">
              <Link to="/logout">
                <FaSignOutAlt /> Logout
              </Link>
            </DropDown>
          )}
        </div>
      </Header>
    )
  })
)
