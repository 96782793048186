import React from 'react'
import PropTypes from 'prop-types'

import {Box, makeStyles, Paper, Tab, Tabs, withStyles} from '@material-ui/core'

function TabMenuPage(props) {
  const {children, value, index, ...other} = props
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}>
      {value === index && (
        <Box pt={1}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  )
}

TabMenuPage.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    maxHeight: '70px',
  },
  indicator: {
    backgroundColor: '#2fb73e',
    height: '3px',
    bottom: '0',
  },
  wrapper: {
    borderBottom: 'none',

    width: '100%',
  },
  tabs: {
    color: '#484848',
    backgroundColor: '#efefef',
    fontSize: '12px',
    maxHeight: '170px',
  },
  appBar: {
    backgroundColor: 'red',
    height: 'auto',
  },
  tripleTab: {
    minWidth: 'calc(100% / ' + 3 + ')',
  },
}))

const TabStyle = withStyles(theme => ({
  root: {
    padding: '0 30px',
    textTransform: 'none',
    fontSize: '12px',
    lineHeight: '16px',
    maxHeight: '70px',
    boxSizing: 'border-box',
    '&:hover': {
      backgroundColor: '#e3e3e3',
      color: '#000',
      opacity: 1,
      maxHeight: '70px',
    },
    '&$selected': {
      backgroundColor: '#fff',
      color: '#000',
    },
  },

  selected: {},
}))(props => <Tab {...props} />)

export default function ScrollableTabsButtonAuto(props) {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <div className={classes.wrapper}>
      <Paper>
        <div className={classes.toolbar + classes.appBar}>
          <Tabs
            className={classes.tabs}
            classes={{
              indicator: classes.indicator,
            }}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            value={value}
            onChange={handleChange}>
            {props.tabs.map((i, k) => (
              <TabStyle
                className={classes[props.tabSize || '']}
                label={
                  <div className="vertical center">
                    {i.label.icon && i.label.icon}
                    {i.label.count && (
                      <span className="sz-18 ">{i.label.count}</span>
                    )}
                    <div className="sz-12 fw-400">{i.label.name}</div>
                  </div>
                }
                {...a11yProps(k)}
              />
            ))}
          </Tabs>
        </div>
      </Paper>
      {props.tabs.map((i, k) => (
        <TabMenuPage className={props.className} value={value} index={k}>
          {i.content}
        </TabMenuPage>
      ))}
    </div>
  )
}
