export class Config {
  types = []
  filters = []
  columns = []
  report_switch = {}

  constructor(data = {items: []}) {
    const {items} = data
    this.types = items.map(i => ({
      label: i.name,
      value: i.report_type,
    }))
    const config = items.find(i => i.report_type === data.type) || {}
    this.formElements = config.filters
    this.columns = config.columns
    this.filters = config.saved_reports
    this.report_switch = config.report_switch
  }
}

export class Meta {
  searchable = []
  selectable = []
  sortable = []
  data = {}
  charts = []

  constructor(data) {
    if (data) Object.entries(data).forEach(([k, i]) => (this[k] = i))
  }
}

export class Performance {
  constructor(data) {
    if (data) {
      Object.entries(data).forEach(([k, i]) => (this[k] = i))
    }
  }
}
