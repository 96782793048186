import {inject, observer} from 'mobx-react'
import React from 'react'
import {HeadLine, SearchInput} from 'components'
import {Link, useHistory, useLocation} from 'react-router-dom'
import useDeepCompareEffect from 'use-deep-compare-effect'
import {Loader, qs, Pagination, ToggleMenu} from '@evet/library'
import {PublisherLibraryStatusList} from 'components/Filter/FilterDefaults'
import {Card} from './Card'

export const PublisherLibrary = inject(
  'ClientStore',
  'AdvertiserStore',
  'FilterStore'
)(
  observer(props => {
    const {search} = useLocation()
    const history = useHistory()
    const params = {
      status: 'PENDING',
      ...qs.parse(search),
    }

    // const {elder: id} = useParams()

    useDeepCompareEffect(() => {
      props.ClientStore.read({params})
    }, [props.ClientStore, params])
    const {state, list, meta} = props.ClientStore
    //
    // useDeepCompareEffect(() => {
    //   props.FilterStore.readCountries()
    //   props.FilterStore.readNetworks()
    //   props.FilterStore.readCategories()
    //   props.FilterStore.readTypes()
    // }, [props.FilterStore])
    // const {countries, networks, categories, types} = props.FilterStore

    return (
      <section className="vertical center">
        <HeadLine title="Publisher Library" className="sz-30 fw-300">
          <div className="options horizon right middle">
            <div className="item" />
          </div>
        </HeadLine>

        {/*<Contacts/>*/}

        <div className="container mt-20 horizon top between">
          <div className="per-100">
            <div className="section-title horizon between middle">
              <div className="horizon left middle">
                <p className="sz-14 f-grey mr-20">
                  Results : {meta.count || 0}
                </p>
                <ToggleMenu
                  navigate={history.push}
                  params={params}
                  size="small"
                  options={PublisherLibraryStatusList}
                  defaultValue={params.status}
                />
              </div>
              <div className="filter per-50 horizon right">
                <SearchInput placeholder="Search Publisher" />
              </div>
            </div>
            <div className="mt-10">
              {state === 'listing' ? (
                <Loader />
              ) : (
                <React.Fragment>
                  {list
                    .filter(
                      i =>
                        i.status !== 'JOINED' ||
                        i.publishers.find(
                          i =>
                            i.status !== 'JOINED' ||
                            i.properties.find(i => i.status !== 'JOINED')
                        )
                    )
                    .map(i => (
                      <Card key={'card-' + i.id} id={i.id} data={i} />
                    ))}
                  <Pagination
                    className="mt-30 mb-40"
                    offset={params.offset}
                    count={meta.count}
                    limit={meta.limit}
                    params={params}
                    Link={Link}
                  />
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </section>
    )
  })
)
