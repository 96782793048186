import {DashboardServices as Service} from 'services'

export class Meta {
  columns = []
  count = 0
  limit = 0
  offset = 0
  constructor(data) {
    if (data) Object.entries(data).forEach(([k, i]) => (this[k] = i))
  }
}

export class Module {
  constructor(data) {
    console.log('Module Model', {data})

    this.state = 'initial'
    this.id = data.id
    this.name = data.name
    this.meta = {columns: []}
    this._list = {}
  }

  get list() {
    return Object.values(this._list)
  }

  fill = ({meta, items}) => {
    this.meta = new Meta(meta)
    this._list = {}
    items.forEach(i => {
      this._list[i.id] = i
    })
    this.state = 'done'
  }

  read = async ({params = {}, module = ''} = {}) => {
    this.state = 'listing'
    await Service.read(params, module).then(this.fetchSuccess, this.handleError)
    return this
  }

  fetchSuccess = res => {
    if (res) {
      this.meta = new Meta(res.meta)
      this._list = {}
      res.items.forEach(i => {
        this._list[i.id] = i
      })
    }
    this.state = 'done'
  }

  handleError = error => {
    this.state = 'error'
    console.log({error})
    //return this.stores.SystemMessageStore.handleError(error)
  }
}
