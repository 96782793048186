import {inject, observer} from 'mobx-react'
import React, {useEffect, useState} from 'react'
import {Loader} from '@evet/library'
import {Row} from './Row'

export const Item = inject('AdvertiserStore')(
  observer(props => {
    const {id} = props
    const [loading, setLoading] = useState(false)

    useEffect(() => {
      ;(async () => {
        setLoading(true)
        await props.AdvertiserStore.read({id, params: {similar: true}})
        setLoading(false)
      })()
    }, [props.AdvertiserStore, id])

    const item = props.AdvertiserStore.getListItemByID(id)

    return (
      <React.Fragment>
        {loading ? (
          <Loader />
        ) : (
          <table className="list-table mt-10">
            {/* <div style={{position: 'absolute'}}><Loader padding={true}/></div> */}
            <thead>
              <tr>
                <td>NETWORKS</td>
                <td>CURRENCY</td>
                <td>APPROVAL DATE</td>
                <td>EXECUTION DATE</td>
                <td>NETWORK COMM</td>
                <td>SHARE PERCENTAGE</td>
                <td>SHARED EVET COMM</td>
                <td>NETWORK STATUS</td>
                <td>EVET'S STATUS</td>
                <td>OFFERS</td>
                <td />
                <td />
              </tr>
            </thead>
            <tbody>
              {/*TODO detail and connect*/}
              {/*TODO advertiser_id degil external_id*/}
              {(item.network_advertisers || [])
                .concat(item.similars || [])
                .map(i => (
                  <Row key={i.id} id={i.id} data={i} parent={id} />
                ))}
            </tbody>
          </table>
        )}
      </React.Fragment>
    )
  })
)
