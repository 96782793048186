import {MoreLess} from '@evet/library'
import {Button} from 'components'
import {inject, observer} from 'mobx-react'
import React from 'react'
import {
  GiTriangleTarget,
  GiWorld,
  GrContact,
  GrCube,
  GrMoney,
  GrServices,
  RiUserVoiceLine,
  HiOutlineUserGroup,
} from 'react-icons/all'
import {Item} from './Item'
import {useRouteMatch} from 'react-router'

export const Card = inject(
  'FilterStore',
  'ClientStore'
)(
  observer(props => {
    const match = useRouteMatch()
    const {client_id, data} = props
    const {
      id,
      name,
      status,
      properties = [],
      service_areas = [],
      country = {},
      // categories = [],
    } = data
    // const [showEdit, setShowEdit] = useState(false)
    // const [showUsers, setShowUsers] = useState(false)

    // const handleEditModal = () => {
    //   if (!showEdit) {
    //     props.FilterStore.readCountries()
    //     props.FilterStore.readNetworks()
    //     props.FilterStore.readCategories()
    //     props.FilterStore.readTypes()
    //   }
    //   setShowEdit(!showEdit)
    // }
    //
    // const handleEdit = async data => {
    //   await props.ClientStore.patchPublisher({
    //     parent: client_id,
    //     id,
    //     inList: false,
    //     data: data.publisher,
    //   })
    //   handleEditModal()
    // }
    // const handleUserModal = () => {
    //   setShowUsers(!showUsers)
    // }

    return (
      <React.Fragment>
        {/*{showEdit && (*/}
        {/*  <Publisher*/}
        {/*    onClose={handleEditModal}*/}
        {/*    nextStep={handleEdit}*/}
        {/*    isEditing*/}
        {/*    data={{*/}
        {/*      country_code: country.country_code || '',*/}
        {/*      service_areas: service_areas.map(i => i.country.country_code),*/}
        {/*      categories: categories.map(i => i.id.toString()),*/}
        {/*      name,*/}
        {/*      status,*/}
        {/*    }}*/}
        {/*  />*/}
        {/*)}*/}
        {/*{showUsers && <Users onClose={handleUserModal} />}*/}
        <div className="publisher-card horizon between">
          <div className="card-base per-25 border-box">
            <div className="title horizon between per-100">
              <div className="detail">
                <p>{name}</p>
                <span>PUBID: {id}</span>
              </div>

              <Button
                className="edit-button small"
                // onClick={handleEditModal}
                to={`${match.url}/publishers/${id}/edit`}
                label="Edit"
              />
            </div>
            <div className="data per-100 mt-10">
              <div className="item">
                <span>
                  <HiOutlineUserGroup />
                  Users
                </span>
                <div className="c-green horizon left middle">
                  <p>6</p>
                  <Button
                    // onClick={handleUserModal}
                    to={`${match.url}/publishers/${id}/users`}
                    className="basic-button  ml-6"
                    label="view"
                  />
                </div>
              </div>
              <div className="item">
                <span>
                  <GiWorld />
                  Region
                </span>
                <p>{country ? country.name : '-'}</p>
              </div>
              <div className="item">
                <span>
                  <GrServices />
                  Services Area
                </span>
                <p>
                  <MoreLess
                    content={
                      service_areas.length
                        ? service_areas.map(i => i.country.name).join(', ')
                        : '-'
                    }
                    maxLimit="24"
                  />
                </p>
              </div>
              <div className="item">
                <span>
                  <GrMoney />
                  Total Sales
                </span>
                <p>xxxx</p>
              </div>
              <div className="item">
                <span>
                  <GrCube /># of Properties
                </span>
                <p>{properties.length}</p>
              </div>
              <div className="item">
                <span>
                  <RiUserVoiceLine /># of Advertisers
                </span>
                <p>xxxx</p>
              </div>
              <div className="item">
                <span>
                  <GrContact />
                  Contacts
                </span>
                <p>xxxx</p>
              </div>
              <div className="item">
                <span>
                  <GiTriangleTarget />
                  Status
                </span>
                <p className="c-green horizon left middle">{status}</p>
              </div>
              <div className="item mb-10">
                <Button
                  className="add-button light per-100"
                  to={`/clients/${client_id}/publishers/${id}/properties/new`}
                  label="Add New Property"
                />
              </div>
            </div>
          </div>
          <div
            className="vertical scroll-base center per-75 border-box"
            style={{maxHeight: 330}}
            id="scrollBar">
            {props.children}
            <table className="list-table ">
              <thead>
                <tr>
                  <td>PID</td>
                  <td>URI</td>
                  <td>NAME</td>
                  <td>USERS</td>
                  <td>CONTACT NAME</td>
                  <td>EMAIL</td>
                  <td>TYPE</td>
                  <td>PID</td>
                  <td>REGION</td>
                  <td>CONTACT</td>
                  <td># of ADVERTISERS</td>
                  <td>SALES</td>
                  <td>STATUS</td>
                  <td />
                </tr>
              </thead>
              <tbody>
                {properties.map(i => (
                  <Item
                    // showPropertyUsers={handleUserModal}
                    key={'property-' + i.id}
                    elder={client_id}
                    grand={id}
                    id={i.id}
                    data={i}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </React.Fragment>
    )
  })
)
