import React from 'react'
import TabMenuPage from '../../components/TabMenuPage'
import {inject, observer} from 'mobx-react'
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import {DatePickerForm} from 'components'
import {DateHelper} from 'helpers'

const data = [
  {
    name: '1',
    pv: 2400,
    amt: 2400,
  },
  {
    name: '2',
    pv: 1398,
    amt: 2210,
  },
  {
    name: '3',
    pv: 9800,
    amt: 2290,
  },
  {
    name: '4',
    pv: 3908,
    amt: 2000,
  },
  {
    name: '5',
    pv: 4800,
    amt: 2181,
  },
  {
    name: '6',
    pv: 3800,
    amt: 2500,
  },
  {
    name: '7',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: '1',
    pv: 2400,
    amt: 2400,
  },
  {
    name: '2',
    pv: 1398,
    amt: 2210,
  },
  {
    name: '3',
    pv: 9800,
    amt: 2290,
  },
  {
    name: '4',
    pv: 3908,
    amt: 2000,
  },
  {
    name: '5',
    pv: 4800,
    amt: 2181,
  },
  {
    name: '6',
    pv: 3800,
    amt: 2500,
  },
  {
    name: '7',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
]

const NetworkActivities = () => (
  <div className="horizon between top per-100">
    <div style={{width: '100%', height: 220}}>
      <ResponsiveContainer>
        <LineChart
          stroke="red"
          data={data}
          margin={{
            top: 10,
            bottom: 10,
          }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            tick={{fill: '#8f8f8f'}}
            tickLine={false}
            dy={10}
            style={{
              fontSize: '12px',
            }}
            axisLine={{stroke: '#fff'}}
            dataKey="name"
          />
          <YAxis
            tick={{fill: '#8f8f8f'}}
            tickLine={false}
            dx={-10}
            axisLine={{stroke: '#fff'}}
            style={{
              fontSize: '12px',
            }}
          />
          <Tooltip />
          <Line
            type="monotone"
            dataKey="pv"
            stroke="#7000ae"
            activeDot={{r: 8}}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  </div>
)
const CommissionActivities = () => (
  <div className="horizon between top per-100">
    <div style={{width: '100%', height: 220}}>
      <ResponsiveContainer>
        <LineChart
          stroke="red"
          data={data}
          margin={{
            top: 10,
            bottom: 10,
          }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            tick={{fill: '#8f8f8f'}}
            tickLine={false}
            dy={10}
            style={{
              fontSize: '12px',
            }}
            axisLine={{stroke: '#fff'}}
            dataKey="name"
          />
          <YAxis
            tick={{fill: '#8f8f8f'}}
            tickLine={false}
            dx={-10}
            axisLine={{stroke: '#fff'}}
            style={{
              fontSize: '12px',
            }}
          />
          <Tooltip />
          <Line
            type="monotone"
            dataKey="pv"
            stroke="#7000ae"
            activeDot={{r: 8}}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  </div>
)
const StatusActivities = () => (
  <div className="horizon between top per-100">
    <div style={{width: '100%', height: 220}}>
      <ResponsiveContainer>
        <LineChart
          stroke="red"
          data={data}
          margin={{
            top: 10,
            bottom: 10,
          }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            tick={{fill: '#8f8f8f'}}
            tickLine={false}
            dy={10}
            style={{
              fontSize: '12px',
            }}
            axisLine={{stroke: '#fff'}}
            dataKey="name"
          />
          <YAxis
            tick={{fill: '#8f8f8f'}}
            tickLine={false}
            dx={-10}
            axisLine={{stroke: '#fff'}}
            style={{
              fontSize: '12px',
            }}
          />
          <Tooltip />
          <Line
            type="monotone"
            dataKey="pv"
            stroke="#7000ae"
            activeDot={{r: 8}}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  </div>
)
export const Summary = inject()(
  observer(() => {
    const {yesterday: from, today: to} = DateHelper

    const handleTimeChange = ([start_date, end_date]) => {
      console.log('handleTimeChange', [start_date, end_date])

      // props.UIStore.from = dates[0]
      // props.UIStore.to = dates[1]
      // history.push(
      //     '?' +
      //     qs.stringify({
      //         start_date: dates[0],
      //         end_date: dates[1],
      //     })
      // )
    }

    const tabs = [
      {
        label: {name: 'Advertisers ', count: '12.300'},
        content: <NetworkActivities />,
      },
      {
        label: {name: 'Publishers ', count: '12.300'},
        content: <CommissionActivities />,
      },
      {
        label: {name: 'Commission ', count: '12.300'},
        content: <StatusActivities />,
      },
    ]

    return (
      <div className="card">
        <div className="card-title">
          Summary
          <DatePickerForm
            onSubmit={handleTimeChange}
            defaultValues={{start_date: from, end_date: to}}
            name={['start_date', 'end_date', 'date_range']}
          />
        </div>
        <div className="card-body">
          <TabMenuPage
            wrapper="per-100"
            className="p-0 per-100"
            tabSize="tripleTab"
            tabs={tabs}
          />
        </div>
      </div>
    )
  })
)
