import {inject, observer} from 'mobx-react'
import React from 'react'
import {
  HeadLine,
  InternalStatusList,
  NetworkStatusList,
  SearchInput,
} from 'components'
import {AppliedFilters} from 'components/Filter/AppliedFilters'
import {
  useLocation,
  Link,
  useHistory,
  useRouteMatch,
  Switch,
} from 'react-router-dom'
import useDeepCompareEffect from 'use-deep-compare-effect'
import {Card} from './Card'
import {
  InfoBoard,
  Loader,
  queryStringFormatter,
  qs,
  Pagination,
  Filter,
  AsyncFilterSection,
  FilterSection,
} from '@evet/library'
import {Route} from 'react-router'

import {NetworkDetail} from 'pages/Advertiser/Advertisers/Modals/NetworkDetail'
import {AddModal as NetworkAdd} from './Modals/Add/Add'
import {CancelModal as NetworkCancel} from './Modals/Cancel'

const QUERY_ARRAYS = [
  'internal_status',
  'network_status',
  'category_id',
  'advertiser_id',
  'network_advertiser_id',
]
const APPLIED_FILTER_EXCEPTIONS = ['offset']

export const AdvertiserLibrary = inject(
  'LibraryStore',
  'FilterStore',
  'AdvertiserStore'
)(
  observer(props => {
    const match = useRouteMatch()
    const {search} = useLocation()
    const history = useHistory()
    const params = qs.parse(search)

    const {state, meta, list, read} = props.LibraryStore
    useDeepCompareEffect(() => {
      read({params}).then()
    }, [read, params])

    useDeepCompareEffect(() => {
      props.FilterStore.readCountries().then()
      props.FilterStore.readNetworks().then()
      props.FilterStore.readCategories().then()
    }, [props.FilterStore])

    const {countries, networks, categories} = props.FilterStore

    return (
      <React.Fragment>
        <section className="vertical center">
          <HeadLine title="Advertiser Library" className="sz-30 fw-300">
            <div className="options horizon right middle">
              <div className="item" />
            </div>
          </HeadLine>
          <div className="container mt-20 horizon top between">
            <div className="list-page">
              <div className="side-filter">
                <Filter
                  defaultValues={{
                    country: '',
                    network_id: '',
                    ...queryStringFormatter({params, array: QUERY_ARRAYS}),
                  }}
                  params={params}
                  navigate={history.push}>
                  <FilterSection
                    name="network_id"
                    label="Network"
                    options={networks}
                  />

                  <FilterSection
                    name="country"
                    label="Country"
                    options={countries}
                  />

                  <FilterSection
                    name="internal_status"
                    label="Evet's Status"
                    options={InternalStatusList}
                    multi
                  />

                  <FilterSection
                    name="network_status"
                    label="Network Status"
                    options={NetworkStatusList}
                    multi
                  />

                  <FilterSection
                    name="category_id"
                    label="Category"
                    options={categories}
                    multi
                  />

                  <AsyncFilterSection
                    multi
                    name="advertiser_id"
                    label="Advertiser"
                    store={props.AdvertiserStore}
                  />

                  <AsyncFilterSection
                    multi
                    name="network_advertiser_id"
                    label="Network Advertiser"
                    store={props.LibraryStore}
                  />
                </Filter>
              </div>
              <div className="results">
                <div className="section-title horizon between middle">
                  <div className="horizon left middle">
                    <p className="sz-14 f-grey">Results : {meta.count}</p>
                  </div>

                  <div className=" per-70 horizon right">
                    {/*<ToggleMenu*/}
                    {/*	size="small"*/}
                    {/*	options={STATUS_LIST}*/}
                    {/*	defaultValue={params.status}*/}
                    {/*/>*/}

                    <SearchInput placeholder="Search Advertiser" />
                  </div>
                </div>
                <div>
                  <AppliedFilters
                    exceptions={APPLIED_FILTER_EXCEPTIONS}
                    hocParams={queryStringFormatter({
                      params,
                      array: QUERY_ARRAYS,
                    })}
                  />
                </div>
                {state['listing'] ? (
                  <Loader />
                ) : (
                  <div className="mt-10">
                    {!meta.count ? (
                      <InfoBoard />
                    ) : (
                      <React.Fragment>
                        <table className="list-table">
                          <thead>
                            <tr>
                              <td>Advertiser</td>
                              <td>Region</td>
                              <td>Currency</td>
                              <td>Commission</td>
                              <td>Network</td>
                              <td>Network Status</td>
                              <td>Evet's Status</td>
                              <td />
                            </tr>
                          </thead>
                          <tbody>
                            {list.map(i => (
                              <Card key={i.id} id={i.id} data={i} />
                            ))}
                          </tbody>
                        </table>
                        <Pagination
                          className="mt-30 mb-40"
                          offset={params.offset}
                          count={meta.count}
                          limit={meta.limit}
                          params={params}
                          Link={Link}
                        />
                      </React.Fragment>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
        {/*  MODALS */}
        <Switch>
          <Route
            path={`${match.path}/:advertiserID/add`}
            component={NetworkAdd}
          />
          <Route
            path={`${match.path}/:advertiserID/cancel`}
            component={NetworkCancel}
          />
          <Route
            path={`${match.path}/:advertiserID`}
            component={NetworkDetail}
          />
        </Switch>
        {/* /MODALS */}
      </React.Fragment>
    )
  })
)
