import {inject, observer} from 'mobx-react'
import React, {useEffect} from 'react'
import {Summary} from './Summary'
import TabMenuPage from 'components/TabMenuPage'
import {DatePickerForm, HeadLine, ListModule} from 'components'
import {FaStoreAlt} from 'react-icons/all'

export const Dashboard = inject('DashboardStore')(
  observer(props => {
    useEffect(() => {
      props.DashboardStore.read().then()
    }, [props.DashboardStore])

    const {state, meta, list, start_date, end_date} = props.DashboardStore

    console.log('Dashboard', {state, meta, list})

    const handleTimeChange = ([start_date, end_date]) => {
      console.log('handleTimeChange', [start_date, end_date])
      props.DashboardStore.read({
        params: {start_date, end_date, limit: 10},
      }).then()
    }

    const advertiser_tabs = list
      .filter(i => i.id.match(/^advertiser_/))
      .map(i => ({
        label: {name: i.name, icon: <FaStoreAlt />},
        content: (
          <ListModule
            model={i}
            params={{start_date, end_date}}
            loading={state !== 'done'}
          />
        ),
      }))
    const publisher_tabs = list
      .filter(i => i.id.match(/^publisher_/))
      .map(i => ({
        label: {name: i.name, icon: <FaStoreAlt />},
        content: (
          <ListModule
            model={i}
            params={{start_date, end_date}}
            loading={state !== 'done'}
          />
        ),
      }))

    return (
      <section className="dashboard vertical center">
        <HeadLine title="Dashboard" className="sz-30 fw-300">
          <div className="options horizon right middle">
            <div className="item" />
          </div>
        </HeadLine>
        <div className="container mt-20 vertical top">
          <Summary />
          <div className="card per-100 mt-20">
            <div className="card-title">
              Logs
              <DatePickerForm
                onSubmit={handleTimeChange}
                defaultValues={{start_date, end_date}}
                name={['start_date', 'end_date', 'date_range']}
              />
            </div>
            <div className="card-body horizon between top per-100">
              <div className="module per-50 light-br-1 p-10">
                <div className="sz-14 mb-20">Advertisers Activity Logs</div>
                <div className="card-body">
                  <TabMenuPage
                    wrapper="per-100"
                    className="p-0 per-100"
                    tabSize="tripleTab"
                    tabs={advertiser_tabs}
                  />
                </div>
              </div>
              <div className="module per-50 p-10">
                <div className="sz-14 mb-20">Publisher Activity Logs</div>
                <div className="card-body">
                  <TabMenuPage
                    wrapper="per-100"
                    className="p-0 per-100"
                    tabSize="tripleTab"
                    tabs={publisher_tabs}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  })
)
