import {request} from 'helpers'

export class Services {
  static create = async () => null

  static read = async ({params}) => {
    return await request.get(`/advertisers/network-advertisers/`, params)
  }

  static update = async (id, data) =>
    await request.put(`/advertisers/network-advertisers/${id}/`, data).then()

  static delete = async () => null

  static patch = async (id, data) =>
    await request.patch(`/advertisers/network-advertisers/${id}/`, data).then()

  static detail = async (id, params = null) => {
    //return await request.get(`/users/all?from=2020-04-28&to=2020-04-29&offset=0&limit=10`+params);
    return await request.get(`/advertisers/network-advertisers/${id}/`, params)
    // return await request.get(`/network-advertisers/`+id+'?similar=true');
  }

  static similars = async ({params = null}) => {
    //return await request.get(`/users/all?from=2020-04-28&to=2020-04-29&offset=0&limit=10`+params);
    return await request.get(`/advertisers/advertisers/`, params)
    // return await request.get(`/network-advertisers/`+id+'?similar=true');
  }

  static searchable = async ({field, search_text}) =>
    await request.get(`/advertisers/network-advertisers/`, {field, search_text})
}
