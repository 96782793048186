import {Modal} from 'components'
import {
  Form,
  Input as CustomInput,
  Select as CustomSelect,
  Submit,
} from '@evet/library'
import {inject, observer} from 'mobx-react'
import React from 'react'
import * as Yup from 'yup'

export const Client = inject('FilterStore')(
  observer(props => {
    const {nextStep, handleClose, types, data} = props
    const {countries} = props.FilterStore

    const defaultValues = {
      country_code: '',
      ...data,
    }

    const schema = Yup.object().shape({
      name: Yup.string().required('required'),
      country_code: Yup.string().required('required'),
      types: Yup.array().required('required'),
    })

    return (
      <Modal onClose={handleClose} title="Add Client" className="per-40">
        <div className="vertical left top p-10">
          <Form
            schema={schema}
            defaultValues={defaultValues}
            onSubmit={nextStep}
            className="per-100 vertical between top">
            <div className="horizon top between  per-100">
              <div className="vertical top per-50">
                <div className="vertical left per-100 mt-10">
                  <CustomInput className="per-100" label="Name" name="name" />
                </div>
                <div className="vertical left per-100 mt-10">
                  <CustomSelect
                    className="per-100"
                    label="Region"
                    name="country_code"
                    listHeight="120"
                    dropdownType="alwaysOpen"
                    options={[{label: 'Select...', value: ''}, ...countries]}
                  />
                </div>
              </div>
              <div className="per-46">
                <CustomSelect
                  label="Types"
                  name="types"
                  options={types}
                  multi
                  disableSearch
                  dropdownType="alwaysOpen"
                />
              </div>
            </div>

            <div className="horizon right middle per-100 mt-10">
              <Submit className="ml-6">Continue</Submit>
            </div>
          </Form>
        </div>
      </Modal>
    )
  })
)
