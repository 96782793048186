import {
  Filter,
  FilterLinkTypes,
  FilterTagList,
  HeadLine,
  InternalStatusList,
  NetworkStatusList,
  OfferStatusList,
  SearchInput,
} from 'components'
import {AppliedFilters} from 'components/Filter/AppliedFilters'
import {AsyncFilterSection, FilterSection} from 'filter'
import {Loader, qs, queryStringFormatter, Pagination} from '@evet/library'
import {InfoBoard} from '@evet/library'
import {inject, observer} from 'mobx-react'
import React from 'react'
import {Link, useHistory, useLocation, useRouteMatch} from 'react-router-dom'
import useDeepCompareEffect from 'use-deep-compare-effect'
import {Card} from './Card'
import {Route} from 'react-router'
import {GetCode} from 'pages/Advertiser/Offers/Modals/GetCode'
import {Edit} from 'pages/Advertiser/Offers/Modals/Edit'

const QUERY_ARRAYS = [
  'status',
  'advertiser_internal_status',
  'advertiser_network_status',
  'link_type',
  'category_id',
  'tag',
  'advertiser_id',
  'network_advertiser_id',
]
const APPLIED_FILTER_EXCEPTIONS = ['offset']

export const Offers = inject(
  'OfferStore',
  'LibraryStore',
  'AdvertiserStore',
  'FilterStore'
)(
  observer(props => {
    const match = useRouteMatch()
    const {search} = useLocation()
    const history = useHistory()
    const params = qs.parse(search)

    useDeepCompareEffect(() => {
      props.OfferStore.read({params})
    }, [props.OfferStore, params])
    const {list, state, meta} = props.OfferStore

    useDeepCompareEffect(() => {
      props.FilterStore.readCountries()
      props.FilterStore.readNetworks()
      props.FilterStore.readCategories()
    }, [props.FilterStore])
    const {countries, networks, networkLabels, categories} = props.FilterStore

    useDeepCompareEffect(() => {
      props.AdvertiserStore.searchable({
        field: 'advertiser_id',
        query: '',
      }).then()
    }, [props.AdvertiserStore])

    useDeepCompareEffect(() => {
      props.LibraryStore.searchable({
        field: 'network_advertiser_id',
        query: '',
      }).then()
    }, [props.LibraryStore])

    return (
      <React.Fragment>
        <section className="vertical center">
          <HeadLine title="Offers" className="sz-30 fw-300">
            <div className="options horizon right middle">
              <div className="item" />
            </div>
          </HeadLine>
          <div className="container mt-20 horizon top between">
            <div className="list-page">
              <div className="side-filter">
                <Filter
                  defaultValues={{
                    country: '',
                    network_id: '',
                    ...queryStringFormatter({params, array: QUERY_ARRAYS}),
                  }}
                  params={params}
                  navigate={history.push}>
                  <FilterSection
                    name="has_coupon_code"
                    label="Coupon Code"
                    options={[{label: 'Required', value: 'true'}]}
                  />
                  <FilterSection
                    name="network_id"
                    label="Network"
                    options={networks}
                  />
                  <FilterSection
                    name="country"
                    label="Country"
                    options={countries}
                  />
                  <FilterSection
                    name="link_type"
                    label="Offer Type"
                    options={FilterLinkTypes}
                    multi
                  />
                  <FilterSection
                    name="category_id"
                    label="Category"
                    options={categories}
                    multi
                  />
                  <FilterSection
                    name="tag"
                    label="Tags"
                    options={FilterTagList}
                    multi
                  />
                  <FilterSection
                    name="status"
                    label="Offer Status"
                    options={OfferStatusList}
                    multi
                  />
                  <FilterSection
                    name="advertiser_internal_status"
                    label="Evet Status"
                    options={InternalStatusList}
                    multi
                  />
                  <FilterSection
                    name="advertiser_network_status"
                    label="Network Status"
                    options={NetworkStatusList}
                    multi
                  />
                  <AsyncFilterSection
                    multi
                    name="advertiser_id"
                    label="Advertiser"
                    store={props.AdvertiserStore}
                  />
                  <AsyncFilterSection
                    multi
                    name="network_advertiser_id"
                    label="Network Advertiser"
                    store={props.LibraryStore}
                  />
                </Filter>
              </div>
              <div className="results">
                <div className="section-title horizon between middle">
                  <p className="sz-14 f-grey">Results : {meta.count || 0}</p>
                  {/*<ToggleMenu*/}
                  {/*  size="small"*/}
                  {/*  options={OfferStatusList}*/}
                  {/*  defaultValue={params.status}*/}
                  {/*/>*/}

                  <div className="filter per-50 horizon right">
                    <SearchInput placeholder="Search Offer" />
                  </div>
                </div>
                <div>
                  <AppliedFilters
                    exceptions={APPLIED_FILTER_EXCEPTIONS}
                    hocParams={queryStringFormatter({
                      params,
                      array: QUERY_ARRAYS,
                    })}
                  />
                </div>
                {state === 'listing' ? (
                  <Loader />
                ) : (
                  <div className="mt-10">
                    {!meta.count ? (
                      <InfoBoard />
                    ) : (
                      <React.Fragment>
                        {list.map(i => (
                          <Card
                            key={i.id}
                            id={i.id}
                            data={i}
                            networkLabels={networkLabels}
                          />
                        ))}
                        <Pagination
                          className="mt-30 mb-40"
                          offset={params.offset}
                          count={meta.count}
                          limit={meta.limit}
                          params={params}
                          Link={Link}
                        />
                      </React.Fragment>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
        {/*  MODALS */}
        <Route path={`${match.path}/:id/edit`} component={Edit} />
        <Route path={`${match.path}/:id/code`} component={GetCode} />
        {/* /MODALS */}
      </React.Fragment>
    )
  })
)
