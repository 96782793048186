import React from 'react'

export const CommissionLogs = props => {
  return (
    <div id="scrollBar" className="commission-log-table">
      <table className="list-table-modal">
        <thead>
          <tr>
            <td>START DATE</td>
            <td>END DATE</td>
            <td>PREVIOUS COMMISSION</td>
            <td>CURRENT COMMISSION</td>
            <td>STATUS</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>12 Jun 2020</td>
            <td>20 Jun 2020</td>
            <td>%8</td>
            <td>%10</td>
            <td>Active</td>
          </tr>
          <tr className="passive">
            <td>12 Jun 2020</td>
            <td>20 Jun 2020</td>
            <td>%8</td>
            <td>%10</td>
            <td>Passive</td>
          </tr>
          <tr className="passive">
            <td>12 Jun 2020</td>
            <td>20 Jun 2020</td>
            <td>%8</td>
            <td>%10</td>
            <td>Passive</td>
          </tr>
          <tr className="passive">
            <td>12 Jun 2020</td>
            <td>20 Jun 2020</td>
            <td>%8</td>
            <td>%10</td>
            <td>Passive</td>
          </tr>
          <tr className="passive">
            <td>12 Jun 2020</td>
            <td>20 Jun 2020</td>
            <td>%8</td>
            <td>%10</td>
            <td>Passive</td>
          </tr>
          <tr className="passive">
            <td>12 Jun 2020</td>
            <td>20 Jun 2020</td>
            <td>%8</td>
            <td>%10</td>
            <td>Passive</td>
          </tr>
          <tr className="passive">
            <td>12 Jun 2020</td>
            <td>20 Jun 2020</td>
            <td>%8</td>
            <td>%10</td>
            <td>Passive</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
