import {inject, observer} from 'mobx-react'
import React, {useState} from 'react'
import {CustomAutoComplete} from 'components'

export const AutoCompleteFilter = inject('UIStore')(
  observer(props => {
    const {store, name, label} = props
    const [options, setOptions] = useState([])

    const allOptions = [...options, ...store.searchableSelected]
    const uniqueArray = removeDuplicates(allOptions, 'value')
    return (
      <div className="section">
        <span className="mb-10">{label}</span>
        <CustomAutoComplete
          name={name}
          label={label}
          options={uniqueArray}
          onInputChange={async e => {
            const value = e.target.value
            if (!value) return
            const newOptions = await store.searchable({
              search_text: value,
              field: ['id', 'name'],
            })
            setOptions(newOptions)
          }}
          onChange={data => (store.searchableSelected = data)}
        />
      </div>
    )
  })
)

const removeDuplicates = (originalArray, prop) => {
  const newArray = []
  const lookupObject = {}

  originalArray.reverse().forEach(i => (lookupObject[i[prop]] = i))
  Object.values(lookupObject).forEach(i => newArray.push(i))

  return newArray
}
