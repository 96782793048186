import {TextField, withStyles} from '@material-ui/core'
import {Autocomplete} from '@material-ui/lab'
import {MoreLess} from '@evet/library'
import React from 'react'
import {Controller, useFormContext} from 'react-hook-form'
import {FiCheck} from 'react-icons/all'

// https://material-ui.com/api/autocomplete/#css
const StyledAutoComplete = withStyles({
  root: {
    border: '1px solid #ccc',
    padding: '2px 10px',
    backgroundColor: '#fff',
    borderRadius: 6,
  },

  input: {
    position: 'relative',
    backgroundColor: 'transparent',
    fontSize: 14,
    width: '100%',
    padding: 0,
    '&:focus': {
      border: 'none',
    },
    '&:disabled': {
      color: '#636363',
    },
  },
  paper: {
    position: 'absolute',
    width: 'auto',
    left: '-10px',
  },
  option: {
    fontSize: '12px',
    width: '170px',
  },
  noOptions: {
    fontSize: '12px',
  },
})(Autocomplete)

export const CustomAutoComplete = React.forwardRef((props, ref) => {
  const {control, watch, submitOnChange, onSubmit} = useFormContext()
  const {name, label, options, onChange: onSelectChange, ...rest} = props
  const value = watch(name)

  // return (<StyledAutoComplete
  // 	fullWidth
  // 	disableCloseOnSelect
  // 	multiple
  // 	openOnFocus
  // 	limitTags={0}
  // 	renderTags={() => null}
  // 	options={options}
  // 	noOptionsText='No option, enter at least 3 letter'
  // 	getOptionLabel={option => option.name}
  // 	getOptionSelected={(option, value) => {
  // 		return option.value === value.value;
  // 	}}
  // 	renderOption={(option, {selected}) => (
  // 		<div className='horizon between per-100'>
  // 			<MoreLess content={option.name} maxLimit="20"/>
  //
  // 			<div className={"check-mark " + (selected && 'selected')}>
  // 				<FiCheck />
  // 			</div>
  //
  // 		</div>)}
  // 	renderInput={params => (
  // 		<TextField
  // 			{...params}
  // 			InputProps={{...params.InputProps, disableUnderline: true}}
  // 			placeholder={value && value.length ?
  // 				value.length + ' selected' :
  // 				'SELECT ' + label
  // 			}
  // 		/>
  // 	)}
  // 	value={value}
  // 	ref={ref}
  // 	{...rest}
  // />)

  return (
    <React.Fragment>
      <Controller
        render={({onChange, ...props}) => (
          <StyledAutoComplete
            fullWidth
            disableCloseOnSelect
            multiple
            openOnFocus
            limitTags={0}
            renderTags={() => null}
            noOptionsText="No option, enter at least 3 letter"
            getOptionSelected={(option, value) => {
              return value
                ? option.value === value || option.value === value.value
                : false
            }}
            options={options}
            getOptionLabel={option => option.name || option.label}
            renderOption={(option, {selected}) => (
              <div className="horizon between per-100">
                <MoreLess content={option.name || option.label} maxLimit="20" />
                <div className={'check-mark ' + (selected && 'selected')}>
                  <FiCheck />
                </div>
              </div>
            )}
            renderInput={params => (
              <TextField
                {...params}
                //variant="outlined"
                InputProps={{...params.InputProps, disableUnderline: true}}
                placeholder={
                  value && value.length
                    ? value.length + ' selected'
                    : 'SELECT ' + label
                }
              />
            )}
            onChange={(e, data) => {
              onChange(data)
              if (onSelectChange) onSelectChange(data)
              if (submitOnChange) onSubmit()
            }}
            {...props}
            {...rest}
          />
        )}
        name={name}
        control={control}
      />
    </React.Fragment>
  )
})
