import {inject, observer} from 'mobx-react'
import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {LayoutDefault, LayoutGuest} from 'layouts'
// import * as reportingTabs from 'reportingTabs'
// import * as networkReportingTabs from 'networkReportingTabs'
// import * as advertiserReportingTabs from 'advertiserReportingTabs'
// import * as publisherReportingTabs from 'publisherReportingTabs'

import {
  AdvertiserLibrary,
  Advertisers,
  Client,
  Clients,
  Commission,
  Logout,
  NoMatch,
  Offers,
  PublisherLibrary,
  Performance,
  NetworkSingle,
  Dashboard,
  Transactions,
  UsersManagement,
} from 'pages'
//
// const renderReporting = ({
//   me,
//   authenticated,
//   components,
//   mainRoute,
//   defaultRoute,
// }) => {
//   return (
//     <Switch path={mainRoute}>
//       {components.map(i => (
//         <RestrictedRoute
//           key={`${mainRoute}/:page(${i.route})`}
//           exact
//           path={`${mainRoute}/:page(${i.route})`}
//           component={i.component}
//           authenticated={authenticated}
//           me={me}
//         />
//       ))}
//       <Redirect to={`${mainRoute}/${defaultRoute}`} />
//     </Switch>
//   )
// }

const RestrictedRoute = ({
  component: Component,
  layout: Layout = LayoutDefault,
  authenticated,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props =>
        authenticated ? (
          <Layout {...rest}>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: '/logout',
              state: {from: props.location},
            }}
          />
        )
      }
    />
  )
}

const GuestRoute = ({
  component: Component,
  layout: Layout = LayoutGuest,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  )
}

export const Routing = inject(
  'UIStore',
  'AuthStore'
)(
  observer(props => {
    const {authenticated, me} = props.AuthStore
    const pending = false

    return pending &&
      !['/pending', '/logout'].includes(props.location.pathname) ? (
      <Redirect to="/pending" />
    ) : (
      <Switch>
        <GuestRoute
          path="/logout"
          component={Logout}
          layout={LayoutDefault}
          me={me}
        />
        <RestrictedRoute
          exact
          path="/"
          component={Dashboard}
          authenticated={authenticated}
          me={me}
        />

        <RestrictedRoute
          path="/advertisers"
          component={Advertisers}
          authenticated={authenticated}
          me={me}
        />

        <RestrictedRoute
          path="/advertiser-library"
          component={AdvertiserLibrary}
          authenticated={authenticated}
          me={me}
        />

        <RestrictedRoute
          path="/offers"
          component={Offers}
          authenticated={authenticated}
          me={me}
        />

        <RestrictedRoute
          exact
          path="/clients/:id(new)?"
          component={Clients}
          authenticated={authenticated}
          me={me}
        />

        {/* 3 fold listing */}
        {/*<RestrictedRoute*/}
        {/*  path={*/}
        {/*    '/clients/:clientID/publishers/:publisherID?/properties/:propertyID?'*/}
        {/*  }*/}
        {/*  component={Client}*/}
        {/*  authenticated={authenticated}*/}
        {/*  me={me}*/}
        {/*/>*/}
        {/*<RestrictedRoute*/}
        {/*  path={'/clients/:clientID/publishers/:publisherID?'}*/}
        {/*  component={Client}*/}
        {/*  authenticated={authenticated}*/}
        {/*  me={me}*/}
        {/*/>*/}
        <RestrictedRoute
          path={'/clients/:clientID'}
          component={Client}
          authenticated={authenticated}
          me={me}
        />
        {/* /3 fold listing */}

        <RestrictedRoute
          exact
          path="/publisher-library"
          component={PublisherLibrary}
          authenticated={authenticated}
          me={me}
        />
        <RestrictedRoute
          exact
          path="/transactions"
          component={Transactions}
          authenticated={authenticated}
          me={me}
        />
        <RestrictedRoute
          exact
          path="/commission"
          component={Commission}
          authenticated={authenticated}
          me={me}
        />
        <RestrictedRoute
          exact
          path="/reporting"
          component={Performance}
          authenticated={authenticated}
          me={me}
        />
        <RestrictedRoute
          exact
          path="/user-management"
          component={UsersManagement}
          authenticated={authenticated}
          me={me}
        />

        {/*<RestrictedRoute*/}
        {/*  exact*/}
        {/*  path="/redirections"*/}
        {/*  component={Redirections}*/}
        {/*  authenticated={authenticated}*/}
        {/*  me={me}*/}
        {/*/>*/}

        {/*<RestrictedRoute*/}
        {/*  exact*/}
        {/*  path="/clients/:elder/:contactsPage(contacts)?/:contactID?"*/}
        {/*  component={Client}*/}
        {/*  authenticated={authenticated}*/}
        {/*  me={me}*/}
        {/*/>*/}
        {/*<RestrictedRoute*/}
        {/*  exact*/}
        {/*  path="/clients/:elder/(publishers)?/:grand?/(properties)?/:parent?/:advertisersPage(advertisers)?"*/}
        {/*  component={Client}*/}
        {/*  authenticated={authenticated}*/}
        {/*  me={me}*/}
        {/*/>*/}
        {/*<RestrictedRoute*/}
        {/*  path={'/clients/:elder/publishers/:grand/properties/:parent?'}*/}
        {/*  component={Client}*/}
        {/*  authenticated={authenticated}*/}
        {/*  me={me}*/}
        {/*/>*/}
        {/*<RestrictedRoute*/}
        {/*  path={'/clients/:elder/publishers/:grand?'}*/}
        {/*  component={Client}*/}
        {/*  authenticated={authenticated}*/}
        {/*  me={me}*/}
        {/*/>*/}

        {/*{renderReporting({*/}
        {/*  me,*/}
        {/*  authenticated,*/}
        {/*  mainRoute: '/reporting',*/}
        {/*  defaultRoute: 'date',*/}
        {/*  components: [*/}
        {/*    {component: reportingTabs.DateTab, route: 'date'},*/}
        {/*    {component: reportingTabs.CountryTab, route: 'country'},*/}
        {/*    {component: reportingTabs.NetworkTab, route: 'network'},*/}
        {/*    {component: reportingTabs.PublisherTab, route: 'publisher'},*/}
        {/*    {component: reportingTabs.AdvertiserTab, route: 'advertiser'},*/}
        {/*    {component: reportingTabs.PageTab, route: 'page'},*/}
        {/*    {component: reportingTabs.LinksTab, route: 'link'},*/}
        {/*    {component: reportingTabs.SiteTab, route: 'site'},*/}
        {/*    {component: reportingTabs.DeviceTab, route: 'device'},*/}
        {/*  ],*/}
        {/*})}*/}
        {/*{renderReporting({*/}
        {/*  me,*/}
        {/*  authenticated,*/}
        {/*  mainRoute: '/network-reporting',*/}
        {/*  defaultRoute: 'network',*/}
        {/*  components: [*/}
        {/*    {component: networkReportingTabs.DateTab, route: 'date'},*/}
        {/*    {component: networkReportingTabs.CountryTab, route: 'country'},*/}
        {/*    {component: networkReportingTabs.NetworkTab, route: 'network'},*/}
        {/*    {component: networkReportingTabs.PublisherTab, route: 'publisher'},*/}
        {/*    {*/}
        {/*      component: networkReportingTabs.AdvertiserTab,*/}
        {/*      route: 'advertiser',*/}
        {/*    },*/}
        {/*    {component: networkReportingTabs.PageTab, route: 'page'},*/}
        {/*    {component: networkReportingTabs.LinksTab, route: 'link'},*/}
        {/*    {component: networkReportingTabs.SiteTab, route: 'site'},*/}
        {/*    {component: networkReportingTabs.DeviceTab, route: 'device'},*/}
        {/*  ],*/}
        {/*})}*/}
        {/*{renderReporting({*/}
        {/*  me,*/}
        {/*  authenticated,*/}
        {/*  mainRoute: '/advertiser-reporting',*/}
        {/*  defaultRoute: 'advertiser',*/}
        {/*  components: [*/}
        {/*    {component: advertiserReportingTabs.DateTab, route: 'date'},*/}
        {/*    {component: advertiserReportingTabs.CountryTab, route: 'country'},*/}
        {/*    {component: advertiserReportingTabs.NetworkTab, route: 'network'},*/}
        {/*    {*/}
        {/*      component: advertiserReportingTabs.PublisherTab,*/}
        {/*      route: 'publisher',*/}
        {/*    },*/}
        {/*    {*/}
        {/*      component: advertiserReportingTabs.AdvertiserTab,*/}
        {/*      route: 'advertiser',*/}
        {/*    },*/}
        {/*    {component: advertiserReportingTabs.PageTab, route: 'page'},*/}
        {/*    {component: advertiserReportingTabs.LinksTab, route: 'link'},*/}
        {/*    {component: advertiserReportingTabs.SiteTab, route: 'site'},*/}
        {/*    {component: advertiserReportingTabs.DeviceTab, route: 'device'},*/}
        {/*  ],*/}
        {/*})}*/}
        {/*{renderReporting({*/}
        {/*  me,*/}
        {/*  authenticated,*/}
        {/*  mainRoute: '/publisher-reporting',*/}
        {/*  defaultRoute: 'publisher',*/}
        {/*  components: [*/}
        {/*    {component: publisherReportingTabs.DateTab, route: 'date'},*/}
        {/*    {component: publisherReportingTabs.CountryTab, route: 'country'},*/}
        {/*    {component: publisherReportingTabs.NetworkTab, route: 'network'},*/}
        {/*    {*/}
        {/*      component: publisherReportingTabs.PublisherTab,*/}
        {/*      route: 'publisher',*/}
        {/*    },*/}
        {/*    {*/}
        {/*      component: publisherReportingTabs.AdvertiserTab,*/}
        {/*      route: 'advertiser',*/}
        {/*    },*/}
        {/*    {component: publisherReportingTabs.PageTab, route: 'page'},*/}
        {/*    {component: publisherReportingTabs.LinksTab, route: 'link'},*/}
        {/*    {component: publisherReportingTabs.SiteTab, route: 'site'},*/}
        {/*    {component: publisherReportingTabs.DeviceTab, route: 'device'},*/}
        {/*  ],*/}
        {/*})}*/}
        <RestrictedRoute
          exact
          path="/networks/:id"
          component={NetworkSingle}
          authenticated={authenticated}
          me={me}
        />
        <GuestRoute component={NoMatch} layout={LayoutDefault} me={me} />
      </Switch>
    )
  })
)
