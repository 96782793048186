import {request} from 'helpers'

export class Services {
  static create = async () => null

  static read = async params => {
    return await request.get(`/redirections/`, params)
  }

  static update = async () => null

  static detail = async ({id, params = null}) => {
    return await request.get(`/redirections/${id}/`, params)
  }

  static delete = async () => null
}
