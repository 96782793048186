export class Meta {
  constructor(data) {
    if (data) Object.entries(data).forEach(([k, i]) => (this[k] = i))
  }
}

export class Library {
  constructor(data) {
    if (data) {
      Object.entries(data).forEach(([k, i]) => (this[k] = i))
      this.id = String(data.id)
    }
  }

  get similars() {
    return (this['similar_advertisers'] || []).map(i => new Library(i))
  }

  get label() {
    return this['name']
  }

  get value() {
    return this['id']
  }
}
