import React, {useState} from 'react'
import {Modal} from 'components'
import {Button} from 'components'
import {
  Form,
  Input as CustomInput,
  Select as CustomSelect,
  Submit,
} from '@evet/library'
import * as Yup from 'yup'

const STEP = 3

export const Property = props => {
  const [properties] = useState(['property-form-' + 0])
  const {nextStep, prevStep, handleClose, data, types, initialStep} = props

  const defaultValues = {
    'publisher.properties[0].publisher_type_id': (data.types || []).length
      ? data.types[0]
      : '', //TODO
    ...data,
  }

  // const add = () =>
  //   setProperties([...properties, 'property-form-' + properties.length + '-' + Math.random()]);
  // const remove = e => setProperties(properties.filter(i => i !== e.target.id));

  const schema = Yup.object().shape({
    publisher: Yup.object({
      properties: Yup.array()
        .of(
          Yup.object().shape({
            publisher_type_id: Yup.number().required('required'),
            name: Yup.string().required('type required'),
            contact_name: Yup.string(),
            uri: Yup.string().required('uri required'),
            contact_email: Yup.string(),
            phone: Yup.string(),
          })
        )
        .compact()
        .required(),
    }),
  })

  return (
    <Modal onClose={handleClose} title="Add Properties">
      <Form
        schema={schema}
        defaultValues={defaultValues}
        onSubmit={nextStep}
        className="vertical left top p-10 ">
        {properties.map((i, k) => (
          <div key={i} className="horizon between  between per-100 ">
            <div className="vertical left top per-48">
              <CustomSelect
                className="per-100 mt-10"
                label="Type"
                name={`publisher[properties][${k}]publisher_type_id`}
                disableSearch
                options={types}
              />
              <label className="vertical left per-100 mt-10">
                <CustomInput
                  className="per-100"
                  label="Name"
                  name={`publisher[properties][${k}]name`}
                />
              </label>
              <label className="vertical left per-100 mt-10">
                <CustomInput
                  className="per-100"
                  label="Contact Name or Username"
                  name={`publisher[properties][${k}][contact_name]`}
                />
              </label>
            </div>
            <div className="vertical left top per-48">
              <label className="vertical left per-100 mt-10">
                <CustomInput
                  className="per-100"
                  label="URI"
                  name={`publisher[properties][${k}][uri]`}
                />
              </label>
              <label className="vertical left per-100 mt-10">
                <CustomInput
                  className="per-100"
                  label="Phone"
                  name={`publisher[properties][${k}][phone]`}
                />
              </label>
              <label className="vertical left per-100 mt-10">
                <CustomInput
                  className="per-100"
                  label="Contact E-mail"
                  name={`publisher[properties][${k}][contact_email]`}
                />
              </label>
            </div>
          </div>
        ))}
        <div className="horizon right middle per-100 mt-10">
          {STEP > initialStep && (
            <Button className="secondary-button" onClick={prevStep}>
              Back
            </Button>
          )}
          <Submit className="ml-6" loading={props.loading}>
            Submit
          </Submit>
        </div>
      </Form>
    </Modal>
  )
}
