import React from 'react'
import {MdHome} from 'react-icons/all'
import {useHistory} from 'react-router-dom'

export const HeadLine = props => {
  const history = useHistory()
  const handleHome = () => {
    history.push('/')
  }
  return (
    <div className="headline">
      <div className="container pt-20 pb-20 horizon between middle">
        <div className="title vertical left ">
          <div className="breadcrumb">
            <button
              onClick={handleHome}
              style={{
                border: 0,
                background: 'transparent',
                padding: 0,
                cursor: 'pointer',
              }}>
              <MdHome />
              Dashboard
            </button>
          </div>
          <div className="title">
            <h2 className={props.className}>{props.title}</h2>
          </div>
        </div>
        {props.children}
      </div>
    </div>
  )
}
