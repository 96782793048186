import {inject, observer} from 'mobx-react'
import React from 'react'
import {MdSubdirectoryArrowRight} from 'react-icons/all'
import {Button} from 'components'

export const Row = inject('ClientStore')(
  observer(props => {
    const {id, grand, parent, data} = props

    const handleApprove = async () => {
      if (
        await props.ClientStore.patchProperty({
          id,
          grand,
          parent,
          data: {status: 'JOINED'},
        })
      ) {
        // const i = props.ClientStore._list
        //   .get(grand)
        //   .publishers.find(i => i.id === parent)
        //   .properties.findIndex(i => i.id === id)
        // props.ClientStore._list
        //   .get(grand)
        //   .publishers.find(i => i.id === parent)
        //   .properties.splice(i,1)
      }
    }

    return (
      <React.Fragment>
        <tr className="light-b-1">
          <td>
            {' '}
            <MdSubdirectoryArrowRight />{' '}
          </td>
          <td>{data.uri}</td>
          <td>PID : {data.id}</td>
          <td>{data.publisher_type.name}</td>
          <td>{data.status}</td>
          <td>SALES: $123**</td>
          <td>
            <div className="horizon right">
              <Button
                className="secondary-button small"
                onClick={handleApprove}>
                Approve
              </Button>
            </div>
          </td>
        </tr>
      </React.Fragment>
    )
  })
)
