import {DateTime} from 'components'
import {inject, observer} from 'mobx-react'
import React, {useEffect} from 'react'
import {
  MdCheck,
  MdNotifications,
  MdNotificationsActive,
  MdSettings,
} from 'react-icons/md'
import {Link} from 'react-router-dom'

import {Item} from './Item'

export const Notifications = inject(
  'UIStore',
  'NotificationStore'
)(
  observer(props => {
    const isOpen = props.UIStore.dropdown.Notification
    useEffect(() => {
      props.NotificationStore.read({limit: 10, offset: 0})
    }, [props.NotificationStore])

    const toggleMenu = e => {
      if (e) e.stopPropagation()
      props.UIStore.toggleDropDown('Notification')
      window.addEventListener('click', () => {
        props.UIStore.toggleDropDown('Notification', false)
      })
    }

    const {meta, list} = props.NotificationStore
    return (
      <div className="notifications vertical middle ml-10">
        <div
          id="notificationsBtn"
          className="notification-button pl-20 pr-20"
          onClick={toggleMenu}>
          <div className="badge">{meta.unseen_count}</div>
          <MdNotifications className="passive sz-20" />
        </div>
        <div
          id="notificationsList"
          className={
            'notification-list shadow-box ' + (isOpen ? 'show' : 'hide')
          }>
          <div className="title horizon between middle per-100">
            <Link to="/notifications">
              <span className="grey75 sz-14">Notification</span>
            </Link>
            <Link to="/notifications/settings">
              <MdSettings />
            </Link>
          </div>
          <div id="scrollBar" className="scroll-base">
            {list.map(i => (
              <Item
                key={'n' + i.id}
                class="icon red"
                icon={i.read ? <MdCheck /> : <MdNotificationsActive />}
                title={i.title}
                date={<DateTime date={i.creation_date} />}
                content={i.message}
                to={'/notifications/1/' + i.id}
                read={i.is_seen}
              />
            ))}
          </div>
          <div className="action horizon center">
            <Link to="/notifications" className="see-more sz-12">
              See All
            </Link>
          </div>
        </div>
      </div>
    )
  })
)
