import {MoreOptions, EditableInput} from '@evet/library'
import {inject, observer} from 'mobx-react'
import React from 'react'
import {useParams} from 'react-router-dom'

export const Row = inject('PropertyAdvertisersStore')(
  observer(props => {
    const {elder, grand, parent} = useParams()
    const {advertiser, earning_percentage, share_percentage, id} = props
    const {name} = advertiser

    const handleDelete = () => {
      props.PropertyAdvertisersStore.delete({
        elder,
        grand,
        parent,
        connectionID: id,
      })
    }

    const handleSubmit = async (_, data) => {
      return await props.PropertyAdvertisersStore.patch({
        elder,
        grand,
        parent,
        connectionID: id,
        data,
        forceRefresh: true,
      })
    }

    return (
      <React.Fragment>
        <tr>
          <td>{name}</td>
          <td>xxx</td>
          <td>xxx</td>
          <td>xxx</td>
          <td>
            <EditableInput onSubmit={handleSubmit} count={share_percentage} />
          </td>
          <td>{earning_percentage}%</td>
          <td>
            <MoreOptions
              offset="br"
              innerClass="top right"
              links={
                <ul>
                  <li onClick={handleDelete}>Delete</li>
                </ul>
              }
            />
          </td>
        </tr>
      </React.Fragment>
    )
  })
)
