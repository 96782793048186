import {inject, observer} from 'mobx-react'
import React from 'react'
import {Button} from '@evet/library'

export const Row = inject('ClientAdvertiserStore')(
  observer(props => {
    const {parent, id, data} = props
    const publisher_id = data.publisher_property.publisher.id
    const property_id = data.publisher_property.id
    const handleApprove = async () => {
      await props.ClientAdvertiserStore.update({
        client_id: parent,
        publisher_id,
        property_id,
        id,
        data: {status: 'JOINED'},
      })
    }
    const handleIgnore = async () => {
      await props.ClientAdvertiserStore.update({
        client_id: parent,
        publisher_id,
        property_id,
        id,
        data: {status: 'DECLINED'},
      })
    }

    return (
      <tr>
        <td>{data.publisher_property.publisher.name}</td>
        <td>{data.publisher_property.name}</td>
        <td>{data.advertiser.name}</td>
        <td>
          <div className="horizon right middle ">
            <Button className="apply-button light " onClick={handleApprove}>
              Approve
            </Button>
            <Button className="alert-button light  ml-6" onClick={handleIgnore}>
              Decline
            </Button>
          </div>
        </td>
      </tr>
    )
  })
)
