import {TableSelector} from 'components/Table/TableSelector'
import {Form, Input as CustomInput, Select as CustomSelect} from '@evet/library'
import {Button} from 'components'
import React from 'react'
import {CgExport, HiOutlineFilter} from 'react-icons/all'

export const TableMeta = ({
  allColumns,
  selectedColumns,
  setSelectedColumns,
  SaveFilter,
  // SelectFilter,
}) => {
  return (
    <div className="table-header per-100">
      <div className="search">
        <Form>
          <CustomInput
            icon="search"
            placeholder="Search..."
            className="per-100"
          />
        </Form>
      </div>
      <div className="actions">
        <TableSelector
          columnFilter
          icon={<HiOutlineFilter />}
          className="mr-6"
          children={
            <Form defaultValues={{bla: selectedColumns}} submitOnChange>
              <CustomSelect
                name="bla"
                dropdownType="alwaysOpen"
                options={allColumns}
                multi
                onChange={setSelectedColumns}
              />
            </Form>
          }
        />

        {/*<TableSelector icon={<AiOutlineUnorderedList />} className="ml-6">*/}
        {/*  <Form>*/}
        {/*    <SelectFilter label="" name="bla" dropdownType="alwaysOpen" />*/}
        {/*  </Form>*/}
        {/*</TableSelector>*/}
        <SaveFilter className="secondary-button" />
        <Button
          icon={<CgExport />}
          className="ml-6 secondary-button circle"
          name="Export"
        />
      </div>
    </div>
  )
}
