import React from 'react'
import {MoreLess} from '@evet/library'
// import {MdLink} from 'react-icons/md';

export const WebsiteOpener = props => {
  const {url, limit, text} = props

  return (
    <React.Fragment>
      {url && (
        <a
          href={url && (url.startsWith('http') ? url : '//' + url)}
          target="_blank"
          rel="noreferrer noopener">
          {/* <MdLink /> */}
          {text ? text : <MoreLess content={url} maxLimit={limit} />}
        </a>
      )}
    </React.Fragment>
  )
}
