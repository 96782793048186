import React, {useState} from 'react'
import {AiOutlineEye} from 'react-icons/all'
import {Button} from 'components'
import {Item} from 'pages/Publisher/Clients/Item'
import {Link} from 'react-router-dom'

export const Card = props => {
  const {id, data} = props
  const {name, created_at, country = {}, publisher_count = 0} = data
  const hasPendingAdvertiser = data.pending_advertiser_count
  const [showItems, setShowItems] = useState(false)

  const toggleShowItems = () => setShowItems(!showItems)

  return (
    <div className={showItems ? 'client-card  opened' : 'client-card '}>
      <div className="data" key={'client-' + id}>
        <div className="item">
          <div className="client-id horizon left middle p-10">
            <Button className="logo sz-22" to={`/clients/${id}/contacts/${id}`}>
              {name.slice(0, 1)}
            </Button>
            <Link className="detail vertical middle left" to={`/clients/${id}`}>
              <p>CID: {id}</p>
              <p>{name}</p>
              <p>{created_at}</p>
            </Link>
          </div>
        </div>
        <div className="item">
          <span>Contact</span>
          <div className="horizon left middle mt-6">
            <Button to={`/clients/${id}/contacts`} className="basic-button">
              0
            </Button>
            <Button
              label="add new"
              to={`/clients/${id}/contacts/new`}
              className="add-button light  circle small  ml-6"
            />
          </div>
        </div>
        <div className="item">
          <span>Region</span> <p className="mt-6">{country.name || '-'}</p>
        </div>
        <div className={showItems ? 'item active' : 'item'}>
          <span>Pending List</span>
          {hasPendingAdvertiser > 0 ? (
            <Button onClick={toggleShowItems} className="basic-button  mt-6">
              {data.pending_advertiser_count}
              <span className="ml-6">{showItems ? '(hide)' : '(view)'}</span>
            </Button>
          ) : (
            <p>{data.pending_advertiser_count}</p>
          )}
        </div>
        <div className="item">
          <span>Publishers</span>
          <Button to={'/clients/' + id} className="basic-button mt-6">
            {publisher_count}
            <AiOutlineEye className="ml-6 sz-14" />
          </Button>
        </div>
        <div className="item">
          <span>Users</span>
          {data.user_count ? (
            <Button to={'/clients/' + id} className="basic-button mt-6">
              {data.user_count}
            </Button>
          ) : (
            '-'
          )}
        </div>
      </div>
      {showItems && <Item id={id} data={data} />}
    </div>
  )
}
