import {makeObservable, observable} from 'mobx'

export class Meta {
  constructor(data) {
    if (data) Object.entries(data).forEach(([k, i]) => (this[k] = i))
  }
}

export class Client {
  publishers = []
  constructor(data) {
    if (data) Object.entries(data).forEach(([k, i]) => (this[k] = i))

    makeObservable(this, {
      publishers: observable,
    })
  }

  // get id() {
  //   return this['user_id']
  // }
}
