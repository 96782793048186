// https://colorswall.com/palette/326/
export const defaultColors = [
  '#ff6358',
  '#ffd246',
  '#78d237',
  '#28b4c8',
  '#2d73f5',
  '#aa46be',
]
export const defaultPassiveColor = '#aaaaaa'
