import {action, computed, makeObservable, observable} from 'mobx'
import {Filter as Model} from '../models'
import {TransactionServices as Service} from '../services'

export default class TransactionFilterStore {
  state = 'initial'
  _list = new observable.map()

  constructor(stores) {
    this.stores = stores
    makeObservable(this, {
      state: observable,
      _list: observable.deep,

      list: computed,

      read: action,

      fetchSuccess: action.bound,
      handleError: action.bound,
    })
  }

  get list() {
    return [...this._list.values()]
  }

  read = async ({params = {}}) => {
    this.state = 'listing'
    return await Service.filters(params).then(
      this.fetchSuccess,
      this.handleError
    )
  }

  fetchSuccess = res => {
    if (res) {
      this._list = new observable.map()
      res.items.forEach(i => {
        const item = new Model(i || {})
        this._list.set(item.report_type, item)
      })
    }
    return !!(this.state = 'done')
  }

  handleError = error => {
    this.state = 'error'
    return this.stores.SystemMessageStore.handleError(error)
  }
}
