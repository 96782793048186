export {request} from './request'
export {DateHelper} from './DateHelper'
export {qs} from '@evet/library'

// commission_summary %5 gibi gostermemiz gereken yerlerde kullanilacak.
// bunu eger flat doluysa "min-max currency" veya percentage doluysa "min-max%" gibi gostermemiz lazim.
// min ve max degerleri ayniysa iki kere yazmayip "min%" gibi gosterebiliriz.
const showMinMax = (min, max) => {
  return min === max ? Number(min) : `${Number(min)}-${Number(max)}`
}

const showComm = (comm, currency) => {
  return showMinMax(comm.min, comm.max) + currency
}

const getComm = (summary = {}, currency = null) => {
  return Number((summary.flat || {}).max)
    ? showComm(summary.flat || {}, currency || '$')
    : showComm(summary.percentage || {}, '%')
}

export const commissionParser = (advertiser = {}) => {
  const networkCommText = getComm(
    advertiser.commission_summary,
    advertiser.currency
  )
  const evetCommText = getComm(
    advertiser.shared_commission_summary,
    advertiser.currency
  )

  return [networkCommText, evetCommText]
}
