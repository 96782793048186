import {Button, WebsiteOpener} from 'components'
import {MoreOptions} from '@evet/library'
import {inject, observer} from 'mobx-react'
import React from 'react'
import {Link} from 'react-router-dom'
import {useRouteMatch} from 'react-router'

export const Item = inject('ClientStore')(
  observer(({id, elder, grand, data, ...props}) => {
    const match = useRouteMatch()

    const {
      name,
      contact_name,
      contract_email,
      uri,
      advertiser_count,
      publisher_type,
      status,
    } = data

    const handleApprove = async () => {
      await props.ClientStore.patchProperty({
        grand: elder,
        parent: grand,
        id,
        inList: false,
        data: {status: 'JOINED'},
      })
    }

    const handlePending = async () => {
      await props.ClientStore.patchProperty({
        grand: elder,
        parent: grand,
        id,
        inList: false,
        data: {status: 'PENDING'},
      })
    }

    const handleDelete = async () => {
      await props.ClientStore.deleteProperty({
        elder,
        grand,
        id,
        inList: false,
      })
    }

    return (
      <tr>
        <td>{id}</td>
        <td>
          <WebsiteOpener url={uri} />
        </td>
        <td>{name}</td>
        <td>
          <div className="horizon left middle">
            8
            <Button
              // onClick={showPropertyUsers}
              className="basic-button  ml-6 "
              label="view"
              to={`${match.url}/publishers/${grand}/properties/${id}/users`}
            />
          </div>
        </td>
        <td>{contact_name || '-'}</td>
        <td>{contract_email || '-'}</td>
        <td>{publisher_type ? publisher_type.name : '-'}</td>
        <td>{id}</td>
        <td>xxxx</td>
        <td>
          <div className="horizon left middle">
            <Link
              to={`/clients/${elder}/publishers/${grand}/properties/${id}/contacts`}>
              0
            </Link>
            <Button
              className="add-button light small ml-10"
              to={`/clients/${elder}/publishers/${grand}/properties/${id}/contacts/new`}>
              add
            </Button>
          </div>
        </td>
        <td>
          <Link
            to={`/clients/${elder}/publishers/${grand}/properties/${id}/advertisers`}>
            {advertiser_count}
          </Link>
        </td>
        <td>xxxx</td>
        <td>{status}</td>
        <td>
          <MoreOptions
            offset="c"
            innerClass="top right"
            links={
              <ul>
                <li>
                  <Button className="horizon" onClick={handleApprove}>
                    Approve
                  </Button>
                </li>
                <li>
                  <Button className="horizon" onClick={handlePending}>
                    Set Pending
                  </Button>
                </li>
                <li>
                  <Button className="horizon" onClick={handleDelete}>
                    Delete
                  </Button>
                </li>
              </ul>
            }
          />
        </td>
      </tr>
    )
  })
)
