import {action, computed, makeObservable, observable, toJS} from 'mobx'
import {Library as Model, LibraryMeta as Meta} from '../models'
import {LibraryServices as Service} from '../services'

export default class LibraryStore {
  state = {
    init: false,
    listing: false,
    getting: false,
    error: false,
  }
  _list = new observable.map()
  _similars = new observable.map()
  _item = new Model()
  _searchableSelected = new observable.map()
  _searchable = {}
  _selectable = {}
  meta = {}

  constructor(Stores) {
    this.stores = Stores

    makeObservable(this, {
      _list: observable,
      _similars: observable,
      _item: observable,
      _searchableSelected: observable,
      _searchable: observable,
      state: observable,
      meta: observable,

      list: computed,
      item: computed,
      similars: computed,

      // create: action,
      read: action,
      update: action,
      patch: action,
      getSimilars: action,
      selectable: action,
      searchable: action,

      fetchSuccess: action.bound,
      handleError: action.bound,
    })
    this.state['init'] = true
  }

  get list() {
    return [...this._list.values()]
  }

  get similars() {
    return [...this._similars.values()]
  }

  get item() {
    return toJS(this._item)
  }

  getListItemByID = id => this._list.get(id) || new Model({})

  read = async ({id = null, params = {}}) => {
    if (id) {
      this.state['getting'] = true
      await Service.detail(id, params).then(res => {
        this._item = new Model(res.item || {})
        this.state['getting'] = false
      }, this.handleError)
    } else {
      this.state['listing'] = true
      await Service.read({params}).then(this.fetchSuccess, this.handleError)
    }
  }

  getSimilars({params = {}}) {
    this.state['getting'] = true
    console.log('getSimilars', params)
    return Service.similars({params}).then(res => {
      this._similars = new observable.map()
      res.items.forEach(i => {
        const item = new Model(i || {})
        this._similars.set(item.id, item)
      })
      this.state['getting'] = false
    }, this.handleError)
  }

  update = async (id, data) => {
    if (!data) return false
    return await Service.update(id, data)
      .then(res => {
        //this._item = null;
        return res
      })
      .catch(error => this.stores.SystemMessageStore.handleError(error))
  }

  patch = async (id, data) => {
    if (!data) return false
    return await Service.patch(id, data)
      .then(res => {
        //this._item = null;
        if (res.item) {
          const item = new Model(res.item || {})
          this._list.set(item.id, item)
        }
        return res
      })
      .catch(error => this.stores.SystemMessageStore.handleError(error))
  }

  fetchSuccess(res) {
    if (res.items) {
      this.meta = new Meta(res.meta)
      this._list = new observable.map()
      res.items.forEach(i => {
        const item = new Model(i || {})
        this._list.set(item.id, item)
      })
    }
    this.state['listing'] = false
  }

  handleError(error) {
    this.state['error'] = true
    return this.stores.SystemMessageStore.handleError(error)
  }

  async selectable({field}) {
    return (
      toJS(this._selectable[field]) ||
      (this._selectable[field] = (await Service.selectable({field}))['data'][
        field
      ])
    )
  }

  async searchable({field, search_text, query}) {
    const text = search_text || query
    if (text && text.length > 2)
      return (
        toJS(this._searchable[field + '-' + text]) ||
        (this._searchable[field + '-' + text] = (
          await Service.searchable({
            field,
            search_text: text,
          })
        )['items'].map(i => ({value: i.id, name: i.name})))
      )

    return []
  }

  //@action
  get searchableSelected() {
    return toJS([...this._searchableSelected.values()])
  }

  //@action
  set searchableSelected(data) {
    data.forEach(i => this._searchableSelected.set(i.value, i))
  }
}
