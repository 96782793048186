import {request} from 'helpers'

export class Services {
  static create = async data =>
    await request.post('/advertisers/advertisers/', data).then()

  static read = async params => {
    return await request.get('/advertisers/advertisers/', params)
  }

  static detail = async (id, params = null) => {
    //return await request.get(`/users/all?from=2020-04-28&to=2020-04-29&offset=0&limit=10`+params);
    return await request.get(`/advertisers/advertisers/${id}/`, params)
    // return await request.get(`/network-advertisers/`+id+'?similar=true');
  }

  static update = async (id, data) =>
    await request.put(`/advertisers/advertisers/${id}/`, data).then()

  static patch = async (id, data) =>
    await request.patch(`/advertisers/advertisers/${id}/`, data).then()

  static delete = async () => null

  // static selectable = async ({field}) => await request.get(`/advertisers`, {field})
  static searchable = async ({field, search_text}) =>
    await request.get(`/advertisers/advertisers/`, {field, search_text})
}
