import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import {qs, DateHelper} from 'helpers'
import React from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {FiFilter} from 'react-icons/all'

export const ReportSwitch = ({types, field, data}) => {
  // const {data, page: mainPage} = props
  const [anchorEl, setAnchorEl] = React.useState(null)
  const history = useHistory()
  const location = useLocation()
  // const {page: currentPage} = useParams()
  const params = qs.parse(location.search)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleFilter = e => {
    handleClose()
    const {type} = e.currentTarget.dataset
    //if (data && data.row_filter.field) {
    params['report_type'] = type
    // field.map(i => (params[i.target] = data[i.source]))
    // !IMPORTANT date filter logic
    field.forEach(i => {
      if (i.target === 'date') {
        const _date = new DateHelper(data[i.source])
        params['date_start'] = _date.asUTCISO()
        params['date_end'] = _date.asUTCISO({end: true})
      } else if (i.target === 'group_date') {
        const _date = new DateHelper(data[i.source])
        params['group_date_start'] = _date.asUTCISO()
        params['group_date_end'] = _date.asUTCISO({end: true})
      } else params[i.target] = data[i.source]
    })
    // {
    //     network_id:'source_field',
    //     network_name:'source_field',
    //     network_id:'source_field',
    //     advertiser_id:null
    // }
    // history.push('?' + qs.stringify({...params, date_exact:null}))
    history.push('?' + qs.stringify(params))
    //}
  }

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}>
        <FiFilter />
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        {types.map(i => (
          <MenuItem
            key={'report-switch-' + i.type + '-' + data[i.source]}
            //disabled={currentPage === 'date'}
            data-type={i.type}
            onClick={handleFilter}>
            {i.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}
