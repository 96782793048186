import {request} from 'helpers'

export class Services {
  static create = async () => null

  static read = async ({id, params}) => {
    return await request.get(`/networking/network-summary/${id}/`, params)
  }

  // static searchable = async ({field, search_text}) =>
  // 	await request.get(`/advertisers/network-advertisers/`, {field, search_text})
}
