import {inject, observer} from 'mobx-react'
import React from 'react'
import {HeadLine, Pagination, SearchInput} from 'components'
import {AppliedFilters} from 'components/Filter/AppliedFilters'
import {InfoBoard, Filter} from '@evet/library'
import {useLocation, Link, useRouteMatch, useHistory} from 'react-router-dom'
import useDeepCompareEffect from 'use-deep-compare-effect'
import {Card} from './Card'
import {FilterSection} from 'filter'
import {Loader, qs, queryStringFormatter} from '@evet/library'
import {Route} from 'react-router'
import {NetworkDetail} from 'pages/Advertiser/Advertisers/Modals/NetworkDetail'

const QUERY_ARRAYS = ['category_id']
const APPLIED_FILTER_EXCEPTIONS = ['offset']

export const Advertisers = inject(
  'AdvertiserStore',
  'FilterStore'
)(
  observer(props => {
    const match = useRouteMatch()
    const {search} = useLocation()
    const history = useHistory()
    const params = qs.parse(search)
    // const match = useRouteMatch()
    // const location = useLocation()
    // const params = qs.parse(location.search)

    useDeepCompareEffect(() => {
      props.AdvertiserStore.read({params})
    }, [props.AdvertiserStore, params])
    const {state, meta, list} = props.AdvertiserStore

    useDeepCompareEffect(() => {
      props.FilterStore.readCountries()
      props.FilterStore.readNetworks()
      props.FilterStore.readCategories()
    }, [props.FilterStore])
    const {countries, networks, categories} = props.FilterStore

    return (
      <React.Fragment>
        <section className="vertical center">
          <HeadLine title="Advertisers" className="sz-30 fw-300">
            <div className="options horizon right middle">
              <div className="item" />
            </div>
          </HeadLine>
          <div className="container mt-20 horizon top between">
            <div className="list-page">
              <div className="side-filter">
                <Filter
                  defaultValues={{
                    country: '',
                    network_id: '',
                    ...queryStringFormatter({params, array: QUERY_ARRAYS}),
                  }}
                  params={params}
                  navigate={history.push}>
                  <FilterSection
                    name="network_id"
                    label="Network"
                    options={networks}
                  />
                  <FilterSection
                    name="country"
                    label="Country"
                    options={countries}
                  />
                  <FilterSection
                    name="category_id"
                    label="Category"
                    options={categories}
                    multi
                  />
                </Filter>
              </div>

              <div className="results">
                <div className="section-title horizon between middle">
                  <p className="sz-14 f-grey">Results : {meta.count}</p>
                  <div className="filter per-50 horizon right">
                    <SearchInput placeholder="Search Advertiser" />
                  </div>
                </div>
                <div>
                  <AppliedFilters
                    exceptions={APPLIED_FILTER_EXCEPTIONS}
                    hocParams={queryStringFormatter({
                      params,
                      array: QUERY_ARRAYS,
                    })}
                  />
                </div>
                {state === 'listing' ? (
                  <Loader />
                ) : (
                  <div className="mt-10">
                    {!meta.count ? (
                      <InfoBoard />
                    ) : (
                      <React.Fragment>
                        {list.map(i => (
                          <Card key={i.id} data={i} />
                        ))}
                        <Pagination
                          className="mt-30 mb-40"
                          offset={params.offset}
                          count={meta.count}
                          limit={meta.limit}
                          params={params}
                          Link={Link}
                        />
                      </React.Fragment>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
        {/*  MODALS */}
        <Route
          path={`${match.path}/:advertiserID/networks/:id`}
          component={NetworkDetail}
        />
        {/* /MODALS */}
      </React.Fragment>
    )
  })
)
