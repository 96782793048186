import {makeObservable, observable} from 'mobx'
export class Meta {
  constructor(data) {
    if (data) Object.entries(data).forEach(([k, i]) => (this[k] = i))
  }
}
export class User {
  constructor(data) {
    this.id = data.id || data.user_id
    this.first = data.first_name
    this.last = data.last_name
    this.email = data.email
    this.clients = data.clients
    this.is_verified = data.is_verified
    this.inherited_role = data.inherited_role
    this.direct_role = data.direct_role
    this.has_lower_level_role = data.has_lower_level_role
    makeObservable(this, {
      id: observable,
      first: observable,
      last: observable,
      is_verified: observable,
      inherited_role: observable,
      direct_role: observable,
      has_lower_level_role: observable,
    })
  }

  get name() {
    return this.first
  }

  get fullName() {
    return this.first + ' ' + this.last
  }
}
