import {request} from 'helpers'

export class Services {
  static create = async ({client_id, publisher_id, property_id, data}) =>
    property_id
      ? await request.post(
          `/publishers/clients/${client_id}/publishers/${publisher_id}/properties/${property_id}/users/`,
          data
        )
      : publisher_id
      ? await request.post(
          `/publishers/clients/${client_id}/publishers/${publisher_id}/users/`,
          data
        )
      : await request.post(`/publishers/clients/${client_id}/users/`, data)

  static read = async ({client_id, publisher_id, property_id, params = {}}) =>
    property_id
      ? await request.get(
          `/publishers/clients/${client_id}/publishers/${publisher_id}/properties/${property_id}/users/`,
          params
        )
      : publisher_id
      ? await request.get(
          `/publishers/clients/${client_id}/publishers/${publisher_id}/users/`,
          params
        )
      : await request.get(`/publishers/clients/${client_id}/users/`, params)

  static update = async ({
    client_id,
    user_id,
    publisher_id,
    property_id,
    data,
  }) =>
    property_id
      ? await request.patch(
          `/publishers/clients/${client_id}/publishers/${publisher_id}/properties/${property_id}/users/${user_id}/`,
          data
        )
      : publisher_id
      ? await request.patch(
          `/publishers/clients/${client_id}/publishers/${publisher_id}/users/${user_id}/`,
          data
        )
      : client_id
      ? await request.patch(
          `/publishers/clients/${client_id}/users/${user_id}/`,
          data
        )
      : await request.patch(`/users/publishers/`, data)

  static delete = async ({client_id, user_id, publisher_id, property_id}) =>
    property_id
      ? await request.delete(
          `/publishers/clients/${client_id}/publishers/${publisher_id}/properties/${property_id}/users/${user_id}/`
        )
      : publisher_id
      ? await request.delete(
          `/publishers/clients/${client_id}/publishers/${publisher_id}/users/${user_id}/`
        )
      : client_id
      ? await request.delete(
          `/publishers/clients/${client_id}/users/${user_id}/`
        )
      : await request.patch(`/users/publishers/`)
}

//publishers/clients/${client_id}/users/
