import {Form, qs, Submit} from '@evet/library'
import React from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {AppliedFilters} from './AppliedFilters'
import {AiFillFilter} from 'react-icons/all'

export const Filter = ({
  type,
  defaultValues,
  autocomplete = [],
  appliedFilterExceptions = [],
  hocParams = null,
  submitOnChange = true,
  disableAppliedFilters = false,
  // savedList = [],
  ...props
}) => {
  const history = useHistory()
  const location = useLocation()
  const params = hocParams || {
    ...defaultValues,
    ...qs.parse(location.search),
  }

  autocomplete.forEach(i =>
    typeof params[i] === 'string'
      ? (defaultValues[i] = [{value: params[i], name: params[i]}])
      : (defaultValues[i] = (params[i] || []).map(j => ({
          value: j,
          name: j,
        })))
  )

  const handleSubmit = async data => {
    autocomplete.forEach(i => (data[i] = (data[i] || []).map(j => j.value)))
    delete data['offset']
    history.push('?' + qs.stringify({...params, ...data}))
    return true
  }

  // const onReset = async () => {
  //   history.push('?')
  // }

  return type === 'horizon' ? (
    <div className="reporting-filter">
      <Form
        className="vertical left top per-100 "
        defaultValues={defaultValues}
        onSubmit={handleSubmit}
        noValidate={true}
        submitOnChange={submitOnChange}>
        <div className={'vertical between middle per-100 ' + props.className}>
          {props.children}
          {!submitOnChange && (
            <Submit className="per-100 horizon center">Filter</Submit>
          )}
          {!disableAppliedFilters && (
            <AppliedFilters
              hocParams={hocParams}
              exceptions={appliedFilterExceptions}
            />
          )}
        </div>
      </Form>
    </div>
  ) : (
    <div className="filter-comp">
      <div className="filter-comp-title">
        <AiFillFilter />
        <span>Filter</span>
      </div>
      <Form
        className="filter per-100 border-box "
        defaultValues={defaultValues}
        onSubmit={handleSubmit}
        noValidate={true}
        submitOnChange>
        {props.children}
        {/*<div className="section">*/}
        {/*  <Submit className="per-100 horizon center">Filter</Submit>*/}
        {/*  <Button*/}
        {/*    className="per-100 mt-6 horizon center"*/}
        {/*    label="Clear"*/}
        {/*    onClick={onReset}*/}
        {/*  />*/}
        {/*</div>*/}
      </Form>
    </div>
  )
}
