import {action, computed, makeObservable, observable, toJS} from 'mobx'
import {User as Model, UserMeta as Meta} from '../models'
import {UserServices as Service} from '../services'

export default class UserStore {
  state = 'initial'
  meta = {}
  _list = new observable.map()
  _item = {}

  constructor(stores) {
    this.stores = stores
    makeObservable(this, {
      state: observable,
      meta: observable,
      _list: observable.deep,
      _item: observable,

      list: computed,
      item: computed,

      create: action,
      read: action,
      update: action,
      delete: action,
      fetchSuccess: action.bound,
      handleError: action.bound,
    })
  }

  get list() {
    return [...this._list.values()]
  }

  get item() {
    return toJS(this._item)
  }
  get properties() {
    return [...this._properties.values()]
  }
  get types() {
    return [...this._types]
  }

  getItemById = id => {
    return this._list.get(id) || new Model({})
  }

  create = async ({client_id, publisher_id, property_id, data}) => {
    return await Service.create({client_id, publisher_id, property_id, data})
      .then(res => {
        this._item = null
        this.formData = {}
        const item = new Model(res.item || {})
        this._list.set(item.id, item)
        return res
      })
      .catch(error => this.stores.SystemMessageStore.handleError(error))
  }

  read = async ({client_id = null, id = null, fields = null, params = {}}) => {
    if (fields) {
      this.state = 'properting'
      console.log(this.state)
      await Service.detail({id, fields, params}).then(res => {
        if (res) {
          //todo
          const item = this._list.get(id)
          item['properties'] = res
        }
        this.state = 'done'
        console.log(this.state)
      }, this.handleError)
    } else if (id) {
      this.state = 'getting'
      console.log(this.state)
      return await Service.detail({id}).then(res => {
        if (res) {
          this.state = 'done'
          console.log(this.state)
          return (this._item = new Model(res.item || {}))
        }
        return {}
      }, this.handleError)
    } else {
      this.state = 'listing'
      console.log(this.state)
      await Service.read({client_id, params}).then(
        this.fetchSuccess,
        this.handleError
      )
    }
  }

  update = async ({client_id, publisher_id, property_id, user_id, data}) => {
    return await Service.update({
      client_id,
      publisher_id,
      property_id,
      user_id,
      data,
    })
      .then(res => {
        this._item = null
        this.formData = {}
        const item = new Model(res.item || {})
        this._list.set(item.id, item)
        return res
      })
      .catch(error => this.stores.SystemMessageStore.handleError(error))
  }

  delete = async ({client_id, publisher_id, property_id, user_id}) => {
    return await Service.delete({
      client_id,
      publisher_id,
      property_id,
      user_id,
    })
      .then(() => this._list.delete(user_id))
      .catch(error => this.stores.SystemMessageStore.handleError(error))
  }

  fetchSuccess = res => {
    if (res) {
      this.meta = new Meta(res.meta)
      this._list = new observable.map()
      res.items.forEach(i => {
        const item = new Model(i || {})
        this._list.set(item.id, item)
      })
    }
    this.state = 'done'
  }

  handleError = error => {
    this.state = 'error'
    return this.stores.SystemMessageStore.handleError(error)
  }
}
