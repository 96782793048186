import {inject, observer} from 'mobx-react'
import React, {useState} from 'react'
import {useLocation, useRouteMatch} from 'react-router-dom'
import {MoreLess, LazyLoad} from '@evet/library'
import {commissionParser} from 'helpers'
import {Button} from 'components'
import logo from 'images/no-logo.png'

export const Card = inject('LibraryStore')(
  observer(props => {
    const {id, data} = props
    console.log({data})

    const match = useRouteMatch()
    const {search} = useLocation()

    const [loading, setLoading] = useState(false)
    // const [showNetworkModal, setShowNetworkModal] = useState(false)
    // const [showCancelModal, setShowCancelModal] = useState(false)
    // const [showAddModal, setShowAddModal] = useState(false)

    // const closeModal = () => {
    //   setShowNetworkModal(false)
    //   setShowCancelModal(false)
    //   setShowAddModal(false)
    // }
    // const handleNetworkModal = () => setShowNetworkModal(true)
    // const handleCancelModal = () => setShowCancelModal(true)
    // const handleAddModal = () => setShowAddModal(true)

    const handleDisconnect = async () => {
      if (!(await props.LibraryStore.patch(data.id, {advertiser_id: null})))
        setLoading(false)
    }

    const media = data.logo_url ? '//' + data.logo_url : logo

    return (
      <React.Fragment>
        <tr>
          <td>
            {/*{showNetworkModal && (*/}
            {/*  <NetworkDetail*/}
            {/*    title={`ID ${id} | ${*/}
            {/*      data.name*/}
            {/*    } | ${data.network_id.toUpperCase()}`}*/}
            {/*    {...data}*/}
            {/*    onClose={closeModal}*/}
            {/*  />*/}
            {/*)}*/}
            {/*{showCancelModal && <CancelModal {...data} onClose={closeModal} />}*/}
            {/*{showAddModal && <AddModal {...data} onClose={closeModal} />}*/}
            <div className="advertiser-id">
              <LazyLoad>
                <img alt="" src={media} />
              </LazyLoad>
              <div className="detail">
                <span>ID {data.id}</span>
                <Button
                  className="basic-button"
                  to={`${match.path}/${id}/networks/${data.network_id}${search}`}>
                  <MoreLess
                    className="sz-16"
                    content={data.name}
                    maxLimit="24"
                    onClick={props.onClick}
                  />
                </Button>
                <a
                  href={data.website}
                  target="_blank"
                  rel="noreferrer noopener">
                  <MoreLess content={data.website} maxLimit="24" />
                </a>
              </div>
            </div>
          </td>
          <td>{data.countries.map(i => i.name).join(', ') || '-'}</td>
          <td>{data.currency || '-'}</td>
          <td>{commissionParser(data || {})[0]}</td>
          <td>{data.network_id.toUpperCase()}</td>
          <td>
            {' '}
            {data.network_status === 'IGNORED' ? (
              <span className="sz-12 c-alert">{data.network_status}</span>
            ) : (
              <span className="sz-12"> {data.network_status}</span>
            )}
          </td>
          <td>{data.internal_status}</td>
          <td>
            <div className="action horizon middle right">
              {data.network_status === 'JOINED' &&
                (data.internal_status === 'CONNECTED' ? (
                  <Button
                    label="Disconnect"
                    onClick={handleDisconnect}
                    className="secondary-button mr-10"
                    confirm={{description: 'Are you sure?'}}
                    loading={loading}
                  />
                ) : (
                  <Button
                    label="Add"
                    to={`${match.path}/${id}/add${search}`}
                    // onClick={handleAddModal}
                    className="add-button mr-10"
                    disabled={data.internal_status !== 'FREE'}
                  />
                ))}

              {data.internal_status === 'IGNORED' ? (
                <Button
                  label="Ignored"
                  to={`${match.path}/${id}/cancel${search}`}
                  // onClick={handleCancelModal}
                  disabled
                  className="alert-button light decline"
                />
              ) : (
                <Button
                  label="Ignore"
                  // onClick={handleCancelModal}
                  to={`${match.path}/${id}/cancel${search}`}
                  className="alert-button light decline  "
                />
              )}
            </div>
          </td>
        </tr>
      </React.Fragment>
    )
  })
)
