import {inject, observer} from 'mobx-react'
import React from 'react'
import {Link, useParams} from 'react-router-dom'
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs'
import useDeepCompareEffect from 'use-deep-compare-effect'
import {Loader} from '@evet/library'
import {Modal} from 'components'

// const offersMap = {
//   TEXT: 'Text Links',
//   AD: 'Advanced Links',
//   BANNER: 'Banner Links',
//   COUPON: 'Coupon Links',
//   PRODUCT: 'Product Links',
// }

export const NetworkDetail = inject('LibraryStore')(
  observer(props => {
    const {advertiserID: id} = useParams()

    const {state, item, read} = props.LibraryStore
    useDeepCompareEffect(() => {
      read({id}).then()
    }, [read, id])

    return (
      <Modal title={item.website_base || 'Loading...'}>
        <div className="network-modal">
          {state['getting'] ? (
            <Loader />
          ) : (
            <Tabs className="tab-menu">
              <TabList className="title">
                <Tab>Info</Tab>
                {/*<Tab className="light-bl-1">Offers</Tab>*/}
                <Tab className="light-bl-1">Commission</Tab>
              </TabList>
              <TabPanel>
                <div className="vertical between top">
                  <div className="per-100 p-10 border-box">
                    <div className="info-table mt-10 ">
                      <div className="column">
                        <span>URL</span>
                        <p>
                          :{' '}
                          <a
                            href={item.website}
                            target="_blank"
                            rel="noreferrer">
                            {item.website}
                          </a>
                        </p>
                      </div>
                      <div className="column">
                        <span>REGION</span>
                        <p>
                          :{' '}
                          {(item.countries || [])
                            .map(i => i.country_code)
                            .join(', ')}
                        </p>
                      </div>
                      <div className="column">
                        <span>MOBILE TRACKING</span>
                        <p>
                          : {item.mobil_tracking_enabled ? 'TRUE' : 'FALSE'}
                        </p>
                      </div>
                      <div className="column">
                        <span>CATEGORIES</span>
                        <p>
                          :{' '}
                          {(item.categories || []).map(i => i.name).join(', ')}
                        </p>
                      </div>
                      <div className="column">
                        <span>OFFERS</span>
                        <p>
                          :{' '}
                          <Link to={`/offers?network_advertiser_id=${id}`}>
                            Offers ({item.link_count})
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="per-100 p-10 border-box">
                    <h3>DESCRIPTION</h3>
                    <div id="scrollBar" className="scroll-area mt-10">
                      <p>
                        {item.description
                          ? item.description
                          : 'For advertisers without description text, a dummy description text needs to be written.'}
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>

              {/*<TabPanel>*/}
              {/*  {item.link_counts && item.link_counts.distribution && (*/}
              {/*    <div*/}
              {/*      id="scrollBar"*/}
              {/*      className="per-100 p-10 border-box scroll-area offers">*/}
              {/*      <h3>OFFERS</h3>*/}
              {/*      <div className="tag-area mt-10">*/}
              {/*        /!*currency: "USD"*/}
              {/*        flat: null*/}
              {/*        max_requirement: null*/}
              {/*        min_requirement: null*/}
              {/*        name: "Sale"*/}
              {/*        percentage: "6.00"*/}
              {/*        subname: ""*/}
              {/*        *!/*/}
              {/*        <Link*/}
              {/*          to={`/offers?network_advertiser_id=${id}`}*/}
              {/*          className="item"*/}
              {/*          onClick={() => props.UIStore.modalClose()}>*/}
              {/*          <p>{offersMap[i.link_type] || '-'}</p>*/}
              {/*          <span>{i.count}</span>*/}
              {/*        </Link>*/}
              {/*        /!*{item.link_counts.distribution.map(i => (*!/*/}
              {/*        /!*  <Link*!/*/}
              {/*        /!*    key={i.link_type}*!/*/}
              {/*        /!*    to={`/offers?network_advertiser_id=${id}&link_type=${i.link_type}`}*!/*/}
              {/*        /!*    className="item"*!/*/}
              {/*        /!*    onClick={() => props.UIStore.modalClose()}>*!/*/}
              {/*        /!*    <p>{offersMap[i.link_type] || '-'}</p>*!/*/}
              {/*        /!*    <span>{i.count}</span>*!/*/}
              {/*        /!*  </Link>*!/*/}
              {/*        /!*))}*!/*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  )}*/}
              {/*</TabPanel>*/}

              <TabPanel>
                <div id="scrollBar" className="p-10 scroll-area commission">
                  <table className="list-table per-100">
                    <thead>
                      <tr>
                        <td>Name</td>
                        <td>Subname</td>
                        <td>Commission</td>
                        <td>Requirement</td>
                      </tr>
                    </thead>
                    <tbody>
                      {(item.commission_configs || []).map((i, index) => (
                        <tr key={index + i.name}>
                          <td>{i.name}</td>
                          <td>{i.subname || '-'}</td>
                          <td>
                            {i.flat
                              ? `${i.flat}${i.currency}`
                              : `${i.percentage}%`}
                          </td>
                          <td>
                            {i.max_requirement
                              ? `${i.min_requirement}-${i.max_requirement}`
                              : '-'}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </TabPanel>
            </Tabs>
          )}
        </div>
      </Modal>
    )
  })
)
