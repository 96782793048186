import {Form, Input as CustomInput, Modal, qs, Submit} from '@evet/library'
import {Button} from 'components'
import React, {useState} from 'react'
import {AiOutlineSave} from 'react-icons/all'
import {useLocation} from 'react-router-dom'
import * as Yup from 'yup'

export const SaveFilter = ({
  activeFilter = {},
  filters = [],
  onSave,
  ...props
}) => {
  const [showSaveFilter, setShowFilter] = useState(false)
  const [isOverwrite, setIsOverwrite] = useState(activeFilter.name)
  const location = useLocation()
  const params = qs.parse(location.search)
  const {report_type} = params

  const toggleFilterModal = () => setShowFilter(!showSaveFilter)

  const schema = Yup.object().shape({
    name: Yup.string().required('required'),
  })

  const handleName = e =>
    setIsOverwrite(!!filters.find(i => i.name === e.target.value))

  const handleSaveReport = async data => {
    if (await onSave(data)) toggleFilterModal()
  }

  return (
    report_type && (
      <React.Fragment>
        {showSaveFilter && (
          <Modal title="Save Report" onClose={toggleFilterModal}>
            <Form
              className="p-10 vertical center"
              schema={schema}
              onSubmit={handleSaveReport}
              defaultValues={{name: activeFilter.name}}>
              <CustomInput
                label="Schema Name"
                name="name"
                className="per-100 mb-10"
                onChange={handleName}
              />
              <Submit
                className="per-20"
                confirm={isOverwrite && 'Is overwrite?'}>
                Save
              </Submit>
            </Form>
          </Modal>
        )}

        <Button
          id="save_filter_button"
          className={'circle ' + props.className}
          onClick={toggleFilterModal}
          label="Save The Report"
          icon={<AiOutlineSave />}
        />
      </React.Fragment>
    )
  )
}
