import {TextField, withStyles} from '@material-ui/core'
import {Autocomplete} from '@material-ui/lab'
import React from 'react'
import {FiCheck} from 'react-icons/all'
import {MoreLess} from '@evet/library'

// https://material-ui.com/api/autocomplete/#css
const StyledAutoComplete = withStyles({
  root: {
    border: '1px solid #ccc',
    padding: '2px 10px',
    backgroundColor: '#fff',
    borderRadius: 6,
  },

  input: {
    position: 'relative',
    backgroundColor: 'transparent',
    fontSize: 14,
    width: '100%',
    padding: 0,
    '&:focus': {
      border: 'none',
    },
    '&:disabled': {
      color: '#636363',
    },
  },
  paper: {
    position: 'absolute',
    width: 'auto',
    left: '-10px',
  },
  option: {
    fontSize: '12px',
    width: '170px',
  },
  noOptions: {
    fontSize: '12px',
  },
})(Autocomplete)

export const SelectInput = React.forwardRef((props, ref) => {
  const {options, onSelectChange, onClose, label, value, ...rest} = props

  return (
    <StyledAutoComplete
      fullWidth
      disableCloseOnSelect
      multiple
      openOnFocus
      limitTags={0}
      renderTags={() => null}
      options={options}
      noOptionsText="No option, enter at least 3 letter"
      getOptionLabel={option => option}
      renderOption={(option, {selected}) => (
        <div className="horizon between per-100">
          <MoreLess content={option} maxLimit="20" />

          <div className={'check-mark ' + (selected && 'selected')}>
            <FiCheck />
          </div>
        </div>
      )}
      renderInput={params => (
        <TextField
          {...params}
          InputProps={{...params.InputProps, disableUnderline: true}}
          placeholder={
            value.length ? value.length + ' selected' : 'SELECT ' + label
          }
        />
      )}
      value={value}
      onChange={props.onSelectChange}
      onClose={props.onClose}
      ref={ref}
      {...rest}
    />
  )
})
