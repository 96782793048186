export class Meta {
  constructor(data) {
    if (data) Object.entries(data).forEach(([k, i]) => (this[k] = i))
  }
}

export class Offer {
  constructor(data) {
    if (data) {
      Object.entries(data).forEach(([k, i]) => (this[k] = i))
      this.id = String(data.id)
    }
  }

  // get id() {
  //   return this['link_id']
  // }
}
