import {CheckBox as CustomCheckBox} from '@evet/library'
import {inject, observer} from 'mobx-react'
import React from 'react'

export const MultiCheckboxFilter = inject('UIStore')(
  observer(props => {
    const {name, label, options = []} = props

    return (
      <div className="section">
        <span>{label}</span>
        {options.map(({label, value}) => (
          <CustomCheckBox
            key={`${name}-${value}`}
            name={name}
            value={value}
            label={label}
            limit={18}
          />
        ))}
      </div>
    )
  })
)
