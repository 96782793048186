import {DatePicker, Form} from '@evet/library'
import React from 'react'

export const DatePickerForm = ({
  defaultValue,
  onChange,
  submitOnChange = true,
  openingPosition = 'br',
  windowPosition = 'tr',
  ...props
}) => (
  <Form
    className="vertical left top"
    onSubmit={onChange}
    submitOnChange={submitOnChange}
    defaultValues={defaultValue}>
    <DatePicker
      {...props}
      windowPosition={windowPosition}
      openingPosition={openingPosition}
    />
  </Form>
)
