import {action, computed, makeObservable, observable, toJS} from 'mobx'
import {ModuleMeta as Meta, Module as Model} from '../models'
import {DashboardServices as Service} from '../services'
import {DateHelper} from 'helpers'

export default class DashboardStore {
  state = 'initial'
  meta = {}
  _list = new observable.map()
  _item = {}

  constructor(stores) {
    this.stores = stores

    const {today, yesterday} = DateHelper
    this.start_date = yesterday
    this.end_date = today

    makeObservable(this, {
      state: observable,
      meta: observable,
      _list: observable.deep,
      _item: observable,

      start_date: observable,
      end_date: observable,

      list: computed,
      item: computed,

      read: action,
      fetchSuccess: action.bound,
      handleError: action.bound,
    })
  }

  get list() {
    return [...this._list.values()]
  }

  get item() {
    return toJS(this._item)
  }

  create = null

  read = async ({id = null, params = {limit: 10}} = {}) => {
    if (id) {
      this.state = 'getting'
      return Service.detail({id}).then(res => {
        if (res) {
          this.state = 'done'
          return (this._item = new Model(res.item || {}))
        }
        return {}
      }, this.handleError)
    } else {
      this.state = 'listing'
      this.start_date = params.start_date
      this.end_date = params.end_date
      Service.read(params).then(this.fetchSuccess, this.handleError)
    }
  }

  fetchSuccess = res => {
    if (res) {
      this.meta = new Meta(res.meta)
      this._list = new observable.map()
      res.items.forEach(i => {
        const item = new Model(i || {})
        item.fill({...i})
        console.log({item})
        this._list.set(item.id, item)
      })
    }
    this.state = 'done'
  }

  handleError = error => {
    this.state = 'error'
    return this.stores.SystemMessageStore.handleError(error)
  }
}
