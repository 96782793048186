export class Meta {
  searchable = []
  selectable = []
  sortable = []

  constructor(data) {
    if (data) Object.entries(data).forEach(([k, i]) => (this[k] = i))
  }
}

export class Advertiser {
  constructor(data) {
    if (data) Object.entries(data).forEach(([k, i]) => (this[k] = i))
  }

  get similars() {
    return (this['similar_network_advertisers'] || []).map(
      i => new Advertiser(i)
    )
  }

  get connections() {
    return (this['advertiser_connections'] || []).map(i => new Advertiser(i))
  }

  get label() {
    return this['name']
  }

  get value() {
    return this['id']
  }

  setSimilar = val => {
    this['similar_network_advertisers'] = (
      this['similar_network_advertisers'] || []
    ).map(i => (i.id === val.id ? val : i))
  }

  setConnection = val => {
    this['advertiser_connections'] = (
      this['advertiser_connections'] || []
    ).map(i => (i.id === val.id ? val : i))
  }
}
