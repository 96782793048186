export class DateHelper extends Date {
  constructor(_string) {
    super(_string)
    this.timestamp = Date.parse(_string)
    this.offset = this.getTimezoneOffset()
    // do localize - shift the date to offset
    this.setTime(this.timestamp + this.offset * 60 * 1000)
    // console.log(
    //   'constructor',
    //   this.getTime(),
    //   this.offset,
    //   this
    // )
    this.today = new Date()
    this.yesterday = new Date()
    this.yesterday.setDate(this.today.getDate() - 1)
    this.yesterday.setHours(0, 0, 0, 0)
  }
  asUTCISO({end = false} = {}) {
    // UTC shifted
    const _date = new Date(this.getTime() - this.offset * 60 * 1000 * 2)
    if (end) {
      _date.setUTCHours(23)
      _date.setUTCMinutes(59)
      _date.setUTCSeconds(59)
    }
    // console.log('asUTCISO', _date.toISOString())
    return _date.toISOString()
  }
}
