import {inject, observer} from 'mobx-react'
import React, {useState} from 'react'
import {Item} from './Item'

import useDeepCompareEffect from 'use-deep-compare-effect'
import {Loader} from '@evet/library'
import {SearchInput} from '@evet/library'
import {useHistory} from 'react-router-dom'

export const Similars = inject('LibraryStore')(
  observer(props => {
    const {id} = props

    const [loading, setLoading] = useState(true)
    const history = useHistory()

    useDeepCompareEffect(() => {
      ;(async () => {
        setLoading(true)
        const item = await props.LibraryStore.getListItemByID(id)
        await props.LibraryStore.getSimilars({
          params: {website_base: item.website_base},
        })
        setLoading(false)
      })()
    }, [props.LibraryStore, id])
    const {similars} = props.LibraryStore
    const list = similars

    const searchWebsites = e => {
      const search = e.target.value
      if (search.length > 2)
        props.LibraryStore.getSimilars({
          params: {search_text: search, limit: 5},
        })
    }

    return (
      <div className="mb-20">
        {id && (
          <div className="per-100">
            {loading ? (
              <Loader padding />
            ) : (
              <React.Fragment>
                <div className="horizon between middle per-100">
                  <h2>SELECT SIMILAR ADVERTISER</h2>
                  <SearchInput
                    navigation={history.push}
                    className="per-25"
                    autoComplete="off"
                    onChange={searchWebsites}
                  />
                </div>
                <table className="list-table mt-10">
                  <thead>
                    <tr>
                      <td>ID</td>
                      <td>NAME</td>
                      <td>URL</td>
                      <td>NETWORKS</td>
                      <td />
                    </tr>
                  </thead>
                  <tbody>
                    {list
                      // .filter(i => i.status === 'PENDING')
                      .map(i => (
                        <Item key={i.id} id={i.id} data={i} parent={id} />
                      ))}
                  </tbody>
                </table>
              </React.Fragment>
            )}
          </div>
        )}
      </div>
    )
  })
)
