import React from 'react'
import {Searchable} from './Searchable'
import {Selectable} from './Selectable'
import {Sortable} from './Sortable'
import {Sortable2} from './Sortable2'

export const TableLabel = ({label, field, meta, store, ...props}) => {
  const isSearchable = (meta.searchable || []).includes(field)
  const isSelectable = (meta.selectable || []).includes(field)
  const isSortable = (meta.sortable || []).includes(field)
  const isSortable2 = !!props.isSortable

  return (
    <div>
      {isSearchable && <Searchable label={label} field={field} store={store} />}
      {isSelectable && <Selectable label={label} field={field} store={store} />}
      {isSortable && <Sortable label={label} field={field} store={store} />}
      {isSortable2 && (
        <Sortable2
          label={label}
          field={field}
          value={props.isSortable}
          store={store}
        />
      )}
      {!isSearchable && !isSelectable && !isSortable && !isSortable2 && label}
    </div>
  )
}
