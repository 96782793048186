import React, {useEffect, useState} from 'react'
import {MdKeyboardArrowDown, RiStickyNoteLine} from 'react-icons/all'
import {ToggleMenu} from '@evet/library'
import {
  Form,
  MoreLess,
  Select as CustomSelect,
  EditableInput,
} from '@evet/library'
import {Button} from 'components'
import {inject, observer} from 'mobx-react'
import {NetworkList} from './NetworkList'
import {CommissionLogs} from './CommissionLogs'
import {useHistory, useLocation} from 'react-router-dom'
import {qs} from 'helpers'
import noLogo from 'images/no-logo.png'

export const AdvertiserCard = inject('UIStore')(
  observer(props => {
    const history = useHistory()
    const location = useLocation()
    const params = qs.parse(location.search)

    const {type} = params
    const [isOpen, setIsOpen] = useState(false)
    useEffect(() => {
      setIsOpen(false)
    }, [type])
    const handleShow = () => {
      setIsOpen(!isOpen)
    }

    const networkList = () => {
      const title = 'Networks'
      const content = <NetworkList />
      const size = 'medium-size'
      props.UIStore.modalOpen({
        title,
        content,
        size,
        type: '',
        onClose: null,
      })
    }
    // const editCommission = () => {
    //   const title = 'Edit Commission'
    //   const content = (
    //     <div className="p-10">
    //       <Form className="horizon between middle">
    //         <CustomInput className="per-70" />
    //         <PrimaryButton className="per-28 horizon center" name="Change" />
    //       </Form>
    //     </div>
    //   )
    //   props.UIStore.modalOpen({
    //     title,
    //     content,
    //     type: '',
    //     onClose: null,
    //   })
    // }

    const commissionLogList = () => {
      const title = "Commission Log's"
      const content = <CommissionLogs />
      const size = 'medium-size'
      props.UIStore.modalOpen({
        title,
        content,
        size,
        type: '',
        onClose: null,
      })
    }

    return (
      <div className={isOpen ? 'commission-card opened' : 'commission-card'}>
        <div className="card-base">
          <div className="title">
            <img alt="" src={noLogo} />
            <div className="detail">
              <span>ID 5eb95142d0ba8bbe845a3092</span>
              <p>
                <MoreLess
                  content="Norton - North America, Latin America, Asia Pacific"
                  maxLimit="44"
                />
              </p>
              <a href="/" target="_blank" rel="noreferrer noopener">
                http://buy.norton.com
              </a>
            </div>
          </div>
          <div className="data">
            <div className="item">
              <span>SALES</span>
              <p>$13.000</p>
            </div>
            <div className="item">
              <span>(PPJ) COMMISSION</span>
              <p className="horizon left">
                %30
                <Button onClick={networkList} className="basic-button ml-6">
                  View List
                </Button>
              </p>
            </div>
            <div className="item">
              <span>ADVERTISERS COMMISSION</span>
              <EditableInput />
            </div>
            <div className="action horizon middle">
              {type === 'UPDATED_COMMISSION' ? (
                <Button onClick={handleShow} className="basic-button">
                  <MdKeyboardArrowDown
                    className={
                      isOpen ? 'dropdown-icon top' : 'dropdown-icon down'
                    }
                  />
                  Networks
                </Button>
              ) : (
                <Button onClick={handleShow} className="basic-button">
                  <MdKeyboardArrowDown
                    className={
                      isOpen ? 'dropdown-icon top' : 'dropdown-icon down'
                    }
                  />
                  Publishers
                </Button>
              )}
            </div>
          </div>
        </div>
        {isOpen &&
          (type === 'UPDATED_COMMISSION' ? (
            <div className="dropdown">
              <table className="list-table bordered mt-20 mb-10 ">
                <thead>
                  <tr>
                    <td>NETWORK NAME</td>
                    <td>PREVIOUS COMMISSION</td>
                    <td>CURRENT COMMISSION</td>
                    <td>UPDATE DATE</td>
                    <td>STATUS</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Cj</td>
                    <td>%10</td>
                    <td>%20</td>
                    <td>20 Jun 2020</td>
                    <td>Active</td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : (
            <div className="dropdown">
              <div className="horizon between per-100 mt-10">
                <div className="per-50 horizon left">
                  <Form className="per-40 ml-10">
                    <CustomSelect listHeight="140" placeholder="Type" />
                  </Form>
                </div>
                <div className="per-50 horizon right">
                  <ToggleMenu
                    navigate={history.push}
                    params={params}
                    size="small"
                    options={[
                      {label: 'All', value: false},
                      {label: 'Exclusive', value: 'EXCLUSIVE'},
                    ]}
                    defaultValue={false}
                  />
                </div>
              </div>
              <table className="list-table bordered mt-20 mb-10 ">
                <thead>
                  <tr>
                    <td>Publisher Name</td>
                    <td>Offer ID</td>
                    <td>Offer Network</td>
                    <td>Commission</td>
                    <td>Commission Log’s</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>XYZ Publisher</td>
                    <td>12332123</td>
                    <td>CJ</td>
                    <td>
                      <EditableInput />
                    </td>
                    <td>
                      <span className="exclusive-caret">exclusive</span>
                      <Button
                        onClick={commissionLogList}
                        className="basic-button">
                        <RiStickyNoteLine />
                        view
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ))}
      </div>
    )
  })
)
