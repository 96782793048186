import {inject, observer} from 'mobx-react'
import React from 'react'
import {Button} from 'components'
import {useHistory} from 'react-router-dom'

export const Item = inject(
  'LibraryStore',
  'UIStore'
)(
  observer(({parent, id, data, ...props}) => {
    const history = useHistory()

    const handleConnect = async () => {
      await props.LibraryStore.patch(parent, {advertiser_id: id})
      history.goBack()
      // onAction()
    }

    const handleDisconnect = async () => {
      await props.LibraryStore.patch(parent, {advertiser_id: null})
      history.goBack()
      // onAction()
    }

    return (
      <tr>
        <td>{data.id}</td>
        <td>{data.name}</td>
        <td>
          <a href={data.website} target="_blank" rel="noreferrer noopener">
            {data.website}
          </a>
        </td>
        <td />
        <td className="horizon right middle">
          {props.status === 'CONNECTED' ? (
            <Button
              onClick={handleDisconnect}
              className="alert-button light small">
              Disconnect
            </Button>
          ) : (
            <Button
              onClick={handleConnect}
              className="apply-button light small">
              Connect
            </Button>
          )}
        </td>
      </tr>
    )
  })
)
