import React from 'react'
import {Item} from './Item'

// const totalTable = [
//   {label: 'Pending'},
//   {label: 'Approved'},
//   {label: 'Bonus'},
//   {label: 'Total'},
//   {
//     label: 'Total Return',
//     subField: [{label: 'Pending Return'}, {label: 'Return'}],
//   },
//   {label: 'Declined'},
// ]
// meta: {limit: 10, offset: 0, count: 128502,…}
// count: 128502
// grand_totals: [{status: "PENDING",…}, {status: "APPROVED",…}, {status: "TOTAL",…}, {status: "DECLINED",…},…]
// 0: {status: "PENDING",…}
// commission: 15648511272.57
// count: 86302
// currency: "USD"
// distributions: [{count: 1265, sale: 144175.66, commission: 144175.66, currency: "EUR"},…]
// 0: {count: 1265, sale: 144175.66, commission: 144175.66, currency: "EUR"}
// commission: 144175.66
// count: 1265
// currency: "EUR"
// sale: 144175.66
// sale: 15648511272.57
// status: "PENDING"

export const GrandTotal = ({totals = [], ...props}) => {
  // const {today, yesterday} = props.AccountStore
  //
  // const {page} = useParams()

  return (
    <div className={'grand-total card ' + props.className}>
      <div className="card-title">
        <h3>Grand Total</h3>
      </div>
      <div className="card-body">
        <div className="table-head">
          <span />
          <span>No Sales</span>
          <span>Amount</span>
          <span>Commission</span>
        </div>
        <div className="table-body">
          {totals.map(i => (
            <Item key={i.status} data={i} />
          ))}
        </div>
      </div>
    </div>
  )
}
