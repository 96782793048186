import {inject, observer} from 'mobx-react'
import React from 'react'
import {HeadLine} from 'components'
import {Card} from 'pages/Publisher/UserManagement/Card'
import useDeepCompareEffect from 'use-deep-compare-effect'
import {useLocation} from 'react-router-dom'
import {qs} from '@evet/library'

export const UsersManagement = inject('UserStore')(
  observer(props => {
    // const history = useHistory()
    const location = useLocation()
    const params = {
      status: 'PENDING',
      ...qs.parse(location.search),
    }

    useDeepCompareEffect(() => {
      props.UserStore.read({params})
    }, [props.UserStore, params])
    const {state, list, meta} = props.UserStore

    console.log({state, meta, list})

    return (
      <section className="vertical center">
        <HeadLine title="Users" className="sz-30 fw-300">
          {/*<div className="options">*/}
          {/*  <div className="item">*/}
          {/*    <Button className="add-button ml-10" label="Add user" />*/}
          {/*  </div>*/}
          {/*</div>*/}
        </HeadLine>

        <div className="container mt-20 vertical top center">
          <div className="per-100 user-accordion-base">
            {list.map(i => (
              <Card key={i.id} id={i.id} data={i} />
            ))}
          </div>
        </div>
      </section>
    )
  })
)
