import {request} from 'helpers'

export class Services {
  static create = async () => null

  static read = async ({report_type = 'date', ...params}) =>
    request.get(`/reporting/performance/`, {report_type, ...params})

  static update = async () => null

  static delete = async () => null

  static config = async params =>
    request.get(`/reporting/performance/configs/`, params)

  static searchable = async ({field, query}) =>
    request.get(`/reporting/performance/searchable/`, {field, query})
}
