import {request} from 'helpers'

export class Services {
  static readAdvertisers = async ({elder, grand, parent}) => {
    return await request.get(
      `/publishers/clients/${elder}/publishers/${grand}/properties/${parent}/advertisers/`,
      {}
    )
  }

  static patch = async ({elder, grand, parent, connectionID, data}) =>
    await request.patch(
      `/publishers/clients/${elder}/publishers/${grand}/properties/${parent}/advertisers/${connectionID}/`,
      data
    )

  static deleteAdvertiser = async ({elder, grand, parent, connectionID}) =>
    await request.delete(
      `/publishers/clients/${elder}/publishers/${grand}/properties/${parent}/advertisers/${connectionID}/`
    )

  static searchAdvertisers = async ({elder, grand, parent, search_text}) =>
    await request.get(
      `/publishers/clients/${elder}/publishers/${grand}/properties/${parent}/advertisers/query/`,
      {search_text}
    )
  static assignAdvertisers = async ({elder, grand, parent, data}) =>
    await request.post(
      `/publishers/clients/${elder}/publishers/${grand}/properties/${parent}/advertisers/`,
      data
    )
}
