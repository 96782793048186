import React from 'react'
import {AiOutlineSwapLeft} from 'react-icons/all'
import {MoreLess} from '@evet/library'

export const Transition = ({fields = {old: 'old', new: 'new'}, ...props}) => {
  return (
    <div className="table-type-transition ">
      <MoreLess maxLimit="10" content={props[fields.old]} />
      <div className="icon">
        <AiOutlineSwapLeft />
        <AiOutlineSwapLeft />
      </div>
      <MoreLess maxLimit="10" content={props[fields.new]} />
    </div>
  )
}
