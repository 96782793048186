import {inject, observer} from 'mobx-react'
import React from 'react'
import {Form, Select, Submit} from '@evet/library'
import {Modal} from 'components'
import {MdCheck} from 'react-icons/all'
import {useParams} from 'react-router-dom'

export const UserPermissions = inject('UserStore')(
  observer(props => {
    const {update, getItemById} = props.UserStore
    const {
      clientID: client_id,
      publisherID: publisher_id,
      propertyID: property_id,
      id: user_id,
    } = useParams()

    const data = getItemById(user_id)

    const handleSubmit = async data => {
      await update({client_id, user_id, publisher_id, property_id, data})
    }

    return (
      <Modal title="User Permission" onClose={props.onClose} className="per-50">
        <Form onSubmit={handleSubmit}>
          <div className="per-100 border-box ">
            <table className="list-table">
              <tbody>
                <tr>
                  <td>Account</td>
                  <td>
                    <div className="horizon between top per-100 p-20">
                      <span className="sz-14 vertical left">
                        <small className=" c-grey sz-12 mb-6">Name</small>
                        {data.fullName}
                      </span>
                      <span className="sz-14 vertical left ">
                        <small className="c-grey sz-12 mb-6">Mail</small>
                        burak@evet.com
                      </span>
                      <span className=" sz-14 vertical left ">
                        <small className=" c-grey sz-12 mb-6">Access to</small>
                        Pid or Pubid name
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Permission Level</td>
                  <td>
                    <div className="horizon between per-100 p-20">
                      {/*  <Select*/}
                      {/*  className="per-48"*/}
                      {/*  label="Inherited"*/}
                      {/*  options={[{label: 'User', value: 'user2'}]}*/}
                      {/*/>*/}
                      <Select
                        className="per-48"
                        label="Exclusive "
                        name="role"
                        options={[
                          // {label: 'Manager', value: 'manager'},
                          // {label: 'User', value: 'user'},
                          // {label: 'Owner', value: 'owner'},
                          {label: 'Superuser', value: 'SUPERUSER'},
                          {label: 'Viewer', value: 'VIEWER'},
                        ]}
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="permission-table mt-20">
              <thead>
                <tr>
                  <td />
                  <td>User</td>
                  <td>Manager</td>
                  <td>Owner</td>
                  <td>Superuser</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Can see reports</td>
                  <td>
                    <MdCheck />
                  </td>
                  <td>
                    <MdCheck />
                  </td>
                  <td>
                    <MdCheck />
                  </td>
                  <td>
                    <MdCheck />
                  </td>
                </tr>
                <tr>
                  <td>Apply to advertiser</td>
                  <td />
                  <td>
                    <MdCheck />
                  </td>
                  <td>
                    <MdCheck />
                  </td>
                  <td>
                    <MdCheck />
                  </td>
                </tr>
                <tr>
                  <td>Get link code</td>
                  <td />
                  <td>
                    <MdCheck />
                  </td>
                  <td>
                    <MdCheck />
                  </td>
                  <td>
                    <MdCheck />
                  </td>
                </tr>
                <tr>
                  <td>Create Publisher</td>
                  <td />
                  <td />
                  <td>
                    <MdCheck />
                  </td>
                  <td>
                    <MdCheck />
                  </td>
                </tr>
                <tr>
                  <td>Manage payment</td>
                  <td />
                  <td />
                  <td />
                  <td>
                    <MdCheck />
                  </td>
                </tr>
                <tr>
                  <td>Manage users</td>
                  <td />
                  <td />
                  <td />
                  <td>
                    <MdCheck />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="p-10 per-100 horizon right middle">
            <div className="vertical right per-100 mt-10">
              <Submit>Save</Submit>
            </div>
          </div>
        </Form>
      </Modal>
    )
  })
)
