import {Modal as LibModal} from '@evet/library'
import React from 'react'
import {useHistory} from 'react-router-dom'

export const Modal = ({onClose = () => null, ...props}) => {
  const history = useHistory()
  const handleClose = params => history.goBack() && onClose(params)

  return <LibModal onClose={handleClose} {...props} />
}
