import {inject, observer} from 'mobx-react'
import React, {useState} from 'react'
import {Button, DynamicFilter, HeadLine, Table} from 'components'
import {qs, DateHelper} from 'helpers'
import {AiOutlineDown, AiOutlineUp, BsCalendar} from 'react-icons/all'
import {Link, useLocation} from 'react-router-dom'
import useDeepCompareEffect from 'use-deep-compare-effect'
import {SelectFilter} from 'components/Filter/SelectFilter'
import {Charts} from 'components/Charts/Charts'
import {ReportSwitch} from 'components/Table/Types/ReportSwitch'

export const Performance = inject(
  'UIStore',
  'PerformanceStore',
  'FilterStore'
)(
  observer(props => {
    const [open, setOpen] = useState(true)
    const handleShow = () => setOpen(!open)
    const location = useLocation()
    const defaultParams = {
      report_type: 'performance_date',
      date_range: 'last_seven_days',
    }
    const params = {...defaultParams, ...qs.parse(location.search)}
    // IMPORTANT
    if (params['date_end'] && params['date_start']) {
      delete params['date_range']
      params['date_start'] = new DateHelper(params['date_start']).asUTCISO()
      params['date_end'] = new DateHelper(params['date_end']).asUTCISO()
    }

    const {report_type} = params
    useDeepCompareEffect(() => {
      props.PerformanceStore.configure({type: report_type}).then()
    }, [report_type, props.PerformanceStore])
    useDeepCompareEffect(() => {
      props.PerformanceStore.read({
        params,
      })
    }, [params, props.TransactionStore])
    const {config, state, meta, list} = props.PerformanceStore
    const types = config.types || []
    const formElements = config.formElements || []
    const columns = config.columns || []

    const extraColumns = []
    if (
      config.report_switch &&
      config.report_switch.compatible_reports &&
      config.report_switch.compatible_reports.length
    ) {
      extraColumns.push({
        key: 'report_switch',
        render: row => {
          return (
            <ReportSwitch
              types={config.report_switch.compatible_reports}
              field={config.report_switch.field}
              data={row}
            />
          )
        },
      })
    }

    return (
      <section className="vertical center">
        <HeadLine title="Reporting" className="sz-30 fw-300">
          <div className="options per-70 horizon right middle m-vertical" />
        </HeadLine>

        <div className="per-100 white-bg horizon center">
          <div className="container">
            <DynamicFilter
              hide={!open}
              elements={formElements}
              defaultParams={{...defaultParams}}>
              <div className="per-100 pb-10 horizon middle right light-bb-1">
                <Button
                  className="secondary-button per-10"
                  onClick={handleShow}>
                  {open ? <AiOutlineDown /> : <AiOutlineUp />}
                  {open ? 'Hide Filter' : 'Show Filter'}
                </Button>
              </div>
            </DynamicFilter>
          </div>
        </div>

        <div className="container mt-20 vertical top between">
          <div className="reporting-sub-menu mt-20">
            {types.map(i => (
              <Link
                key={'key-' + i.value}
                to={'?' + qs.stringify({...params, report_type: i.value})}
                className={
                  'reporting-menu-link ' + (report_type === i.value && 'active')
                }>
                <BsCalendar />
                <span>{i.label}</span>
              </Link>
            ))}
          </div>
          <div className="reporting-base">
            {!report_type ? (
              <div>Select a report type first</div>
            ) : (
              <div className="per-100">
                {/*<SummaryChart title='Summary' summary={summary} list={list} />*/}
                <Charts
                  title={'Summary'}
                  charts={meta.charts}
                  sets={meta.data}
                  height={300}
                />
                <Table
                  data={list}
                  columns={columns}
                  pins={['sale']}
                  loading={state === 'listing'}
                  SelectFilter={SelectFilter}
                  // SaveFilter={CustomSaveFilter}
                  meta={{
                    limit: meta.limit,
                    count: meta.count,
                    offset: meta.offset,
                  }}
                  extraColumns={extraColumns}
                />
              </div>
            )}
          </div>
        </div>
      </section>
    )
  })
)
