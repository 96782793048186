// export class Meta {
// 	constructor(data) {
// 		if (data) Object.entries(data).forEach(([k, i]) => (this[k] = i))
// 	}
// }

export class Network {
  constructor(data) {
    if (data) Object.entries(data).forEach(([k, i]) => (this[k] = i))
  }

  // get id() {
  //   return this['link_id']
  // }
}
