import {inject, observer} from 'mobx-react'
import React from 'react'
import {Route} from 'react-router'
import {Link, useHistory, useLocation, useRouteMatch} from 'react-router-dom'
import useDeepCompareEffect from 'use-deep-compare-effect'
import {Loader, qs, queryStringFormatter, Pagination} from '@evet/library'
import {Button, Filter, HeadLine, SearchInput} from 'components'
import {AsyncFilterSection, FilterSection} from 'filter'
import {AppliedFilters} from 'components/Filter/AppliedFilters'
import {Add} from './Modals/Add/Add'
import {Card} from './Card'

const QUERY_ARRAYS = ['category_id', 'advertiser_id']
const APPLIED_FILTER_EXCEPTIONS = ['offset']

export const Clients = inject(
  'ClientStore',
  'AdvertiserStore',
  'FilterStore'
)(
  observer(props => {
    const match = useRouteMatch()
    const {search} = useLocation()
    const history = useHistory()
    const params = qs.parse(search)
    // const {elder: id} = useParams()

    useDeepCompareEffect(() => {
      props.ClientStore.read({params})
    }, [props.ClientStore, params])
    const {list, state, meta} = props.ClientStore

    useDeepCompareEffect(() => {
      props.FilterStore.readCountries().then()
      props.FilterStore.readNetworks().then()
      props.FilterStore.readCategories().then()
      props.FilterStore.readTypes().then()
    }, [props.FilterStore])
    const {countries, networks, categories, types} = props.FilterStore

    return (
      <React.Fragment>
        <section className="vertical center">
          <HeadLine title="Clients" className="sz-30 fw-300">
            <div className="options horizon right middle">
              <div className="item">
                <p>Active Clients</p>
                <span>{meta.count || '-'}</span>
              </div>
            </div>
          </HeadLine>

          {/*<ContactModal/>*/}
          {/*{id && <Add initialStep={1} to={'/clients'} />}*/}

          <div className="container mt-20 horizon top between">
            <div className="list-page">
              <div className="  side-filter">
                <Filter
                  defaultValues={{
                    type: '',
                    network: '',
                    country: '',
                    advertiser_id: '',
                    ...queryStringFormatter({params, array: QUERY_ARRAYS}),
                  }}
                  params={params}
                  navigate={history.push}>
                  <FilterSection name="type" label="Type" options={types} />

                  <FilterSection
                    name="network"
                    label="Network"
                    options={networks}
                  />

                  <FilterSection
                    name="country"
                    label="Country"
                    options={countries}
                  />

                  <FilterSection
                    name="category_id"
                    label="Category"
                    options={categories}
                    multi
                  />

                  <AsyncFilterSection
                    multi
                    name="advertiser_id"
                    label="Advertiser"
                    store={props.AdvertiserStore}
                  />
                </Filter>
              </div>
              <div className="results">
                <div className="section-title horizon between middle">
                  <Button
                    className="add-button"
                    to={`${match.url}/new${search}`}>
                    Create New
                  </Button>
                  <div className="filter per-50 horizon right">
                    <SearchInput placeholder="Search Client" />
                  </div>
                </div>
                <div>
                  <AppliedFilters
                    exceptions={APPLIED_FILTER_EXCEPTIONS}
                    hocParams={queryStringFormatter({
                      params,
                      array: QUERY_ARRAYS,
                    })}
                  />
                </div>
                <div className="mt-10">
                  {state === 'listing' ? (
                    <Loader />
                  ) : (
                    <React.Fragment>
                      {list.map(i => (
                        <Card key={'client-' + i.id} id={i.id} data={i} />
                      ))}
                      <Pagination
                        className="mt-30 mb-40"
                        offset={params.offset}
                        count={meta.count}
                        limit={meta.limit}
                        params={params}
                        Link={Link}
                      />
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*  MODALS */}
        <Route path={`${match.path}/new`} component={Add} />
        {/* /MODALS */}
      </React.Fragment>
    )
  })
)
