import moment from 'moment/moment'
import React from 'react'

export const DateTime = ({
  date = null,
  timestamp = null,
  format = 'MMM D, YYYY hh:mm A',
}) => {
  const _date = timestamp ? moment.unix(timestamp * 0.001) : moment(date)
  return <span>{_date.isValid() ? _date.format(format) : 'N/A'}</span>
}
