import {HeadLine} from 'components'
import {inject, observer} from 'mobx-react'
import React, {useState} from 'react'
import {AiOutlineEye} from 'react-icons/all'
import {Link, useParams} from 'react-router-dom'
import useDeepCompareEffect from 'use-deep-compare-effect'
import {HighestRated} from './HighestRate'
import {
  CartesianGrid,
  Line,
  LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Sector,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import {Button} from 'components/Button'

// const serverdataresponse1 = {
//   // sale_count: 1031,
//   // total_earnings: 4666.84,
//   // total_sales: 106843.65,
//   // last_week_sales: [
//   //   {daily_sale: 484.92, action_date: '2020-11-03'},
//   //   {daily_sale: 541.44, action_date: '2020-11-04'},
//   //   {daily_sale: 302.68, action_date: '2020-11-05'},
//   //   {daily_sale: 0.0, action_date: '2020-11-06'},
//   // ],
//   // advertiser_count: 924,
//   // advertiser_shares: [
//   //   {link__network_advertiser_id: 19144, link_id: 267040, sale_sum: 13.49},
//   //   {link__network_advertiser_id: 18989, link_id: 284024, sale_sum: 42.88},
//   //   {link__network_advertiser_id: 19317, link_id: 256569, sale_sum: 49.48},
//   //   {link__network_advertiser_id: 19097, link_id: 284192, sale_sum: 53.98},
//   //   {link__network_advertiser_id: 19149, link_id: 263118, sale_sum: 61.2},
//   //   {link__network_advertiser_id: 19051, link_id: 257647, sale_sum: 72.0},
//   //   {link__network_advertiser_id: 19521, link_id: 262323, sale_sum: 80.0},
//   //   {link__network_advertiser_id: 19551, link_id: 264081, sale_sum: 83.76},
//   //   {link__network_advertiser_id: 19085, link_id: 284124, sale_sum: 89.98},
//   //   {link__network_advertiser_id: 19092, link_id: 261026, sale_sum: 100.8},
//   //   {link__network_advertiser_id: 19076, link_id: 271185, sale_sum: 105.6},
//   //   {link__network_advertiser_id: 19163, link_id: 256838, sale_sum: 119.0},
//   //   {link__network_advertiser_id: 19332, link_id: 267188, sale_sum: 119.6},
//   //   {link__network_advertiser_id: 19022, link_id: 262944, sale_sum: 132.6},
//   //   {link__network_advertiser_id: 33903, link_id: 256526, sale_sum: 134.64},
//   //   {link__network_advertiser_id: 19507, link_id: 256646, sale_sum: 146.96},
//   //   {link__network_advertiser_id: 19287, link_id: 261587, sale_sum: 151.66},
//   //   {link__network_advertiser_id: 19295, link_id: 261834, sale_sum: 156.8},
//   //   {link__network_advertiser_id: 19053, link_id: 261800, sale_sum: 168.22},
//   //   {link__network_advertiser_id: 19586, link_id: 264075, sale_sum: 175.6},
//   //   {link__network_advertiser_id: 19511, link_id: 256146, sale_sum: 179.7},
//   //   {link__network_advertiser_id: 19139, link_id: 256877, sale_sum: 200.6},
//   //   {link__network_advertiser_id: 19193, link_id: 271147, sale_sum: 209.74},
//   //   {link__network_advertiser_id: 19100, link_id: 289925, sale_sum: 218.04},
//   //   {link__network_advertiser_id: 19303, link_id: 289908, sale_sum: 219.88},
//   //   {link__network_advertiser_id: 19528, link_id: 256372, sale_sum: 241.4},
//   //   {link__network_advertiser_id: 19426, link_id: 262151, sale_sum: 241.72},
//   //   {link__network_advertiser_id: 19203, link_id: 284099, sale_sum: 249.92},
//   //   {link__network_advertiser_id: 18998, link_id: 271144, sale_sum: 260.08},
//   //   {link__network_advertiser_id: 19320, link_id: 271143, sale_sum: 278.0},
//   //   {link__network_advertiser_id: 19054, link_id: 269417, sale_sum: 289.3},
//   //   {link__network_advertiser_id: 19062, link_id: 259205, sale_sum: 323.95},
//   //   {link__network_advertiser_id: 19109, link_id: 284178, sale_sum: 341.5},
//   //   {link__network_advertiser_id: 19308, link_id: 258655, sale_sum: 341.92},
//   //   {link__network_advertiser_id: 19545, link_id: 257415, sale_sum: 359.96},
//   //   {link__network_advertiser_id: 18976, link_id: 264720, sale_sum: 363.56},
//   //   {link__network_advertiser_id: 19407, link_id: 271171, sale_sum: 386.38},
//   //   {link__network_advertiser_id: 19269, link_id: 266487, sale_sum: 397.22},
//   //   {link__network_advertiser_id: 19517, link_id: 263438, sale_sum: 413.1},
//   //   {link__network_advertiser_id: 19282, link_id: 284149, sale_sum: 428.4},
//   //   {link__network_advertiser_id: 19146, link_id: 284223, sale_sum: 450.0},
//   //   {link__network_advertiser_id: 19186, link_id: 258014, sale_sum: 458.34},
//   //   {link__network_advertiser_id: 19050, link_id: 284377, sale_sum: 474.4},
//   //   {link__network_advertiser_id: 19049, link_id: 263701, sale_sum: 491.2},
//   //   {link__network_advertiser_id: 19268, link_id: 271117, sale_sum: 496.0},
//   //   {link__network_advertiser_id: 19279, link_id: 256224, sale_sum: 496.76},
//   //   {link__network_advertiser_id: 19371, link_id: 263411, sale_sum: 524.8},
//   //   {link__network_advertiser_id: 19478, link_id: 284127, sale_sum: 532.8},
//   //   {link__network_advertiser_id: 19324, link_id: 289944, sale_sum: 542.4},
//   //   {link__network_advertiser_id: 19224, link_id: 256970, sale_sum: 545.9},
//   //   {link__network_advertiser_id: 19367, link_id: 267078, sale_sum: 549.64},
//   //   {link__network_advertiser_id: 19584, link_id: 258362, sale_sum: 563.4},
//   //   {link__network_advertiser_id: 19500, link_id: 263455, sale_sum: 593.6},
//   //   {link__network_advertiser_id: 19534, link_id: 256248, sale_sum: 629.48},
//   //   {link__network_advertiser_id: 19579, link_id: 256565, sale_sum: 650.88},
//   //   {link__network_advertiser_id: 19357, link_id: 260538, sale_sum: 682.62},
//   //   {link__network_advertiser_id: 19174, link_id: 256972, sale_sum: 706.59},
//   //   {link__network_advertiser_id: 19536, link_id: 262169, sale_sum: 708.2},
//   //   {link__network_advertiser_id: 19166, link_id: 262805, sale_sum: 745.6},
//   //   {link__network_advertiser_id: 19336, link_id: 268158, sale_sum: 753.68},
//   //   {link__network_advertiser_id: 19142, link_id: 271148, sale_sum: 808.2},
//   //   {link__network_advertiser_id: 19469, link_id: 261688, sale_sum: 883.7},
//   //   {link__network_advertiser_id: 19240, link_id: 289946, sale_sum: 928.0},
//   //   {link__network_advertiser_id: 19401, link_id: 262304, sale_sum: 989.83},
//   //   {link__network_advertiser_id: 19363, link_id: 257743, sale_sum: 1071.7},
//   //   {link__network_advertiser_id: 19562, link_id: 284165, sale_sum: 1184.0},
//   //   {link__network_advertiser_id: 19312, link_id: 262343, sale_sum: 1212.56},
//   //   {link__network_advertiser_id: 19476, link_id: 284038, sale_sum: 1354.86},
//   //   {link__network_advertiser_id: 19542, link_id: 256546, sale_sum: 1355.32},
//   //   {link__network_advertiser_id: 19064, link_id: 284305, sale_sum: 1408.75},
//   //   {link__network_advertiser_id: 19045, link_id: 262189, sale_sum: 1425.6},
//   //   {link__network_advertiser_id: 19183, link_id: 284199, sale_sum: 1500.3},
//   //   {link__network_advertiser_id: 19217, link_id: 284169, sale_sum: 1523.9},
//   //   {link__network_advertiser_id: 19532, link_id: 260415, sale_sum: 1580.0},
//   //   {link__network_advertiser_id: 19264, link_id: 256866, sale_sum: 1653.24},
//   //   {link__network_advertiser_id: 19516, link_id: 257616, sale_sum: 1858.0},
//   //   {link__network_advertiser_id: 19373, link_id: 256844, sale_sum: 2041.72},
//   //   {link__network_advertiser_id: 19145, link_id: 256261, sale_sum: 2256.2},
//   //   {link__network_advertiser_id: 19338, link_id: 260560, sale_sum: 2885.38},
//   //   {link__network_advertiser_id: 18997, link_id: 256308, sale_sum: 2965.98},
//   //   {link__network_advertiser_id: 19412, link_id: 257113, sale_sum: 3006.98},
//   //   {link__network_advertiser_id: 19344, link_id: 284083, sale_sum: 3071.32},
//   //   {link__network_advertiser_id: 19283, link_id: 289906, sale_sum: 4259.69},
//   //   {
//   //     link__network_advertiser_id: 19495,
//   //     link_id: 256355,
//   //     sale_sum: 8122.79999999999,
//   //   },
//   //   {link__network_advertiser_id: 19245, link_id: 284224, sale_sum: 8741.91},
//   //   {link__network_advertiser_id: 19466, link_id: 260297, sale_sum: 8898.9},
//   //   {link__network_advertiser_id: 19084, link_id: 256223, sale_sum: 20255.9},
//   // ],
//   top_publisher_properties: [1],
//   top_network_earnings: [
//     {commission_amount_usd: 140.0},
//     {commission_amount_usd: 140.0},
//     {commission_amount_usd: 71.68},
//     {commission_amount_usd: 71.68},
//     {commission_amount_usd: 63.28},
//     {commission_amount_usd: 63.28},
//     {commission_amount_usd: 62.38},
//     {commission_amount_usd: 62.38},
//     {commission_amount_usd: 55.77},
//     {commission_amount_usd: 55.77},
//   ],
//   property_count: 1,
//   evet_comms: {},
// }
//
// const pieChart = [
//   {name: 'Advertiser 1', value: 100},
//   {name: 'Advertiser 2', value: 40},
//   {name: 'Advertiser 3', value: 20},
//   {name: 'Advertiser 4', value: 30},
//   {name: 'Advertiser 5', value: 11},
//   {name: 'Advertiser 6', value: 70},
//   {name: 'Advertiser 7', value: 123},
//   {name: 'Advertiser 8', value: 43},
//   {name: 'Advertiser 9', value: 32},
//   {name: 'Advertiser 10', value: 12},
// ]

const data = [
  {
    name: '1',
    pv: 2400,
    amt: 2400,
  },
  {
    name: '2',
    pv: 1398,
    amt: 2210,
  },
  {
    name: '3',
    pv: 9800,
    amt: 2290,
  },
  {
    name: '4',
    pv: 3908,
    amt: 2000,
  },
  {
    name: '5',
    pv: 4800,
    amt: 2181,
  },
  {
    name: '6',
    pv: 3800,
    amt: 2500,
  },
  {
    name: '7',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
]

const renderActiveShape = props => {
  const RADIAN = Math.PI / 180
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    // percent,
    value,
  } = props
  const sin = Math.sin(-RADIAN * midAngle)
  const cos = Math.cos(-RADIAN * midAngle)
  const sx = cx + (outerRadius + 10) * cos
  const sy = cy + (outerRadius + 10) * sin
  const mx = cx + (outerRadius + 30) * cos
  const my = cy + (outerRadius + 30) * sin
  const ex = mx + (cos >= 0 ? 1 : -1) * 22
  const ey = my
  const textAnchor = cos >= 0 ? 'start' : 'end'

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.network_advertiser_name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#222">
        ${value.toFixed()}
      </text>
    </g>
  )
}

export const NetworkSingle = inject(
  'NetworkStore',
  'FilterStore'
)(
  observer(props => {
    // const {today, yesterday} = props.AccountStore
    // const history = useHistory()
    // const {page} = useParams()
    // const location = useLocation()
    const {id} = useParams()
    const [activeIndex, setActiveIndex] = useState(0)

    useDeepCompareEffect(() => {
      props.NetworkStore.read({id})
    }, [props.NetworkStore, id])

    const item = props.NetworkStore.getItemById(id)
    console.log('networks', item)
    const onPieEnter = (data, index) => {
      setActiveIndex(index)
    }

    return (
      <section className="vertical center">
        <HeadLine title={id} className="sz-30 fw-300">
          <div className="options per-70 horizon right middle">
            {/*<div className="filter per-100 horizon right">*/}
            {/*  <label className="date-picker">*/}
            {/*    <CustomDateRangePicker/>*/}
            {/*  </label>*/}
            {/*</div>*/}
            <div className="item">
              <p># of Advertisers</p>
              <span>
                <Link to="" className="horizon left middle">
                  {item.advertiser_count || 0} <AiOutlineEye className="ml-6" />
                </Link>
              </span>
            </div>
            <div className="item">
              <p>Total Sales</p>
              <span>{item.sale_count || 0}</span>
            </div>
            <div className="item">
              <p>Total Commission</p>
              <span>{item.total_earnings || 0}</span>
            </div>
          </div>
        </HeadLine>

        <div className="container mt-20 horizon top between">
          <div className="per-68 vertical top lef">
            <div className="per-100 card">
              <div className="card-title">Network Earning</div>
              <div className="card-body">
                <div className="chart-base horizon between middle per-100">
                  <div className="per-70 p-10">
                    <div style={{width: '100%', height: 220}}>
                      <ResponsiveContainer>
                        <LineChart
                          stroke="red"
                          data={item.last_week_sales}
                          margin={{
                            top: 10,
                            bottom: 10,
                          }}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis
                            tick={{fill: '#8f8f8f'}}
                            tickLine={false}
                            dy={10}
                            style={{
                              fontSize: '12px',
                            }}
                            axisLine={{stroke: '#fff'}}
                            dataKey="action_date"
                          />
                          <YAxis
                            tick={{fill: '#8f8f8f'}}
                            tickLine={false}
                            dx={-10}
                            axisLine={{stroke: '#fff'}}
                            style={{
                              fontSize: '12px',
                            }}
                            dataKey="daily_sale"
                          />
                          <Tooltip
                            formatter={value => [value, 'Daily Sales']}
                          />
                          <Line
                            type="monotone"
                            dataKey="daily_sale"
                            stroke="#7000ae"
                            activeDot={{r: 8}}
                          />
                        </LineChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                  <div className="chart-summary per-30 vertical middle center">
                    <span>Total Earning</span>
                    <div className="count up horizon middle center">
                      <p>{item.total_sales || 0}</p>
                    </div>
                    <Button className="secondary-button " to="">
                      View Full Report
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="per-100 card mt-20">
              <div className="card-title">Publisher</div>
              <div className="card-body ">
                <div className="chart-base horizon between stretch per-100">
                  <div className="per-25 chart-summary light-br-1  vertical middle center">
                    <span>Publisher Earning</span>
                    <div className="count up horizon middle center">
                      <p>{item.total_sales}</p>
                    </div>
                    <Button className="secondary-button" to="">
                      View Publishers
                    </Button>
                  </div>
                  <div className="per-25 chart-summary light-br-1  vertical middle center">
                    <span>Total Publisher Comm</span>
                    <div className="count up horizon middle center">
                      <p>{item.total_earnings}</p>
                    </div>
                    <Button className="secondary-button" to="">
                      View Full Report
                    </Button>
                  </div>
                  <div className="per-50">
                    <div style={{width: '100%', height: 260}}>
                      <ResponsiveContainer>
                        <PieChart>
                          <Pie
                            activeIndex={activeIndex}
                            activeShape={renderActiveShape}
                            data={item.advertiser_shares}
                            cx={180}
                            cy={130}
                            innerRadius={60}
                            outerRadius={70}
                            fill="green"
                            dataKey="sale_sum"
                            onMouseEnter={onPieEnter}
                          />
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="per-100 card mt-20">
              <div className="card-title">Evet Commission</div>
              <div className="card-body">
                <div className="chart-base horizon between middle per-100">
                  <div className="per-70 p-10">
                    <div style={{width: '100%', height: 220}}>
                      <ResponsiveContainer>
                        <LineChart
                          stroke="red"
                          data={data}
                          margin={{
                            top: 10,
                            bottom: 10,
                          }}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis
                            tick={{fill: '#8f8f8f'}}
                            tickLine={false}
                            dy={10}
                            style={{
                              fontSize: '12px',
                            }}
                            axisLine={{stroke: '#fff'}}
                            dataKey="name"
                          />
                          <YAxis
                            tick={{fill: '#8f8f8f'}}
                            tickLine={false}
                            dx={-10}
                            axisLine={{stroke: '#fff'}}
                            style={{
                              fontSize: '12px',
                            }}
                          />
                          <Tooltip />
                          <Line
                            type="monotone"
                            dataKey="pv"
                            stroke="#7000ae"
                            activeDot={{r: 8}}
                          />
                        </LineChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                  <div className="chart-summary per-30 vertical middle center">
                    <span>Total Commission</span>
                    <div className="count up horizon middle center">
                      <p>$12.000</p>
                    </div>
                    <Button className="secondary-button" to="">
                      View Full Report
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="per-30 card">
            <div className="card-title">Highest Rate</div>
            <div className="card-body">
              <HighestRated
                advertiserSales={item.top_publisher_properties}
                networkEarnings={item.top_network_earnings}
              />
            </div>
          </div>
        </div>
      </section>
    )
  })
)
