import {AsyncSelect} from '@evet/library'
import React, {useState} from 'react'
import {FiMinus, GoPlus} from 'react-icons/all'

export const AsyncFilterSection = props => {
  const {name, label, ...rest} = props
  const [isOpen, setIsOpen] = useState(false)

  const handleChange = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div className={isOpen ? 'filter-section isOpen' : 'filter-section'}>
      <button onClick={handleChange} className="section-title">
        <span>{label}</span>
        {isOpen ? <FiMinus /> : <GoPlus />}
      </button>
      {isOpen && (
        <div className="section-body">
          <AsyncSelect
            {...rest}
            queryLabels
            queryName={label}
            multi={props.multi}
            dropdownType="alwaysOpen"
            name={name}
          />
        </div>
      )}
    </div>
  )
}
