import {inject, observer} from 'mobx-react'
import React, {useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import useDeepCompareEffect from 'use-deep-compare-effect'
import {Property} from './Property'
import {Client} from './Client'
import {Publisher} from './Publisher'

export const Add = inject(
  'ClientStore',
  'FilterStore'
)(
  observer(props => {
    const {initialStep, to} = props
    const [step, setStep] = useState(1)

    const history = useHistory()
    const {elder, grand} = useParams()

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({})

    useDeepCompareEffect(() => {
      props.FilterStore.readCountries()
      props.FilterStore.readNetworks()
      props.FilterStore.readCategories()
      props.FilterStore.readTypes()
    }, [props.FilterStore])
    const {types} = props.FilterStore

    const prevStep = () => {
      setStep(step - 1)
    }

    const nextStep = async _data => {
      _data = {...data, ..._data}
      setData(_data)
      props.ClientStore.formData = _data
      setStep(step + 1)
    }

    const handleClose = () => {
      history.push(to)
    }

    const handleSubmit = async _data => {
      data.publisher = {...data.publisher, ..._data.publisher}
      setLoading(true)
      try {
        switch (initialStep) {
          case 1:
            await props.ClientStore.create(data)
            break
          case 2:
            await props.ClientStore.addPublisherWebsite({
              elder,
              data: data.publisher,
            })
            break
          case 3:
            await props.ClientStore.addProperty({
              elder,
              grand,
              data: data.publisher.properties,
            })
            break
          default:
            break
        }
        history.push(to)
      } catch {
        setLoading(false)
      }
    }

    return (
      <div className="per-100">
        {step === 1 && (
          <Client
            nextStep={nextStep}
            handleClose={handleClose}
            types={types}
            loading={loading}
            data={data}
          />
        )}
        {step === 2 && (
          <Publisher
            initialStep={initialStep}
            prevStep={prevStep}
            nextStep={nextStep}
            handleClose={handleClose}
            loading={loading}
            data={data}
          />
        )}
        {step === 3 && (
          <Property
            initialStep={initialStep}
            prevStep={prevStep}
            nextStep={handleSubmit}
            handleClose={handleClose}
            loading={loading}
            data={data}
            types={types}
          />
        )}
      </div>
    )
  })
)
