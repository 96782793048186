import {AuthStore} from './AuthStore'

import {UIStore} from './UIStore'
import {SystemMessageStore} from './SystemMessageStore'

import AdvertiserStore from './AdvertiserStore'
import LibraryStore from './LibraryStore'
import OfferStore from './OfferStore'
import ClientStore from './ClientStore'
import RedirectionStore from './RedirectionStore'
import FilterStore from './FilterStore'
// import ReportingStore from './ReportingPerformanceStore'
import PropertyAdvertisersStore from './PropertyAdvertisersStore'
import NetworkStore from './NetworkStore'

import ClientAdvertiserStore from './ClientAdvertiserStore'
import TransactionFilterStore from './TransactionFilterStore'
import TransactionStore from './TransactionStore'

import PerformanceStore from './PerformanceStore'

import DashboardStore from './DashboardStore'
import UserStore from './UserStore'

class RootStore {
  constructor() {
    this.UIStore = new UIStore(this)
    this.SystemMessageStore = new SystemMessageStore(this)
    this.AuthStore = new AuthStore(this)
    this.AdvertiserStore = new AdvertiserStore(this)
    this.LibraryStore = new LibraryStore(this)
    this.OfferStore = new OfferStore(this)
    this.RedirectionStore = new RedirectionStore(this)
    this.NetworkStore = new NetworkStore(this)

    this.ClientStore = new ClientStore(this)
    this.ClientAdvertiserStore = new ClientAdvertiserStore(this)
    this.PropertyAdvertisersStore = new PropertyAdvertisersStore(this)

    this.TransactionFilterStore = new TransactionFilterStore(this)
    this.TransactionStore = new TransactionStore(this)
    this.FilterStore = new FilterStore(this)

    this.PerformanceStore = new PerformanceStore(this)

    this.DashboardStore = new DashboardStore(this)
    this.UserStore = new UserStore(this)
  }
}

export const stores = new RootStore()
