import {Form, Input, qs} from '@evet/library'
import React from 'react'
import {useHistory, useLocation} from 'react-router-dom'

export const SearchInput = ({placeholder = 'Search...'}) => {
  const history = useHistory()
  const {search} = useLocation()
  const params = qs.parse(search)

  const handleSearch = ({search_text}) => {
    history.push(
      '?' +
        qs.stringify({
          ...params,
          page: null,
          skip: null,
          limit: null,
          search_text,
        })
    )
  }

  return (
    <Form className="per-50" onSubmit={handleSearch}>
      <Input
        icon="search"
        placeholder={placeholder}
        className="ml-10"
        name="search_text"
      />
    </Form>
  )
}
