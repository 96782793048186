import {Loader, qs} from '@evet/library'
import {Pagination} from 'components'
// import {Input as CustomInput, Form} from '@evet/library'
// import {ASide, Button} from 'materials'
import React from 'react'
import {useHistory, useLocation, Link} from 'react-router-dom'
// import useDeepCompareEffect from 'use-deep-compare-effect'
import {TableBody} from './TableBody'
import {TableHead} from './TableHead'
import {TableMeta} from './TableMeta'

export const Table = ({
  data,
  columns: allColumns,
  // defaultColumns = allColumns.map(i => i.field),
  //   ? Object.keys(data[0])
  //   : allColumns.map(i => i.value || i.field),
  SaveFilter = () => null,
  SelectFilter = () => null,

  pins = [],
  store = {},
  meta = store.meta || {},
  extraColumns = [],
  loading = false,
}) => {
  const history = useHistory()
  const {search} = useLocation()
  const params = qs.parse(search)
  // const resultColumns = Object.keys(data[0] || [])

  // TODO qs içnden field parametresi çıkartmak için EXCEPTION YAZALIM

  const selectedColumns =
    typeof params.field === 'string'
      ? [params.field]
      : params.field || Object.keys(data[0] || [])
  const columns = allColumns.filter(i => selectedColumns.includes(i.field))
  // const [selectedColumns, setSelectedColumns] = useState(resultColumns || [])

  // useEffect(() => {
  //   setSelectedColumns(resultColumns)
  // }, [resultColumns])

  const handleSelectedColumnChange = field => {
    history.push('?' + qs.stringify({...params, field}))

    // setSelectedColumns(columns)
  }

  // const [openSave, setOpenSave] = useState(false)
  // const renderColumns = useMemo(() => {
  //   console.log('renderColumns')
  //   return allColumns.filter(i => selectedColumns.includes(i.field))
  // }, [allColumns, selectedColumns])
  //
  // console.log({allColumns, defaultColumns, selectedColumns})

  //
  // const handleSaveColumns = () => {
  //   setOpenSave(!openSave)
  // }
  //   console.log(Object.keys(data[0]).map(i=>allColumns[i]))

  return (
    <div className="explorer-table per-100">
      {/*{openSave && (*/}
      {/*  <ASide*/}
      {/*    title="Save Table Columns"*/}
      {/*    onClose={handleSaveColumns}*/}
      {/*    children={*/}
      {/*      <Form className="p-10 per-100">*/}
      {/*        <CustomInput label="List Name" placeholder="Enter list name" />*/}
      {/*        <Button label="Save Table" className="primary per-100 mt-10" />*/}
      {/*      </Form>*/}
      {/*    }*/}
      {/*  />*/}
      {/*)}*/}

      <TableMeta
        {...{
          SaveFilter,
          SelectFilter,
          allColumns,
          selectedColumns: columns.map(i => i.field),
          setSelectedColumns: handleSelectedColumnChange,
        }}
      />
      <div className="table-base">
        {loading ? (
          <Loader padding />
        ) : (
          <table>
            <TableHead {...{store, meta, columns, extraColumns}} />
            <TableBody
              {...{
                rows: data,
                columns,
                extraColumns,
                pins,
              }}
            />
          </table>
        )}
      </div>
      <div className="table-footer">
        <Pagination
          className="pagination"
          {...meta}
          params={params}
          Link={Link}
        />
      </div>
    </div>
  )
}
