import {request} from 'helpers'

// `/countries/`
// 	`/networks/`
// 	`/categories/`

export class Services {
  static create = async data => request.post(`/reporting/schemas/`, data)

  static read = async params => request.get(`/reporting/schemas/`, params)

  static update = async (id, data) =>
    request.patch(`/reporting/schemas/${id}/`, data)

  static delete = async id => request.delete(`/reporting/schemas/${id}/`)

  static countries = async () => await request.get(`/countries/`, {})
  static networks = async () => await request.get(`/networks/`, {})
  static categories = async () => await request.get(`/categories/`, {})
  static types = async () => await request.get(`/publishers/types/`, {})
}
