import React, {useEffect, useState} from 'react'
import {Select as CustomSelect} from '@evet/library'
import {TransactionServices as Services} from 'services'

// TODO property_id=1&property_id_label=Property

export const Searchable = props => {
  // TODO BB DO IT!
  const [values, setValues] = useState([])

  useEffect(() => {
    //Services.searchable({field, query})
  })

  const handleSearch = async query => {
    if (query.length < 3) return false

    // kasiyo burasi cok kasiyo
    Services.searchable({field: props.name, query}).then(res =>
      setValues((res.items || []).map(i => ({...i, label: i.name})))
    )
  }

  return (
    <CustomSelect
      queryLabels
      disableSearch={false}
      {...props}
      options={values}
      asyncSearch={handleSearch}
    />
  )
}
