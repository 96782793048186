import React, {useState} from 'react'
import {
  AiFillCaretDown,
  AiFillCaretUp,
  BsArrowReturnRight,
  FiFilter,
} from 'react-icons/all'
import {Link, useLocation} from 'react-router-dom'
import {Currency} from 'components/Table/Types/Currency'
import {qs, QuickTip} from '@evet/library'

export const Item = ({data}) => {
  const location = useLocation()
  const params = qs.parse(location.search)
  const filter = '?' + qs.stringify({...params, ...data.filters})

  const [open, setOpen] = useState(false)
  const handleChange = () => setOpen(!open)
  return (
    <div className="item">
      <div className="fields">
        <div className="cell" onClick={handleChange}>
          {data.status}
          <div>
            {data.subtotals && (open ? <AiFillCaretUp /> : <AiFillCaretDown />)}
            <Link to={filter}>
              <FiFilter />
            </Link>
          </div>
        </div>
        <div className="cell">
          {data.count}
          {/*<span>({data.distributions.map(i=>i.count).join(' - ')})</span>*/}
        </div>
        <div className="cell">
          <QuickTip
            comment={
              <span>
                {data.distributions.map(i => (
                  <Currency
                    key={'key-sale-' + data.id + '-' + i.currency}
                    currency={i.currency}>
                    {i.sale}
                  </Currency>
                ))}
              </span>
            }>
            <Currency currency={data.currency}>{data.sale}</Currency>
          </QuickTip>
        </div>
        <div className="cell">
          <QuickTip
            comment={
              <span>
                {data.distributions.map(i => (
                  <Currency
                    key={'key-commission-' + data.id + '-' + i.currency}
                    currency={i.currency}>
                    {i.commission}
                  </Currency>
                ))}
              </span>
            }>
            <Currency currency={data.currency}>{data.commission}</Currency>
          </QuickTip>
        </div>
      </div>
      {data.subtotals &&
        open &&
        data.subtotals.map(i => (
          <div className="sub-fields">
            <div className="cell">
              <span>
                <BsArrowReturnRight /> {i.status}
              </span>
            </div>
            <div className="cell">
              {i.count}
              {/*<span>({data.distributions.map(i=>i.count).join(' - ')})</span>*/}
              {/*<Link to="">*/}
              {/*  <FiFilter />*/}
              {/*</Link>*/}
            </div>
            <div className="cell">
              <QuickTip
                comment={
                  <span>
                    {i.distributions.map(i => (
                      <Currency
                        key={'key-subsale-' + data.id + '-' + i.currency}
                        currency={i.currency}>
                        {i.sale}
                      </Currency>
                    ))}
                  </span>
                }>
                <Currency currency={i.currency}>{i.sale}</Currency>
              </QuickTip>
              {/*<Link to="">*/}
              {/*  <FiFilter />*/}
              {/*</Link>*/}
            </div>

            <div className="cell">
              <QuickTip
                comment={
                  <span>
                    {i.distributions.map(i => (
                      <Currency
                        key={'key-subcommisiion-' + data.id + '-' + i.currency}
                        currency={i.currency}>
                        {i.commission}
                      </Currency>
                    ))}
                  </span>
                }>
                <Currency currency={i.currency}>{i.commission}</Currency>
              </QuickTip>
              {/*<Link to="">*/}
              {/*  <FiFilter />*/}
              {/*</Link>*/}
            </div>
          </div>
        ))}
    </div>
  )
}
