import {qs} from 'helpers'
import React, {useEffect, useRef, useState} from 'react'
import {FiSearch} from 'react-icons/all'
import {useHistory, useLocation} from 'react-router-dom'
import {SelectInput} from './SelectInput'

export const Selectable = props => {
  const history = useHistory()
  const location = useLocation()
  const params = qs.parse(location.search)
  const {search: _search} = params
  const ref = useRef(null)

  const {label, field, store, ...rest} = props
  const search = JSON.parse(_search || '[]')
  const defaultActive = !(!search.find(i => i.field === field)
    ? search.push({field, q: [], include: true})
    : false)
  const value = search.find(i => i.field === field)

  const [loading, setLoading] = useState(false)
  const [activate, setActivate] = useState(false)
  const [options, setOptions] = useState([])
  const [selected, setSelected] = useState(value.q)

  const handleClick = () => {
    setActivate(!activate)
  }

  useEffect(() => {
    if (activate) {
      async function fetchData() {
        setLoading(true)
        //TODO fix focus when page reloaded first time
        if (ref.current) ref.current.click()
        setOptions(await store.selectable({field}))
        setLoading(false)
      }

      fetchData().then()
    }
  }, [field, store, activate])

  const handleSubmit = ({clear = false}) => {
    setActivate(false)
    value.q = selected
    if (clear || (!!value.q && !value.q.length)) {
      const index = search.findIndex(i => i.field === field)
      if (index !== -1) {
        search.splice(index, 1)
      }
    }
    const _params = {
      ...params,
      search: search && search.length ? JSON.stringify(search) : null,
    }

    history.push('?' + qs.stringify(_params))
  }

  const handleSelectChange = (_, data, reason) => {
    setSelected(data)
    value.q = data
    if (reason === 'clear') handleSubmit({clear: true})
  }

  return (
    <div className={props.className}>
      {!activate ? (
        <label onClick={handleClick}>
          {label}
          {defaultActive ? (
            <span className="btn basic ml-10">{value.q.length} filter</span>
          ) : (
            <FiSearch className="ml-10" />
          )}
        </label>
      ) : (
        <div className="horizon left middle">
          <SelectInput
            value={selected}
            options={options}
            //onInputChange={handleInputChange}
            onSelectChange={handleSelectChange}
            label={label}
            onClose={handleSubmit}
            loading={loading}
            ref={ref}
            {...rest}
          />
        </div>
      )}
    </div>
  )
}
