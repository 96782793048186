import {inject, observer} from 'mobx-react'
import React, {useState} from 'react'
import {AiFillCaretDown, AiFillCaretRight} from 'react-icons/all'
import {Button} from 'components'

export const Card = inject('ClientStore')(
  observer(({id: _id, data = {}, margin = 0}) => {
    const [showChildren, setShowChildren] = useState(false)

    const toggleShowChildren = () => setShowChildren(!showChildren)

    const id = Math.random()

    const {
      instance_type: type = 'USER',
      name,
      fullname,
      email,
      role,
      clients = [],
      publishers = [],
      properties = [],
    } = data

    const list = [...clients, ...publishers, ...properties]

    return (
      <div className="accordion-card">
        <div className="item">
          <div className="left">
            <div
              className={
                'card-info ' +
                (margin === 1
                  ? ' ml-40'
                  : margin === 2
                  ? ' ml-60'
                  : margin === 3
                  ? ' ml-80'
                  : '')
              }>
              <Button
                onClick={toggleShowChildren}
                className="arrow basic-button">
                {!!list.length &&
                  (showChildren ? <AiFillCaretDown /> : <AiFillCaretRight />)}
              </Button>
              <div className="vertical left">
                <small className={'type-caret mb-6 ' + type}>
                  {type || 'USER'}
                </small>
                <span className="sz-18">{fullname || name}</span>
                <small className="sz-12 mt-6">{email || id}</small>
              </div>
            </div>
            <div className="action">
              <Button className="secondary-button small per-100">Manage</Button>
              {/*<Button className="alert-button light small per-100 ml-6">*/}
              {/*  Delete*/}
              {/*</Button>*/}
            </div>
          </div>
          <div className="right">
            {!fullname && !list.length && (
              <div className="option v">
                <small>Permission Level</small>
                <span>{role || '-'}</span>
              </div>
            )}
            {fullname && (
              <React.Fragment>
                <div className="option ">
                  <small># of Clients</small>
                  <span>{clients.length}</span>
                </div>
                <div className="option ">
                  <small># of Publisher</small>
                  <span>{publishers.length}</span>
                </div>
                <div className="option v">
                  <small># of Property</small>
                  <span>{properties.length}</span>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
        {showChildren && (
          <div className="per-100 children">
            {list.map(i => (
              <Card key={i.id} id={i.id} data={i} margin={margin + 1} />
            ))}
          </div>
        )}
      </div>
    )
  })
)
