import {inject, observer} from 'mobx-react'
import React from 'react'
import {Form, Input} from '@evet/library'
import {Modal} from 'components'
import {Button} from 'components'
import {useHistory, useParams} from 'react-router-dom'

export const CancelModal = inject(
  'UIStore',
  'LibraryStore'
)(
  observer(props => {
    const {advertiserID: id} = useParams()
    const history = useHistory()
    const onSubmit = async data => {
      if (await props.LibraryStore.patch(id, {...data, status: 'IGNORED'})) {
        history.goBack()
      }
    }

    return (
      <Modal title="Add Cancellation Message">
        <Form
          className="p-10 vertical center cancel-modal border-box"
          onSubmit={onSubmit}>
          <Input name="reason" multiline={true} rows={3} className="per-100" />
          <Button className="per-100 mt-10 horizon center" type="submit">
            Confirm cancellation
          </Button>
        </Form>
      </Modal>
    )
  })
)
