import {inject, observer} from 'mobx-react'
import React, {useState} from 'react'
import {MdKeyboardArrowDown} from 'react-icons/all'
import {Button, DateTime} from 'components'
import {Item} from './Item'

export const Card = inject('ClientStore')(
  observer(props => {
    const {id, data} = props
    const [showItems, setShowItems] = useState(false)

    const handleApprove = async () => {
      if (
        await props.ClientStore.patchClient({
          id,
          data: {status: 'JOINED'},
        })
      ) {
        // props.ClientStore._list.delete(id)
      }
    }

    const handleIgnore = async () => {
      await props.ClientStore.patchClient({
        id,
        data: {status: 'DECLINED'},
      })
    }

    const toggleShowItems = () => setShowItems(!showItems)

    console.log(data.publishers)
    return (
      <div
        className={
          showItems ? 'client-library-table opened' : 'client-library-table'
        }>
        <div className="data">
          <div className="item">
            <div className="client-id horizon left middle ">
              <div className="logo">{data.name[0]}</div>
              <div className="detail vertical middle left">
                <p>{data.name}</p>
              </div>
            </div>
          </div>
          <div className="item">
            <span>CID</span>
            <p>{data.id}</p>
          </div>
          <div className="item">
            <span>Date</span>
            <p>
              <DateTime date={data.created_at} />
            </p>
          </div>
          <div className="item">
            <span>Region</span>
            <p>{data.country.name}</p>
          </div>
          <div className="item">
            <span>Status</span>
            <p className="horizon between middle">
              {data.status}
              {data.status === 'JOINED' && (
                <Button
                  className="alert-button small ml-6"
                  onClick={handleIgnore}
                  label="Ignore"
                />
              )}
              {(data.status === 'DECLINED' || data.status === 'PENDING') && (
                <Button
                  className="secondary-button small ml-6"
                  onClick={handleApprove}
                  label="Approve"
                />
              )}
            </p>
          </div>
          <div className="item">
            <div className="horizon center middle per-100">
              {data.publishers.length > 0 && (
                <Button onClick={toggleShowItems} className="basic-button">
                  <MdKeyboardArrowDown
                    className={
                      showItems ? 'dropdown-icon top' : 'dropdown-icon down'
                    }
                  />
                  {showItems ? 'Less' : 'More'}
                </Button>
              )}
            </div>
          </div>
        </div>
        {showItems && (
          <div className="dropdown vertical">
            {data.publishers
              .filter(
                i =>
                  i.status !== 'JOINED' ||
                  i.properties.find(i => i.status !== 'JOINED')
              )
              .map(i => (
                <Item key={'item-' + i.id} id={i.id} data={i} parent={id} />
              ))}
          </div>
        )}
      </div>
    )
  })
)
