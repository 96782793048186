import {action, computed, makeObservable, observable, toJS} from 'mobx'
import {Client as Model, ClientMeta as Meta} from '../models'
import {ClientServices as Service} from '../services'

export default class ClientStore {
  state = 'initial'
  meta = {}
  _list = new observable.map()
  _item = {}
  _properties = {}
  _types = []
  formData = {}
  _searchable = {}
  searchableSelected = []

  constructor(stores) {
    this.stores = stores
    makeObservable(this, {
      state: observable,
      meta: observable,
      _list: observable.deep,
      _item: observable,
      _properties: observable,
      _types: observable,
      formData: observable,
      _searchable: observable,
      searchableSelected: observable,

      list: computed,
      item: computed,
      properties: computed,
      types: computed,

      create: action,
      read: action,
      fetchSuccess: action.bound,
      handleError: action.bound,
      getItemById: action,
      readWebsite: action,
      addPublisherWebsite: action,
      addProperty: action,
      addContact: action,

      deleteProperty: action,

      patchClient: action,
      patchPublisher: action,
      patchProperty: action,

      selectable: action,
      searchable: action,
    })
  }

  get list() {
    return [...this._list.values()]
  }

  get item() {
    return toJS(this._item)
  }
  get properties() {
    return [...this._properties.values()]
  }
  get types() {
    return [...this._types]
  }

  create = async data => {
    if (!data) return false
    return await Service.create(data)
      .then(res => {
        this._item = null
        this.formData = {}
        const item = new Model(res.item || {})
        this._list.set(item.id, item)
        return res
      })
      .catch(error => this.stores.SystemMessageStore.handleError(error))
  }

  read = ({id = null, fields = null, params = {}}) => {
    if (fields) {
      this.state = 'properting'
      Service.detail({id, fields, params}).then(res => {
        if (res) {
          //todo
          const item = this._list.get(id)
          item['properties'] = res
        }
        this.state = 'done'
      }, this.handleError)
    } else if (id) {
      this.state = 'getting'
      return Service.detail({id}).then(res => {
        if (res) {
          this.state = 'done'
          return (this._item = new Model(res.item || {}))
        }
        return {}
      }, this.handleError)
    } else {
      this.state = 'listing'
      Service.read(params).then(this.fetchSuccess, this.handleError)
    }
  }
  fetchSuccess = res => {
    if (res) {
      this.meta = new Meta(res.meta)
      this._list = new observable.map()
      res.items.forEach(i => {
        const item = new Model(i || {})
        this._list.set(item.id, item)
      })
    }
    this.state = 'done'
  }
  handleError = error => {
    this.state = 'error'
    return this.stores.SystemMessageStore.handleError(error)
  }
  getItemById = id => {
    return this._list.get(Number(id)) || new Model({})
  }

  readWebsite = async ({client_id, id}) => {
    return await Service.websiteDetail({client_id, id}).then(res => res.item) //TODO
  }

  addPublisherWebsite = async ({elder, data}) => {
    return await Service.addPublisherWebsite({elder, data})
      .then(res => {
        this._item.publishers.push(res.item)
        this.formData = {}
      })
      .catch(error => this.stores.SystemMessageStore.handleError(error))
  }

  addProperty = async ({elder, grand, data}) => {
    return await Service.addProperty({elder, grand, data})
      .then(res => {
        this.formData = {}
        this._item.publishers.find(i => i.id === Number(grand)).properties =
          res.items
      })
      .catch(error => this.stores.SystemMessageStore.handleError(error))
  }

  addContact = async ({client_id, data}) => {
    return await Service.addContact({client_id, data}).catch(error =>
      this.stores.SystemMessageStore.handleError(error)
    )
  }

  deleteProperty = async ({elder, grand, id}) => {
    //TODO handle delete property
    return await Service.deleteProperty({elder, grand, id})
      .then(_ => {
        // const items = this._item.publishers.filter(i => i.id === grand)[0].properties
        // const i = items.findIndex(i => i.id === id)
        // items[i] = res.item
        return true
      })
      .catch(error => this.stores.SystemMessageStore.handleError(error))
  }

  //
  async selectable({field}) {
    return (
      toJS(this._selectable[field]) ||
      (this._selectable[field] = (await Service.selectable({field}))['data'][
        field
      ])
    )
  }

  async searchable({field, search_text, query}) {
    const text = search_text || query
    if (text && text.length > 2)
      return (
        toJS(this._searchable[field + '-' + text]) ||
        (this._searchable[field + '-' + text] = (
          await Service.searchable({
            field,
            search_text: text,
          })
        )['items'].map(i => ({value: i.id, name: i.name})))
      )

    return []
  }

  /**
   *
   * @param id : String
   * @param data: Object
   * @returns {Promise<void>}
   */
  patchClient = async ({id, data}) => {
    return await Service.patchClient({id, data})
      .then(res => {
        this._list.set(id, new Model(res.item))
        return true
      })
      .catch(error => this.stores.SystemMessageStore.handleError(error))
  }

  /**
   *
   * @param parent : String
   * @param id : String
   * @param inList Boolean
   * @param data: Object
   * @returns {Promise<void>}
   */
  patchPublisher = async ({parent, id, inList = true, data}) => {
    return await Service.patchClientPublisher({id, parent, data})
      .then(res => {
        const items = (inList ? this._list.get(parent) : this._item).publishers
        const i = items.findIndex(i => i.id === id)
        items[i] = res.item
        return true
      })
      .catch(error => this.stores.SystemMessageStore.handleError(error))
  }

  /**
   *
   * @param grand : String
   * @param parent : String
   * @param id : String
   * @param data: Object
   * @param inList: Boolean
   * @returns {Promise<void>}
   */
  patchProperty = async ({grand, parent, id, data, inList = true}) => {
    return await Service.patchClientPublisherProperty({id, grand, parent, data})
      .then(res => {
        const items = (inList
          ? this._list.get(grand)
          : this._item
        ).publishers.filter(i => i.id === parent)[0].properties
        const i = items.findIndex(i => i.id === id)
        items[i] = res.item
        return true
      })
      .catch(error => this.stores.SystemMessageStore.handleError(error))
  }
}
