import {TableLabel} from 'components'
import React from 'react'

export const TableHead = ({store, meta, columns, extraColumns}) => (
  <thead>
    <tr>
      {columns.map(i => (
        <td key={'table-head-' + i.field}>
          <TableLabel
            label={i.label || i.name}
            field={i.field}
            isSortable={i.sort_keyword} // for dynamic table
            meta={meta}
            store={store}
          />
        </td>
      ))}
      {extraColumns.map(i => (
        <td key={'reporting-date-tab-extra-' + i.label}>{i.label}</td>
      ))}
    </tr>
  </thead>
)
