import {inject, observer} from 'mobx-react'
import React, {useState} from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'
import * as Yup from 'yup'
import {Form, Input, Select, Submit} from '@evet/library'
import {Modal} from 'components'
import {ShareCodes} from '../ShareCodes'
import {useParams} from 'react-router-dom'

export const GetCode = inject(
  'ClientStore',
  'OfferStore'
)(
  observer(props => {
    const {id} = useParams()
    const item = props.OfferStore.getListItemByID(id)
    const {name, sharing} = item

    const [defaultValues, setDefaultValues] = useState({
      publisher: '',
      website: '',
      property: '',
    })
    const schema = Yup.object().shape({
      publisher: Yup.string().required('Required'),
      website: Yup.string().required('Required'),
      property: Yup.string().required('Required'),
      sid: Yup.string().required('Required'),
    })

    const [publisher, setPublisher] = useState(null)
    const [websites, setWebsites] = useState([])
    const [properties, setProperties] = useState([])

    useDeepCompareEffect(() => {
      props.ClientStore.read({})
    }, [props.ClientStore])

    const {list: _publishers} = props.ClientStore
    const publishers = _publishers.map(i => ({label: i.name, value: i.id}))

    const handlePublisher = id => {
      if (id) {
        props.ClientStore.read({
          id,
        }).then(res => {
          setWebsites(
            (res.publishers || []).map(i => ({
              ...i,
              label: i.name,
              value: i.id,
            }))
          )
        })
        setPublisher(id)
      } else {
        setPublisher(null)
        setWebsites([])
        setDefaultValues({
          ...defaultValues,
          publisher: '',
          reset: Math.random(),
        })
      }
    }

    const handleWebsite = id => {
      if (id) {
        props.ClientStore.readWebsite({
          client_id: publisher,
          id,
        }).then(res =>
          setProperties(
            ((res || {}).properties || []).map(i => ({
              ...i,
              label: i.name,
              value: i.id,
            }))
          )
        )
      } else {
        setProperties([])
        setDefaultValues({...defaultValues, publisher, reset: Math.random()})
      }
    }

    const handleUpdate = async data => {
      if (await props.OfferStore.read({id, params: data})) {
        Object.entries(defaultValues).forEach(
          ([key, val]) => (defaultValues[key] = val)
        )
      }
    }

    return (
      <Modal closeOnOutside={false} title={name || 'Loading...'}>
        <div className="horizon between top offer-modal p-10">
          <Form
            schema={schema}
            defaultValues={defaultValues}
            className="info horizon between stretch per-50"
            onSubmit={handleUpdate}>
            <table className="list-table">
              <tbody>
                <tr className="border-none">
                  <td>CLIENT</td>
                  <td>
                    <Select
                      className="per-100"
                      name="publisher"
                      options={publishers}
                      onChange={handlePublisher}
                    />
                  </td>
                </tr>
                <tr className="border-none">
                  <td>PUBLISHER</td>
                  <td>
                    <Select
                      className="per-100"
                      name="website"
                      options={websites}
                      onChange={handleWebsite}
                    />
                  </td>
                </tr>
                <tr className="border-none">
                  <td>PROPERTIES</td>
                  <td>
                    <Select
                      className="per-100"
                      name="property"
                      options={properties}
                    />
                  </td>
                </tr>
                <tr className="border-none">
                  <td>SID</td>
                  <td>
                    <div className="horizon between middle">
                      <label className="per-100 horizon between stretch">
                        <Input name="sid" className="per-69" />
                        <Submit className="per-30">Update</Submit>
                      </label>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </Form>
          <div className="per-48 vertical between">
            <ShareCodes sharing={sharing || {}} />
          </div>
        </div>
      </Modal>
    )
  })
)
