import {qs} from 'helpers'
import React from 'react'
import {AiFillCaretDown, AiFillCaretUp} from 'react-icons/all'
import {useHistory, useLocation} from 'react-router-dom'

const PARAM_KEY = 'order_by'

export const Sortable = props => {
  const history = useHistory()
  const location = useLocation()
  const params = qs.parse(location.search)
  const _sort = params[PARAM_KEY]
  const sort = JSON.parse(_sort || null)

  const {label, field} = props

  const filtered = (sort || []).filter(i => i.field === field) || {}
  const direction = filtered.length ? filtered[0].direction : null

  const handleSubmit = _direction => {
    // TODO multiple search olabilir mevcutu update edilecek
    // TODO sort tek bir field icin
    // TODO pagination ve tarih filtresi  gibi parametrede olan datalarda aynen korunmali
    // TODO direction [ASC,DESC,null] 3 durum arasinda gezinecek - null ise gonderme
    const _params = {
      ...params,
      [PARAM_KEY]: JSON.stringify([{field, direction: _direction}]),
      // sort: sort ? JSON.stringify(sort) : null
    }
    if (direction === _direction) delete _params[PARAM_KEY]

    history.push('?' + qs.stringify(_params))
  }
  // .sortableHeader .sortableHeader.ASC .sortableHeader.DESC
  return (
    <div className={'horizon left middle ' + props.className}>
      {label}
      <div className="sortableHolder ml-10">
        <AiFillCaretUp
          onClick={() => handleSubmit('ASC')}
          className={'sortableHolder ' + (direction !== 'ASC' && 'passive')}
        />
        <AiFillCaretDown
          onClick={() => handleSubmit('DESC')}
          className={'sortableHolder ' + (direction !== 'DESC' && 'passive')}
        />
      </div>
    </div>
  )
}
