import {action, computed, makeObservable, observable, toJS} from 'mobx'
import {Advertiser as Model, AdvertiserMeta as Meta} from '../models'
import {ClientAdvertiserServices as Service} from '../services'

export default class ClientAdvertiserStore {
  state = 'initial'
  meta = {}
  _searchable = {}

  constructor(Stores) {
    this.stores = Stores
    makeObservable(this, {
      state: observable,
      meta: observable,
      _list: observable,
      _item: observable,
      _searchable: observable,
      _searchableSelected: observable,
      list: computed,
      item: computed,
      create: action,
      read: action,
      update: action,
      delete: action,
      fetchSuccess: action.bound,
      handleError: action.bound,
    })
  }

  _list = new observable.map()

  get list() {
    return [...this._list.values()]
  }

  _item = new Model()

  get item() {
    return toJS(this._item)
  }

  _searchableSelected = new observable.map()

  get searchableSelected() {
    return toJS([...this._searchableSelected.values()])
  }

  set searchableSelected(data) {
    data.forEach(i => this._searchableSelected.set(i.value, i))
  }

  create = async () => {}

  read = async ({id = null, params = '', client_id = 0}) => {
    if (id) {
      this.state = 'getting'
      await Service.detail(id, params).then(res => {
        this._item = new Model(res.item || {})
        this.state = 'done'
      }, this.handleError)
    } else {
      this.state = 'listing'
      await Service.read(client_id, params)
        .then(res => ({_parent: client_id, ...res}))
        .then(this.fetchSuccess, this.handleError)
    }
  }

  update = async ({client_id, publisher_id, property_id, id, data}) => {
    if (!data) return false
    return await Service.update(client_id, publisher_id, property_id, id, data)
      .then(res => {
        return res.items.forEach(i => {
          const item = new Model(i || {})
          this._list.set(item.id, item)
        })
      })
      .catch(error => this.stores.SystemMessageStore.handleError(error))
  }

  delete = async () => {}

  fetchSuccess = res => {
    if (res.items) {
      this.meta = new Meta(res.meta)
      // this._list = new observable.map()
      res.items.forEach(i => {
        // const item = new Model(i || {})
        const item = new Model({_parent: res._parent, ...i} || {})
        this._list.set(item.id, item)
      })
    }
    this.state = 'done'
  }

  handleError = error => {
    this.state = 'error'
    return this.stores.SystemMessageStore.handleError(error)
  }

  searchable = async ({field, search_text}) => {
    if (search_text && search_text.length > 2)
      return (
        toJS(this._searchable[field + '-' + search_text]) ||
        (this._searchable[field + '-' + search_text] = (
          await Service.searchable({
            field,
            search_text,
          })
        )['items'].map(i => ({value: i.id, name: i.name})))
      )

    return []
  }
}
