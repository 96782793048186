import {action, computed, makeObservable, observable, toJS} from 'mobx'
import {Advertiser as Model, AdvertiserMeta as Meta} from '../models'
import {AdvertiserServices as Service} from '../services'

export default class AdvertisersStore {
  state = 'initial'
  meta = {}
  _searchable = {}
  _selectable = {}
  _searchableSelected = []
  _list = new observable.map()
  _item = {}

  constructor(Stores) {
    this.stores = Stores

    makeObservable(this, {
      _list: observable,
      _searchableSelected: observable,
      _item: observable,
      _searchable: observable,
      _selectable: observable,
      state: observable,
      meta: observable,

      list: computed,
      item: computed,
      // getListItemByID: computed,

      create: action,
      read: action,
      update: action,
      patch: action,
      selectable: action,
      searchable: action,
      setItem: action,

      fetchSuccess: action.bound,
      handleError: action.bound,
    })
  }

  get list() {
    return [...this._list.values()]
  }

  get item() {
    return toJS(this._item)
  }

  getListItemByID = id => this._list.get(id) || new Model({})

  create = async data => {
    if (!data) return false
    return await Service.create(data)
      .then(res => {
        this._item = null
        return res
      })
      .catch(error => this.stores.SystemMessageStore.handleError(error))
  }

  read = async ({id = null, params = {}}) => {
    console.log('advertise read', id)
    if (id) {
      this.state = 'getting'
      await Service.detail(id, params).then(res => {
        const item = new Model({...res.item, timestamp: new Date()})

        console.log({res, item})
        //this._details.set(item.id, item);
        this._item = item
        this._list.set(item.id, item)
        this.state = 'done'
      }, this.handleError)
    } else {
      this.state = 'listing'
      await Service.read(params).then(this.fetchSuccess, this.handleError)
    }
  }

  update = async (id, data) => {
    if (!data) return false
    return await Service.update(id, data)
      .then(res => {
        //this._item = null;
        return res
      })
      .catch(error => this.stores.SystemMessageStore.handleError(error))
  }

  patch = async (id, data) => {
    if (!data) return false
    return await Service.patch(id, data)
      .then(res => {
        //this._item = null;
        return res
      })
      .catch(error => this.stores.SystemMessageStore.handleError(error))
  }

  fetchSuccess = res => {
    if (res.items) {
      this.meta = new Meta(res.meta)
      this._list = new observable.map()
      res.items.forEach(i => {
        const item = new Model(i || {})
        this._list.set(item.id, item)
      })
    }
    this.state = 'done'
  }

  handleError = error => {
    this.state = 'error'
    return this.stores.SystemMessageStore.handleError(error)
  }

  async selectable({field}) {
    return (
      toJS(this._selectable[field]) ||
      (this._selectable[field] = (await Service.selectable({field}))['data'][
        field
      ])
    )
  }

  async searchable({field, search_text, query}) {
    const text = search_text || query
    if (text && text.length > 2)
      return (
        toJS(this._searchable[field + '-' + text]) ||
        (this._searchable[field + '-' + text] = (
          await Service.searchable({
            field,
            search_text: text,
          })
        )['items'].map(i => ({value: i.id, name: i.name})))
      )

    return []
  }

  setItem = (id, data = {}) => {
    this._list.set(id, new Model(data))
  }

  get searchableSelected() {
    return toJS([...this._searchableSelected.values()])
  }

  set searchableSelected(data) {
    data.forEach(i => this._searchableSelected.set(i.value, i))
  }
}
