import {inject, observer} from 'mobx-react'
import React from 'react'
import {Slider as CustomSlider} from '@evet/library'

export const CustomSliderFilter = inject('UIStore')(
  observer(props => {
    const {name, label, options = {}} = props
    return (
      <div className="section">
        <CustomSlider
          label={label}
          name={name}
          max={options.max}
          min={options.min}
          valueLabelFormat={text => <div style={{color: 'white'}}>{text}</div>}
        />
      </div>
    )
  })
)
