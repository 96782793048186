import {qs} from 'helpers'
import React from 'react'
import {AiFillCaretDown, AiFillCaretUp} from 'react-icons/all'
import {useHistory, useLocation} from 'react-router-dom'

const PARAM_KEY = 'order_by'

export const Sortable2 = ({label, field, value, ...props}) => {
  const history = useHistory()
  const location = useLocation()
  const params = qs.parse(location.search)
  const sorts = params[PARAM_KEY]
    ? typeof params[PARAM_KEY] === 'object'
      ? params[PARAM_KEY]
      : [params[PARAM_KEY]]
    : []

  const sort = sorts.find(i => i && i.match(value)) || false
  const rest = sorts.filter(i => i && !i.match(value))
  const direction = sort ? (sort[0] === '-' ? 'DESC' : 'ASC') : ''

  const handleSubmit = direction => {
    const sort = (direction === 'DESC' ? '-' : '') + value
    const _params = {
      ...params,
      [PARAM_KEY]: rest ? [sort, ...rest] : sort,
    }
    history.push('?' + qs.stringify(_params))
  }
  return (
    <div className={'horizon left middle ' + props.className}>
      {label}
      <div className="sortableHolder ml-10">
        <AiFillCaretUp
          onClick={() => handleSubmit('ASC')}
          className={'sortableHolder ' + (direction !== 'ASC' && 'passive')}
        />
        <AiFillCaretDown
          onClick={() => handleSubmit('DESC')}
          className={'sortableHolder ' + (direction !== 'DESC' && 'passive')}
        />
      </div>
    </div>
  )
}
