import {inject, observer} from 'mobx-react'
import React from 'react'
import {commissionParser} from 'helpers'
import noLogo from 'images/no-logo.png'
import {MoreLess} from '@evet/library'
import {LazyLoad} from 'components'
import {Button} from 'components'

export const Item = inject('PropertyAdvertisersStore')(
  observer(props => {
    // const {elder, grand, parent} = useParams()
    const {id, data, handleCreate} = props

    return (
      <React.Fragment>
        <tr>
          <td>
            <div className="advertiser-id">
              <LazyLoad>
                <img alt="" src={noLogo} />
              </LazyLoad>
              {/* <img alt="" src={media} /> */}
              <div className="detail">
                <span>ID {id}</span>
                <Button className="basic-button" type="button">
                  <MoreLess
                    className="sz-16"
                    content={data.name}
                    maxLimit="24"
                  />
                </Button>
                <a
                  href={data.website}
                  target="_blank"
                  rel="noreferrer noopener">
                  <MoreLess content={data.website} maxLimit="24" />
                </a>
              </div>
            </div>
          </td>
          <td>{(data.countries || []).map(i => i.name).join(', ') || '-'}</td>
          <td>{data.currency || '-'}</td>
          <td>{commissionParser(data || {})[0]}</td>
          <td>{(data.network_id || '').toUpperCase()}</td>
          <td>
            <Button onClick={handleCreate} className="add-button small">
              Create
            </Button>
          </td>
        </tr>
      </React.Fragment>
    )
  })
)
