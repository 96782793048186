import {inject, observer} from 'mobx-react'
import {Modal} from 'components'
import React from 'react'
import {Route, useRouteMatch} from 'react-router'
import {UserAdd} from 'pages/Publisher/Client/Modals/UserAdd'
import {UserPermissions} from 'pages/Publisher/Client/Modals/UserPermissions'
import useDeepCompareEffect from 'use-deep-compare-effect'
import {useParams} from 'react-router'
import {SearchInput, Loader} from '@evet/library'
import {Button} from 'components'
import {Row} from 'pages/Publisher/Client/Modals/UsersTableRow'

export const Users = inject('UserStore')(
  observer(props => {
    const match = useRouteMatch()
    const {list, state, read} = props.UserStore
    const {
      clientID: client_id,
      publisherID: publisher_id,
      propertyID: property_id,
    } = useParams()

    useDeepCompareEffect(() => {
      read({client_id, publisher_id, property_id})
    }, [read, client_id, publisher_id, property_id])

    return (
      <React.Fragment>
        <Modal
          title="Users"
          // onClose={props.onClose}
          // closeOnOutside={!showUserPermission}
          className="per-60">
          {/*{showUserPermission && (*/}
          {/*  <UserPermissions onClose={handlePermissionModal} />*/}
          {/*)}*/}

          <div className="horizon between middle per-100 p-10">
            <SearchInput placeholder="Search User" />
            <Button className="add-button" to={`${match.url}/new`}>
              Add user
            </Button>
          </div>
          {state !== 'done' ? (
            <Loader />
          ) : (
            <table className="list-table">
              <thead>
                <tr>
                  <td>Name</td>
                  <td>Email</td>
                  <td>Permissions</td>
                  <td />
                </tr>
              </thead>
              {/*{*/}
              {/*  "user_id": 0,*/}
              {/*  "inherited_role": "string",*/}
              {/*  "direct_role": "string",*/}
              {/*  "has_lower_level_role": true,*/}
              {/*  "first_name": "string",*/}
              {/*  "last_name": "string",*/}
              {/*  "email": "user@example.com"*/}
              {/*}*/}
              <tbody>
                {list.map(i => (
                  <Row key-={'user-key-' + i.id} id={i.id} data={i} />
                ))}
              </tbody>
            </table>
          )}
        </Modal>
        {/* MODALS */}
        <Route exact path={`${match.path}/new`} component={UserAdd} />
        <Route
          path={`${match.path}/:userID/permissions`}
          component={UserPermissions}
        />
        {/* /MODALS */}
      </React.Fragment>
    )
  })
)
