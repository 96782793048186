import React from 'react'
import {
  EvetButton,
  ProductSwitch,
  Version,
  Layout,
  Aside,
  Project,
  Page,
} from '@evet/library'
import {CustomHeader} from 'components'
import {version} from '../package.json'
import logo from 'images/favicon.png'
import './assets/App.scss'

export const LayoutDefault = props => {
  const {me = {products: []}} = props
  return (
    <React.Fragment>
      {/*<SystemMessages />*/}
      <Layout title={props.title} description={props.description}>
        <Aside>
          <EvetButton logo={logo} me={me} />
          <ProductSwitch
            products={me.products}
            product={me.product}
            env={process.env.NODE_ENV}
          />
        </Aside>
        <Project>
          <CustomHeader />
          <Page>{props.children}</Page>
        </Project>
        <Version version={version} />
      </Layout>
    </React.Fragment>
  )
}

export const LayoutNoHeader = props => (
  <React.Fragment>
    {/*<SystemMessages />*/}
    <Layout title={props.title} description={props.description}>
      <Project>
        <Page>{props.children}</Page>
      </Project>
    </Layout>
  </React.Fragment>
)

export const LayoutGuest = props => (
  <React.Fragment>
    {/*<SystemMessages />*/}
    <Layout title={props.title} description={props.description}>
      <Project>
        <CustomHeader />
        <Page>{props.children}</Page>
      </Project>
    </Layout>
  </React.Fragment>
)
