import {inject, observer} from 'mobx-react'
import React from 'react'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import useDeepCompareEffect from 'use-deep-compare-effect'
import {ScrollToTop} from 'components'
import {AuthLoader} from '@evet/library'
import {Routing} from 'routing'

export const App = inject('AuthStore')(
  observer(props => {
    useDeepCompareEffect(() => {
      if (!props.AuthStore.authenticated)
        window.location.href = process.env.REACT_APP_AUTH
      else if (!['/logout', '/pending'].includes(window.location.pathname))
        props.AuthStore.handleAuth().then()
    }, [props.AuthStore])

    const {authenticated, me} = props.AuthStore

    // console.log({authenticated, me, access: me.checkProjectAccess()})
    if (me.id && me.checkProjectAccess() !== 'ADMIN')
      window.location.href = process.env.REACT_APP_AUTH + '?access_denied'

    return (
      <React.Fragment>
        {authenticated && !me.id ? (
          <AuthLoader />
        ) : (
          <Router>
            <ScrollToTop />
            <Switch>
              <Route path="/" component={Routing} />
            </Switch>
          </Router>
        )}
      </React.Fragment>
    )
  })
)
