import React from 'react'

export const CommissionDetail = props => {
  return (
    <div id="scrollBar" className="p-10 scroll-area commission">
      <table className="list-table per-100">
        <thead>
          <tr>
            <td>Action</td>
            <td>Condition</td>
            <td>Rate</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>-</td>
            <td>-</td>
            <td>-</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
