import {request} from 'helpers'

export class Services {
  static create = async () => null

  static read = async (client_id, params) =>
    await request.get(`/publishers/clients/${client_id}/advertisers/`, params)

  static update = async (client_id, publisher_id, property_id, id, data) =>
    await request.patch(
      `/publishers/clients/${client_id}/publishers/${publisher_id}/properties/${property_id}/advertisers/${id}/`,
      data
    )

  static delete = async () => null

  static detail = async (id, params = null) => null

  static searchable = async search => null
}
