import {request} from 'helpers'

///publishers/clients/
export class Services {
  static create = async data =>
    await request.post('/publishers/clients/', data).then()

  static read = async ({report_type = 'transaction_list', ...params}) => {
    return request.get(`/reporting/transactions/`, {report_type, ...params})
  }

  static update = async () => null

  static detail = async ({id, fields = null, params = {}}) => {
    if (fields)
      return request.get(`/publishers/publishers/${id}/properties/`, {
        ...params,
        field: fields,
      })
    return request.get(`/publishers/clients/${id}/`, params)
  }

  static delete = async () => null

  static config = async params => {
    return request.get(`/reporting/transactions/configs/`, params)
  }

  static searchable = async ({field, query}) => {
    return request.get(`/reporting/transactions/searchable/`, {field, query})
  }
}
