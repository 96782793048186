import {action, makeObservable, observable} from 'mobx'
import {Account as Model} from 'models'
import {AccountServices as Service} from 'services'
import {request} from 'helpers'
import cookie from 'react-cookies'
import jwt_decode from 'jwt-decode'

export class AuthStore {
  authenticated = null
  uid = null
  me = null
  remember = false

  constructor(stores) {
    this.stores = stores
    const token = cookie.load('access')
    this.authenticated = !!cookie.load('refresh')
    this.me = token ? new Model(jwt_decode(token).user) : new Model({})
    makeObservable(this, {
      uid: observable,
      authenticated: observable,
      me: observable,
      handleAuth: action,
      signOut: action,
    })
  }

  handleAuth = async () => {
    if (this.authenticated && this.me && this.me.id) return
    console.log('handleAuth')
    this.signupCache = {}
    const token = await request.token
    this.authenticated = !!token
    this.me = token ? new Model(jwt_decode(token).user) : new Model({})
  }

  signOut = async (from = null) => {
    this.me = false
    this.authenticated = false
    Service.signOut(from)
    window.location.href = process.env.REACT_APP_AUTH
  }
}
