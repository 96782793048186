import {Select as CustomSelect} from '@evet/library'
import React, {useState, useRef} from 'react'
import {FiMinus, AiOutlinePlus} from 'react-icons/all'

export const FilterSection = props => {
  const ref = useRef(null)
  const {
    name,
    label,
    multi,
    options = multi ? undefined : [],
    children,
    ...rest
  } = props
  const [isOpen, setIsOpen] = useState(false)

  const handleChange = () => {
    ref?.current?.classList.toggle('isOpen')
    setIsOpen(!isOpen)
  }

  return (
    <div ref={ref} className={'filter-section'}>
      <button onClick={handleChange} className="section-title">
        <span>{label}</span>
        {isOpen ? <FiMinus /> : <AiOutlinePlus />}
      </button>
      <div className={isOpen ? 'section-body' : 'section-body hide'}>
        {children ? (
          children
        ) : (
          <CustomSelect
            {...rest}
            className="per-100"
            queryLabels
            queryName={label}
            multi={props.multi}
            dropdownType="alwaysOpen"
            name={name}
            options={multi ? options : [{label: 'ALL', value: ''}, ...options]}
          />
        )}
      </div>
    </div>
  )
}
