import React from 'react'
import {Enum} from './Enum'
import {DateTime} from './DateTime'
import {Currency} from './Currency'
import {Transition} from './Transition'
import {MoreLess} from '@evet/library'

export const types = {
  enum: (i, row, column) => <Enum options={column['options']}>{i}</Enum>,

  datetime: i => <DateTime>{i}</DateTime>,
  currency: i => <Currency {...i} />,
  transition: (i, row, column) => <Transition {...i} fields={column.fields} />,
  // commission: i => (
  //   <Button onClick={handleModal}>
  //     <Currency {...i} />
  //   </Button>
  // ),
  coupon_code: code => <div style={{color: 'red'}}>{code}</div>,
  external_id: id => <MoreLess max="10" content={id} />,
  sid: id => <MoreLess max="11" content={id} />,
  order_id: id => <MoreLess max="11" content={id} />,
}
