import React from 'react'
import {types} from './Types/Types'

export const TableBody = ({rows, columns, extraColumns, pins}) => {
  return (
    <tbody>
      {rows.map(row => (
        <tr key={row.key || row.id}>
          {columns.map(column => {
            return (
              <td
                key={(row.key || row.id) + '_' + column.field}
                className={pins.includes(column.field) ? 'pin' : ''}>
                {(
                  types[column.field] ||
                  types[column.type] ||
                  (n => String(n == null ? '' : n))
                )(row[column.field], row, column)}
              </td>
            )
          })}
          {extraColumns.map(option => (
            <td key={(row.key || row.id) + '_' + option.key}>
              {option.render(row)}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  )
}
