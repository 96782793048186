import {inject, observer} from 'mobx-react'
import React from 'react'
import {MdClose} from 'react-icons/md'

export const SystemMessages = inject('SystemMessageStore')(
  observer(props => {
    const messages = props.SystemMessageStore.news

    return (
      <div id="systemMessages" className="per-50">
        {messages.map((message, key) => {
          const className =
            message.status >= 400
              ? 'error'
              : message.status >= 300
              ? 'warn'
              : message.status >= 200
              ? 'success'
              : 'success'
          return (
            <React.Fragment>
              <div
                className={
                  'alert-box  per-100 shadow-box ' +
                  className +
                  '  horizon between'
                }>
                <p>{message.message}</p>
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                  onClick={() => {
                    message.hide()
                  }}>
                  <MdClose />
                </button>
              </div>
              {message.hideTimeout()}
            </React.Fragment>
          )
        })}
      </div>
    )
  })
)
