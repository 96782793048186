import React from 'react'
import {useRouteMatch} from 'react-router'
import {MoreOptions} from '@evet/library'
import {Modal, Button} from 'components'

export const Contacts = () => {
  const match = useRouteMatch()

  return (
    <Modal title="Contacts">
      <div className="vertical center top per-100">
        <div id="scrollBar" className="advertisers-list-modal per-100">
          <table className="list-table">
            <thead>
              <tr>
                <td>Name</td>
                <td>Phone</td>
                <td>Email</td>
                <td>Region</td>
                <td>Country</td>
                <td>City</td>
                <td>Address</td>
                <td />
              </tr>
            </thead>
            <tbody>
              {[...Array(20)].map(_ => (
                <tr>
                  <td>Jhon</td>
                  <td>Scot</td>
                  <td>jhon@jhon.com</td>
                  <td>Canada</td>
                  <td>Canada</td>
                  <td>Toronto</td>
                  <td>bla bla bla bla bla bla</td>
                  <td>
                    <MoreOptions
                      links={
                        <ul>
                          <li>Delete</li>
                        </ul>
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="vertical p-10 per-100">
          <Button className="per-100" to={`${match.url}/new`}>
            Add New
          </Button>
        </div>
      </div>
    </Modal>
  )
}
