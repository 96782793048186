import {inject, observer} from 'mobx-react'
import React from 'react'
import {Route, useRouteMatch} from 'react-router'
import {useParams} from 'react-router-dom'
import useDeepCompareEffect from 'use-deep-compare-effect'
import {Form, Input as CustomInput} from '@evet/library'
import {Button, Modal} from 'components'
import {Row} from '../Row'
import {AdvertiserAdd} from './AdvertiserAdd'

export const Advertisers = inject('PropertyAdvertisersStore')(
  observer(props => {
    const match = useRouteMatch()

    const {to} = props
    // const [showAddAdvertiser, setShowAdd] = useState(false)
    const {
      clientID: elder,
      publisherID: grand,
      propertyID: parent,
    } = useParams()
    console.log('useParams()', useParams(), match)

    useDeepCompareEffect(() => {
      props.PropertyAdvertisersStore.readAdvertisers({
        elder,
        grand,
        parent,
      })
    }, [props.PropertyAdvertisersStore, elder, grand, parent])
    const {list} = props.PropertyAdvertisersStore

    // const handleAddModal = () => setShowAdd(!showAddAdvertiser)

    return (
      <Modal to={to} title="Advertisers">
        {/*{showAddAdvertiser && <AddAdvertiserModal onClose={handleAddModal} />}*/}

        <Route path={`${match.url}/new`} component={AdvertiserAdd} />

        <div className="vertical top left per-100">
          <Form className="horizon left middle per-100 p-10">
            <CustomInput
              placeholder="Search Advertiser"
              className="per-100"
              name="search"
              icon="search"
            />
          </Form>
          <div className="advertisers-list-modal per-100">
            <table className="list-table">
              <thead>
                <tr>
                  <td>Advertiser</td>
                  <td>Advertiser Sale Amount</td>
                  <td>Advertiser Network</td>
                  <td>Advertiser Comm.Rate</td>
                  <td>Evet Sharing Rate</td>
                  <td>Evet Earning Rate</td>
                  <td />
                </tr>
              </thead>
              <tbody>
                {list.map(i => (
                  <Row
                    advertiser={i.advertiser}
                    earning_percentage={i.earning_percentage}
                    share_percentage={i.share_percentage}
                    id={i.id}
                    key={i.id}
                  />
                ))}
              </tbody>
            </table>
          </div>
          <div className="horizon center middle per-100 p-10">
            <Button
              // onClick={handleAddModal}
              to={`${match.url}/new`}
              label="Add Advertiser"
              className="per-100"
            />
          </div>
        </div>
      </Modal>
    )
  })
)
