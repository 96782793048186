import {FaCircle} from 'react-icons/all'
import React from 'react'

export const CardStatus = ({status, internalStatus, networkStatus}) => {
  let level = 0

  //TODO rejected evet status required in here
  // if(internalStatus === 'IGNORED' || internalStatus === 'REJECTED')
  //   level = -1
  if (networkStatus === 'JOINED') {
    level = 1
    if (internalStatus === 'CONNECTED') {
      level = 2
      if (status === 'ACTIVE') level = 3
    }
  }

  return (
    <div className={'offer-status level-' + level}>
      <div className="status-bar ">
        <span />
        <span />
        <span />
      </div>
      <div className="status-info shadow-box">
        <div className="item">
          <FaCircle />
          <p>{level > 0 ? 'Joined on network' : 'Not joined on network'}</p>
        </div>
        <div className="item">
          <FaCircle />
          <p>{level > 1 ? 'Connected to evet' : 'Not connected to evet'}</p>
        </div>
        <div className="item">
          <FaCircle />
          <p>{level > 2 ? 'Activated' : 'Not activated'}</p>
        </div>
      </div>
    </div>
  )
}
