import moment from 'moment/moment'
import React from 'react'
import {MoreLess} from '@evet/library'

export const DateTime = ({
  children,
  format = 'MMM D, YYYY',
  timestamp = null,
}) => {
  const date = timestamp ? moment.unix(timestamp * 0.001) : moment(children)
  return (
    <MoreLess
      shortContent={date.format(format)}
      content={date.format('MMM D, YYYY hh:mm A')}
    />
  )
}
