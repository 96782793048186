import React from 'react'

export const Tab = ({label, value, onClick, index, selected}) => {
  return (
    <li
      onClick={() => onClick(index)}
      className={
        index === selected ? 'react-tabs__tab--selected' : 'react-tabs__tab'
      }>
      <p>{label}</p>
      <span>{value}</span>
    </li>
  )
}
