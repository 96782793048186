import {inject, observer} from 'mobx-react'
import React, {useState} from 'react'
import {Button, DateTime} from 'components'
import {MoreOptions, EditableInput} from '@evet/library'
import {BsCardText} from 'react-icons/all'
// import {Link} from 'react-router-dom'
import {NetworkDetail} from './Modals/NetworkDetail'
import {commissionParser} from 'helpers'
import {useRouteMatch} from 'react-router-dom'

export const Row = inject(
  'UIStore',
  'AdvertiserStore',
  'LibraryStore'
)(
  observer(({id, data, parent: advertiserID, ...props}) => {
    const match = useRouteMatch()
    const {name, network_id} = data
    const [showModal, setShowModal] = useState(false)
    const handleShowModal = () => setShowModal(!showModal)

    const handleConnect = async () => {
      const res = await props.LibraryStore.patch(id, {
        advertiser_id: advertiserID,
      })
      if (res) {
        await props.AdvertiserStore.read({
          id: advertiserID,
          params: {similar: true},
        })
      }
      return res
    }

    const handleDisconnect = async () => {
      const res = await props.LibraryStore.patch(id, {
        advertiser_id: null,
      })
      if (res)
        await props.AdvertiserStore.read({
          id: advertiserID,
          params: {similar: true},
        })
      return res
    }

    const handlePrimary = async () => {
      const res = await props.LibraryStore.patch(id, {
        is_primary: 1,
      })
      if (res)
        await props.AdvertiserStore.read({
          id: advertiserID,
          params: {similar: true},
        })
      return res
    }

    const handleCommChange = async (advertiserID, data) => {
      const res = await props.LibraryStore.patch(id, data)
      if (res)
        await props.AdvertiserStore.read({
          id: advertiserID,
          params: {similar: true},
        })
      return res
    }
    const [networkCommissionText, evetCommissionText] = commissionParser(
      data || {}
    )

    return (
      <tr key={data.id}>
        <td>
          {showModal && (
            <NetworkDetail
              title={`ID ${id} | ${name} | ${network_id.toUpperCase()}`}
              id={id}
              onClose={handleShowModal}
            />
          )}
          <div className="pt-10 pb-10">
            <p className="sz-12 fw-700">{data.name}</p>
            <p>{(data.countries || []).map(_ => data.name).join(', ')}</p>
          </div>
        </td>
        <td>{data.currency || '-'}</td>
        <td>{data.created_at && <DateTime date={data.created_at} />}</td>
        <td>{data.connected_at && <DateTime date={data.connected_at} />}</td>
        <td>{networkCommissionText}</td>
        <td>
          <EditableInput
            advertiserID={data.id}
            onSubmit={handleCommChange}
            count={Number(data.share_percentage || 0)}
            type="number"
          />
        </td>
        <td>{evetCommissionText}</td>
        <td>{data.network_status}</td>
        <td>
          {data.is_primary ? <strong>PRIMARY</strong> : data.internal_status}
        </td>
        <td>
          <Button
            className="basic-button"
            to={'/offers?network_advertiserID=' + data.id}>
            {data.link_count || '-'}
          </Button>
        </td>
        <td>
          <Button
            // onClick={handleShowModal}
            to={`${match.path}/${advertiserID}/networks/${id}`}
            className="basic-button">
            <BsCardText />
          </Button>
        </td>
        <td width="40">
          <div className="per-10 horizon center middle">
            <MoreOptions
              offset="bl"
              links={
                <ul>
                  <li>
                    {data.internal_status === 'FREE' ? (
                      <Button onClick={handleConnect}>Connect</Button>
                    ) : !data.is_primary ? (
                      <Button onClick={handleDisconnect}>Disconnect</Button>
                    ) : (
                      ''
                    )}
                  </li>
                  {!data.is_primary && data.internal_status !== 'FREE' && (
                    <li>
                      <Button onClick={handlePrimary}>Set primary</Button>
                    </li>
                  )}
                </ul>
              }
            />
          </div>
        </td>
      </tr>
    )
  })
)
