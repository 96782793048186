import {request} from 'helpers'

export class Services {
  static create = async () => null

  static read = async params => {
    return await request.get(`/advertisers/links/`, params)
  }

  static patch = async ({id, data}) =>
    await request.patch(`/advertisers/links/${id}/`, data)

  static detail = async ({id, fields = null, params = null}) => {
    if (fields)
      return await request.get(`/advertisers/links/${id}/properties/`, {
        ...params,
        field: fields,
      })
    return await request.get(`/advertisers/links/${id}/`, params)
  }

  static delete = async () => null

  static activate = async ({link_id, status = 'ACTIVE'}) => {
    return await request.patch(`/advertisers/links/${link_id}/`, {status})
  }
}
