import React, {useRef, useState} from 'react'
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs'
import {Button} from 'components'

import {
  DiJavascript1,
  FiCode,
  FiExternalLink,
  RiFileCopy2Line,
} from 'react-icons/all'

export const ShareCodes = ({sharing}) => {
  const [message, setMessage] = useState('')
  const htmlCode = useRef(null)
  const jsCode = useRef(null)
  const tracking = useRef(null)
  const destination = useRef(null)

  const copyToClipboard = type => {
    try {
      const textArea = document.createElement('textarea')
      switch (type) {
        case 'htmlCode':
          textArea.value = htmlCode.current.textContent
          break
        case 'jsCode':
          textArea.value = jsCode.current.textContent
          break
        case 'tracking':
          textArea.value = tracking.current.textContent
          break
        case 'destination':
          textArea.value = destination.current.textContent
          break
        default:
          break
      }
      document.body.appendChild(textArea)
      textArea.select()
      document.execCommand('Copy')
      textArea.remove()
      setMessage('Successfully copied')
      setTimeout(() => {
        setMessage('')
      }, 2000)
    } catch {
      setMessage('Copy failed')
      setTimeout(() => {
        setMessage('')
      }, 2000)
    }
  }

  return (
    <React.Fragment>
      <Tabs>
        <TabList className="tab-menu">
          {sharing.html_code && (
            <Tab>
              <FiCode />
              HTML
            </Tab>
          )}

          {sharing.javascript_code && (
            <Tab>
              <DiJavascript1 />
              JavaScript
            </Tab>
          )}

          {sharing.tracking_url && (
            <Tab>
              <FiExternalLink />
              Click URL
            </Tab>
          )}

          {sharing.destination_url && (
            <Tab>
              <FiExternalLink />
              Destination URL
            </Tab>
          )}
        </TabList>
        {sharing.html_code && (
          <TabPanel>
            <div className=" code-base">
              <div id="scrollBar2" className="code">
                <span ref={htmlCode}>{sharing.html_code}</span>
              </div>
              <Button
                className="copy-clipboard"
                onClick={() => copyToClipboard('htmlCode')}>
                <RiFileCopy2Line />
                Copy to Clipboard
              </Button>
            </div>
          </TabPanel>
        )}
        {sharing.javascript_code && (
          <TabPanel>
            <div className=" code-base">
              <div id="scrollBar2" className="code">
                <span ref={jsCode}>{sharing.javascript_code}</span>
              </div>
              <Button
                className="copy-clipboard"
                onClick={() => copyToClipboard('jsCode')}>
                <RiFileCopy2Line />
                Copy to Clipboard
              </Button>
            </div>
          </TabPanel>
        )}
        {sharing.tracking_url && (
          <TabPanel>
            <div className=" code-base">
              <div id="scrollBar2" className="code">
                <span ref={tracking}>{sharing.tracking_url}</span>
              </div>
              <Button
                className="copy-clipboard"
                onClick={() => copyToClipboard('tracking')}>
                <RiFileCopy2Line />
                Copy to Clipboard
              </Button>
            </div>
          </TabPanel>
        )}
        {sharing.destination_url && (
          <TabPanel>
            <div className=" code-base">
              <div id="scrollBar2" className="code">
                <span ref={destination}> {sharing.destination_url}</span>
              </div>
              <Button
                className="copy-clipboard"
                onClick={() => copyToClipboard('destination')}>
                <RiFileCopy2Line />
                Copy to Clipboard
              </Button>
            </div>
          </TabPanel>
        )}
      </Tabs>
      <div className="horizon center middle per-100 mt-10">
        <span className="sz-12 c-green">{message}</span>
      </div>
    </React.Fragment>
  )
}
