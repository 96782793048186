import {inject, observer} from 'mobx-react'
import React from 'react'
import {Button} from 'components'
import {Row} from './Row'

export const Item = inject('ClientStore')(
  observer(props => {
    const {parent, id, data} = props

    const handleApprove = async () => {
      if (
        await props.ClientStore.patchPublisher({
          id,
          parent,
          data: {status: 'JOINED'},
        })
      ) {
        // const i = props.ClientStore._list
        //   .get(parent)
        //   .publishers.findIndex(i => i.id === id)
        // props.ClientStore._list.get(parent).publishers.splice(i, 1)
      }
    }

    const handleIgnore = async () => {
      await props.ClientStore.patchPublisher({
        id,
        parent,
        data: {status: 'DECLINED'},
      })
    }

    return (
      data && (
        <div className="p-10 per-100 vertical">
          <div className="publisher-library-table">
            <div className="item">
              <span>PUBID: {data.id}</span>
              <p>{data.name}</p>
            </div>
            <div className="item">
              <span>Region</span>
              <p>{data.country.name}</p>
            </div>
            <div className="item">
              <span>Services Area</span>
              <p>
                {data.service_areas.map(i => i.country.country_code).join(', ')}
              </p>
            </div>
            <div className="item">
              <span>Status</span>
              <p>{data.status}</p>
            </div>
            <div className="item horizon center middle">
              <Button
                className="apply-button light small"
                onClick={handleApprove}>
                Approve
              </Button>
              <Button
                className="alert-button light small ml-6"
                onClick={handleIgnore}>
                Decline
              </Button>
            </div>
          </div>
          <table className="list-table ">
            <tbody>
              {data.properties
                .filter(i => i.status !== 'JOINED')
                .map(i => (
                  <Row
                    key={'row-' + i.id}
                    id={i.id}
                    data={i}
                    parent={id}
                    grand={parent}
                  />
                ))}
            </tbody>
          </table>
        </div>
      )
    )
  })
)
