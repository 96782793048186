import {qs, Select as CustomSelect} from '@evet/library'
import {DynamicFilter, HeadLine} from 'components'
import {Button} from 'components/Button'
import {SaveFilter} from 'components/Filter/SaveFilter'
import {SelectFilter} from 'components/Filter/SelectFilter'
import {Table} from 'components/Table'
import {inject, observer} from 'mobx-react'
import {GrandTotal} from 'pages/Reporting/GrandTotal/GrandTotal'
import React, {useEffect, useState} from 'react'
import {AiOutlineDown, AiOutlineUp} from 'react-icons/all'
import {useHistory, useLocation} from 'react-router-dom'
import useDeepCompareEffect from 'use-deep-compare-effect'
import {ReportSwitch} from 'components/Table/Types/ReportSwitch'

export const Transactions = inject(
  'TransactionStore',
  'FilterStore',
  'SystemMessageStore'
)(
  observer(props => {
    const [open, setOpen] = useState(true)
    const handleShow = () => setOpen(!open)
    const history = useHistory()
    const location = useLocation()
    const params = qs.parse(location.search)
    const {report_type} = params

    // GET CONFIG DATA for Dynamic Filtering, Custom Table Viewing and Filter Saving
    useEffect(() => {
      props.TransactionStore.configure({type: report_type}).then()
    }, [props.TransactionStore, report_type])
    // GET TABLE DATA
    useDeepCompareEffect(() => {
      props.TransactionStore.read({
        params: {
          ...params,
        },
      })
    }, [params, props.TransactionStore])
    const {config, state, meta, list} = props.TransactionStore

    const types = config.types || []
    const formElements = config.formElements || []
    const columns = config.columns || []
    const filters = config.filters || [] // yani saved_reports
    const activeFilter = filters.find(i => i.query_string === location.search)

    const handleReportType = report_type => {
      history.push('?' + qs.stringify({report_type}))
    }

    const handleSaveFilter = async data => {
      const filter = filters.find(i => i.name === data.name) // ayni isimde filtre varmi kontrolu icin
      return activeFilter && activeFilter.name !== data.name
        ? props.SystemMessageStore.handleError({
            code: 400,
            message: `You already have the report as ${activeFilter.name}`,
          })
        : filter
        ? await props.FilterStore.update({
            id: filter.id,
            data: {
              name: data.name,
              query_string: location.search,
              report_type_id: params.report_type, // [grup_1, grup_2]
            },
          }).then(() => props.TransactionStore.configure({type: report_type}))
        : await props.FilterStore.create({
            name: data.name,
            query_string: location.search,
            report_type_id: params.report_type,
          }).then(() => props.TransactionStore.configure({type: report_type}))
    }

    const handleActiveFilter = qs => history.push(qs)
    const handleDeleteFilter = async id =>
      await props.FilterStore.delete({id}).then(() =>
        props.TransactionStore.configure({type: report_type})
      )

    const CustomSelectFilter = props => (
      <SelectFilter
        label="Saved Reports"
        className="per-100"
        list={filters}
        onChange={handleActiveFilter}
        onDelete={handleDeleteFilter}
        {...props}
      />
    )
    const CustomSaveFilter = props => (
      <SaveFilter
        activeFilter={activeFilter}
        onSave={handleSaveFilter}
        className="apply-button large"
        {...props}
      />
    )
    const extraColumns = []
    if (
      config.report_switch &&
      config.report_switch.compatible_reports &&
      config.report_switch.compatible_reports.length
    ) {
      extraColumns.push({
        key: 'report_switch',
        render: row => {
          return (
            <ReportSwitch
              types={config.report_switch.compatible_reports}
              field={config.report_switch.field}
              data={row}
            />
          )
        },
      })
    }

    return (
      <section className="vertical center">
        <HeadLine title="Transactions" className="sz-30 fw-300">
          <div className="options per-70 horizon right middle" />
        </HeadLine>
        <section className="white-bg per-100 vertical center top light-bb-1 ">
          <div className="container vertical top between pt-10">
            <DynamicFilter
              hide={!open}
              SaveFilter={CustomSaveFilter}
              elements={formElements}
              prefix={
                <div className="item">
                  <CustomSelect
                    label="Report"
                    // placeholder="Commission Detail"
                    name="report_type"
                    options={types}
                    onChange={handleReportType}
                    className="per-100"
                  />
                </div>
              }>
              <div className="per-100 pb-10 horizon middle between light-bb-1">
                {filters.length ? (
                  <div className="section per-30">
                    <div className="item">
                      <CustomSelectFilter />
                    </div>
                  </div>
                ) : (
                  <div />
                )}
                <Button
                  className="secondary-button per-10"
                  onClick={handleShow}>
                  {open ? <AiOutlineDown /> : <AiOutlineUp />}
                  {open ? 'Hide Filter' : 'Show Filter'}
                </Button>
              </div>
            </DynamicFilter>
          </div>
        </section>
        {!report_type ? (
          <div>Select a report type first</div>
        ) : (
          <div className="per-90">
            <Table
              data={list}
              columns={columns}
              pins={['sale']}
              loading={state === 'listing'}
              SelectFilter={SelectFilter}
              SaveFilter={CustomSaveFilter}
              meta={{limit: meta.limit, count: meta.count, offset: meta.offset}}
              extraColumns={extraColumns}
            />
          </div>
        )}
        <div className="per-90">
          <GrandTotal className="mt-20" totals={meta.grand_totals} />
        </div>
      </section>
    )
  })
)
