import {action, computed, makeObservable, observable, toJS} from 'mobx'
import {Offer as Model, OfferMeta as Meta} from '../models'
import {OfferServices as Service} from '../services'

export default class OfferStore {
  _list = new observable.map()
  _item = {}
  state = 'initial'
  meta = {}

  constructor(Stores) {
    this.stores = Stores

    makeObservable(this, {
      _list: observable,
      _item: observable,
      state: observable,
      meta: observable,

      list: computed,
      item: computed,

      read: action,
      activate: action,
      getItemById: action,
      getListItemByID: action,

      fetchSuccess: action.bound,
      handleError: action.bound,
    })
  }

  get list() {
    return [...this._list.values()]
  }

  get item() {
    return toJS(this._item)
  }

  getListItemByID = id => this._list.get(id) || new Model({})

  read({id = null, params = {}}) {
    if (id) {
      this.state = 'getting'
      return Service.detail({id, params}).then(res => {
        if (res) {
          const item = new Model(res.item || {})
          this._item = item
          this._list.set(item.id, item)
        }
        this.state = 'done'
      }, this.handleError)
    } else {
      this.state = 'listing'
      return Service.read(params).then(this.fetchSuccess, this.handleError)
    }
  }

  /**
   *
   * @param id : item_id
   * @param data: object
   * @returns {Promise<void>}
   */
  patch = async ({id, data}) => {
    return await Service.patch({id, data})
      .then(res => {
        this._list.set(id, new Model(res.item))
        return true
      })
      .catch(error => this.stores.SystemMessageStore.handleError(error))
  }

  activate = async data => {
    if (!data) return false
    return await Service.activate(data)
      .then(res => {
        const item = new Model(res.item || {})
        this._list.set(item.id, item)
      })
      .catch(error => this.stores.SystemMessageStore.handleError(error))
  }

  fetchSuccess(res) {
    if (res.items) {
      this.meta = new Meta(res.meta)
      this._list = new observable.map()
      res.items.forEach(i => {
        const item = new Model(i || {})
        this._list.set(item.id, item)
      })
    }
    this.state = 'done'
  }

  handleError(error) {
    this.state = 'error'
    return this.stores.SystemMessageStore.handleError(error)
  }

  getItemById = id => {
    return this._list.get(Number(id)) || new Model({})
  }
}
