import {action, computed, makeObservable, observable, toJS} from 'mobx'
import {
  PropertyAdvertisers as Model,
  PropertyAdvertisersMeta as Meta,
} from '../models'
import {PropertyAdvertisersServices as Service} from '../services'

export default class PropertyAdvertisersStore {
  _list = new observable.map()
  _item = {}
  state = 'initial'
  meta = {}
  _searchable = {}

  constructor(Stores) {
    this.stores = Stores

    makeObservable(this, {
      _list: observable,
      _item: observable,
      _searchable: observable,
      // _selectable: observable,
      state: observable,
      meta: observable,

      list: computed,
      item: computed,

      read: action,
      // create: action,
      // update: action,
      patch: action,
      readAdvertisers: action,
      assignAdvertisers: action,
      delete: action,
      activate: action,
      getItemById: action,
      searchAdvertisers: action,

      fetchSuccess: action.bound,
      handleError: action.bound,
    })
  }

  get list() {
    return [...this._list.values()]
  }

  get item() {
    return toJS(this._item)
  }

  read({id = null, fields = null, params = {}}) {
    if (id) {
      this.state = 'getting'
      Service.detail({id, params}).then(res => {
        if (res) {
          this._item = new Model(res.item || {})
          this._list.set(this._item.id, this._item)
        }
        this.state = 'done'
      }, this.handleError)
    } else {
      this.state = 'listing'
      Service.read(params).then(this.fetchSuccess, this.handleError)
    }
  }

  patch = async ({
    elder,
    grand,
    parent,
    connectionID,
    data,
    forceRefresh = false,
  }) => {
    return await Service.patch({
      elder,
      grand,
      parent,
      connectionID,
      data,
    }).then(res => {
      if (forceRefresh) this.readAdvertisers({elder, parent, grand})
      return res
    }, this.handleError)
  }

  readAdvertisers({elder, parent, grand}) {
    Service.readAdvertisers({elder, parent, grand}).then(
      this.fetchSuccess,
      this.handleError
    )
  }

  assignAdvertisers = async ({elder, parent, grand, data}) => {
    return await Service.assignAdvertisers({
      elder,
      parent,
      grand,
      data,
    }).then(this.fetchSuccess, this.handleError)
  }

  delete = async ({elder, grand, parent, connectionID}) => {
    return await Service.deleteAdvertiser({
      elder,
      grand,
      parent,
      connectionID,
    }).then(res => this._list.delete(connectionID), this.handleError)
  }

  activate = async data => {
    if (!data) return false
    return await Service.activate(data)
      .then(res => {
        const item = new Model(res.item || {})
        this._list.set(item.id, item)
      })
      .catch(error => this.stores.SystemMessageStore.handleError(error))
  }

  fetchSuccess(res) {
    if (res.items) {
      this.meta = new Meta(res.meta)
      this._list = new observable.map()
      res.items.forEach(i => {
        const item = new Model(i || {})
        this._list.set(item.id, item)
      })
    }
    return (this.state = 'done')
  }

  handleError(error) {
    this.state = 'error'
    return this.stores.SystemMessageStore.handleError(error)
  }

  getItemById = id => {
    return this._list.get(id) || new Model({})
  }

  async searchAdvertisers({elder, grand, parent, search_text}) {
    // if(search_text && search_text.length > 2)
    return (
      toJS(this._searchable[search_text]) ||
      (this._searchable[search_text] = (
        await Service.searchAdvertisers({
          elder,
          grand,
          parent,
          search_text,
        })
      )['items'])
    )

    // return []
  }
}
