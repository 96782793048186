import {Modal} from 'components'
import {inject, observer} from 'mobx-react'
import React from 'react'
import {Form, Input, Submit} from '@evet/library'
import {useHistory, useParams} from 'react-router-dom'

export const Edit = inject(
  'ClientStore',
  'OfferStore'
)(
  observer(props => {
    const history = useHistory()
    const {id} = useParams()
    const {name, description} = props.OfferStore.getListItemByID(id)

    const handleSubmit = async data => {
      if (await props.OfferStore.patch({id, data})) history.goBack()
    }

    return (
      <Modal title={name || 'Loading...'} closeOnOutside={false}>
        <div className="p-10 vertical">
          <Form onSubmit={handleSubmit} defaultValues={{name, description}}>
            <Input label="Name" name="name" />
            <Input
              label="Description"
              name="description"
              multiline
              rows="3"
              className="mt-10"
            />
            <Submit className="mt-10 per-100">Save</Submit>
          </Form>
        </div>
      </Modal>
    )
  })
)
