import React, {useState} from 'react'
import {AiOutlineLineChart, MdRemove} from 'react-icons/all'
import {Tab} from './Tab'
import {BarChart} from './BarChart'
import {LineChart} from './LineChart'
import {AreaChart} from './AreaChart'
import {defaultColors, defaultPassiveColor} from './_colors'

export const Charts = ({
  title = 'Charts',
  sets = {},
  charts = [],
  height = 300,
  colors = defaultColors,
  passiveColor = defaultPassiveColor,
  hideShowButton = true,
}) => {
  const [chartShow, setChartShow] = useState(true)
  const [tabIndex, setTabIndex] = useState(0)
  const handleShow = () => {
    setChartShow(!chartShow)
  }
  const chart = charts[tabIndex] || {bars: []}
  const type = chart.type || 'BarChart'
  const set = sets[chart.set] || null
  return (
    <section className="reporting-summary vertical center">
      <div className="reporting-summary-head per-100">
        <p className="sz-20 ">{title}</p>
        <div className="horizon right middle per-50">
          {hideShowButton && (
            <button className="btn basic" onClick={handleShow}>
              {chartShow ? (
                <span className="horizon middle left">
                  <MdRemove /> Hide Chart
                </span>
              ) : (
                <span className="horizon middle left">
                  <AiOutlineLineChart /> Show Chart
                </span>
              )}
            </button>
          )}
        </div>
      </div>
      <div
        className={
          'reporting-chart-base horizon per-100 ' +
          (!hideShowButton || chartShow)
        }>
        <div className="react-tabs">
          <ul>
            {charts.map((i, k) => (
              <Tab
                key={'chart-tab-' + k}
                label={i.label}
                value={i.value}
                index={k}
                selected={tabIndex}
                onClick={setTabIndex}
              />
            ))}
          </ul>
          <div className="react-tabs__tab-panel">
            {type === 'BarChart' && (
              <BarChart
                height={height}
                data={set}
                bars={chart.bars}
                colors={colors}
                passiveColor={passiveColor}
                customTooltipField={chart.customTooltipField}
                passiveFields={chart.passiveFields}
              />
            )}
            {type === 'LineChart' && (
              <LineChart
                height={height}
                data={set}
                lines={chart.bars}
                colors={colors}
                passiveColor={passiveColor}
                customTooltipField={chart.customTooltipField}
                passiveFields={chart.passiveFields}
              />
            )}
            {type === 'AreaChart' && (
              <AreaChart
                height={height}
                data={set}
                lines={chart.bars}
                colors={colors}
                passiveColor={passiveColor}
                customTooltipField={chart.customTooltipField}
                passiveFields={chart.passiveFields}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
