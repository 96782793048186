import {action, computed, makeObservable, observable, toJS} from 'mobx'
import {Redirection as Model, RedirectionMeta as Meta} from '../models'
import {RedirectionServices as Service} from '../services'

export default class RedirectionStore {
  _list = new observable.map()
  _item = {}
  state = 'initial'
  meta = {}

  constructor(Stores) {
    this.stores = Stores

    makeObservable(this, {
      _list: observable,
      _item: observable,
      state: observable,
      meta: observable,

      list: computed,
      item: computed,

      read: action,
      // create: action,
      // update: action,
      // patch: action,
      // getItemById: action,
      // selectable: action,
      // searchable: action,
      // setItem: action,

      fetchSuccess: action.bound,
      handleError: action.bound,
    })
  }

  get list() {
    return [...this._list.values()]
  }

  get item() {
    return toJS(this._item)
  }

  read({id = null, params = {}}) {
    if (id) {
      this.state = 'getting'
      Service.detail({id}).then(res => {
        if (res) {
          this._item = new Model(res || {})
        }
        this.state = 'done'
      }, this.handleError)
    } else {
      this.state = 'listing'
      Service.read(params).then(this.fetchSuccess, this.handleError)
    }
  }

  fetchSuccess(res) {
    if (res.data) {
      this.meta = new Meta({count: res.count, limit: res.limit, skip: res.skip})
      this._list = new observable.map()
      res.data.forEach(i => {
        const item = new Model(i || {})
        this._list.set(item.id, item)
      })
    }
    this.state = 'done'
  }

  handleError(error) {
    this.state = 'error'
    return this.stores.SystemMessageStore.handleError(error)
  }
}
