import {request} from 'helpers'

///publishers/clients/
export class Services {
  static create = async data =>
    await request.post('/publishers/clients/', data).then()

  static read = async params =>
    await request.get(`/publishers/clients/`, params)

  static update = async () => null

  static detail = async ({id, fields = null, params = {}}) =>
    fields
      ? await request.get(`/publishers/publishers/${id}/properties/`, {
          ...params,
          field: fields,
        })
      : await request.get(`/publishers/clients/${id}/`, params)

  static websiteDetail = async ({client_id, id}) =>
    await request.get(`/publishers/clients/${client_id}/publishers/${id}/`, {})

  static delete = async () => null

  static addPublisherWebsite = async ({elder, data}) =>
    await request.post(`/publishers/clients/${elder}/publishers/`, data)

  static addProperty = async ({elder, grand, data}) =>
    await request.post(
      `/publishers/clients/${elder}/publishers/${grand}/properties/`,
      data
    )

  static addContact = async ({client_id, data}) =>
    await request.post(`/publishers/clients/${client_id}/users/`, data)

  static deleteProperty = async ({elder, grand, id}) =>
    await request.delete(
      `/publishers/clients/${elder}/publishers/${grand}/properties/${id}/`
    )

  // static selectable = async ({field}) => await request.get(`/advertisers`, {field})
  static searchable = async ({field, search_text}) =>
    await request.get(`/publishers/publishers/`, {field, search_text})

  static patchClient = async data =>
    await request.patch(`/publishers/clients/${data.id}/`, data.data)

  static patchClientPublisher = async data =>
    await request.patch(
      `/publishers/clients/${data.parent}/publishers/${data.id}/`,
      data.data
    )

  static patchClientPublisherProperty = async data =>
    await request.patch(
      `/publishers/clients/${data.grand}/publishers/${data.parent}/properties/${data.id}/`,
      data.data
    )
}
