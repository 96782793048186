import {Button as LibButton} from '@evet/library'
import React from 'react'
import {useHistory} from 'react-router-dom'

export const Button = ({onClick = () => null, to, ...props}) => {
  const history = useHistory()
  const handleClick = () => (to ? history.push(to) : onClick())

  return <LibButton onClick={handleClick} {...props} />
}
