import {inject, observer} from 'mobx-react'
import React from 'react'
import {AiOutlineDelete} from 'react-icons/all'
import {useRouteMatch} from 'react-router'
import {useParams} from 'react-router'
import {Button} from 'components'

export const Row = inject('UserStore')(
  observer(({id, data, ...props}) => {
    const match = useRouteMatch()
    const {delete: deleteUser} = props.UserStore
    const {
      clientID: client_id,
      publisherID: publisher_id,
      propertyID: property_id,
    } = useParams()

    const handleDelete = async () =>
      deleteUser({client_id, publisher_id, property_id, user_id: id})

    return (
      <React.Fragment>
        <tr>
          <td>{data.name} </td>
          <td>{data.email || '-'}</td>
          <td>
            {data.direct_role || (data.has_lower_level_role ? 'None' : '-')}
          </td>
          <td>
            <div className="horizon right middle">
              <Button
                className="edit-button small sz-12"
                to={`${match.url}/${data.id}/permissions`}>
                Manage
              </Button>
              <Button
                onClick={handleDelete}
                icon={<AiOutlineDelete />}
                className="basic-button ml-10 sz-12"
                confirm={{title: 'are you sure?'}}
              />
            </div>
          </td>
        </tr>
      </React.Fragment>
    )
  })
)
