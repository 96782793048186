import React from 'react'

const currencies = currency =>
  ({
    AUD: {icon: <span>AU＄</span>, name: 'Australian Dollar'},
    BRL: {
      icon: (
        <span>
          <span style={{marginRight: -2}}>R</span>＄
        </span>
      ),
      name: 'Brazilian Real',
    },
    CAD: {icon: <span>C＄</span>, name: 'Canadian Dollar'},
    CHF: {icon: <span>CHF</span>, name: 'Swiss Franc'},
    EUR: {icon: <span style={{margin: '0 2px'}}>€</span>, name: 'Euro'},
    GBP: {icon: <span>￡</span>, name: 'British Pound Sterling'},
    USD: {icon: <span>＄</span>, name: 'United States Dollar'},
  }[currency] || {icon: <span>＄</span>, name: 'United States Dollar'})

export const Currency = ({
  initial_amount = 0,
  amount = 0,
  currency = 'USD',
  children = 0,
}) => (
  <div className="table-type-currency" title={currencies(currency).name}>
    {currencies(currency).icon}
    {initial_amount ? (
      <s style={{marginRight: 2}}>
        {String(initial_amount).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
      </s>
    ) : (
      ''
    )}
    {String(amount || children).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
  </div>
)
