import React from 'react'
import {
  AreaChart as RechartsAreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'
import {defaultColors, defaultPassiveColor} from './_colors'

export const AreaChart = ({
  data = [],
  lines = [],
  colors = defaultColors,
  passiveColor = defaultPassiveColor,
  height = 300,
  customTooltipField = null,
  passiveFields = ['All others'],
}) => {
  const getField = name => {
    console.log({data})
    return ((data && Array.isArray(data) ? data : []).find(
      i => i.name === name
    ) || {})[customTooltipField]
  }

  const getColors = i => {
    return colors[i] || passiveColor
  }

  const CustomTooltip = ({active, payload, label}) => {
    const val = getField(label)
    if (active) {
      return (
        <div className="custom-tooltip">
          <p className="label">
            <span>{label}</span>
            {val ? `: ${val}` : ''}
          </p>
          {payload.map((i, k) => (
            <p key={`${label}-${i.name}`} className="prop">
              <span
                style={{
                  borderLeft: `3px solid ${getColors(
                    passiveFields.includes(i.dataKey) || k
                  )}`,
                  paddingLeft: 3,
                }}>
                {i.name}
              </span>
              :{i.value}
            </p>
          ))}

          {/*<p className="desc">Anything you want can be displayed here.</p>*/}
        </div>
      )
    }

    return null
  }

  return (
    <ResponsiveContainer height="100%" width="100%">
      <RechartsAreaChart height={height} data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="name"
          axisLine={{stroke: '#fff'}}
          tick={{fill: '#878787', fontSize: 12}}
        />
        <YAxis
          axisLine={{stroke: '#fff'}}
          tick={{fill: '#878787', fontSize: 12}}
        />

        <Tooltip content={<CustomTooltip />} />
        <Legend verticalAlign="bottom" align="center" layout="horizontal" />
        {lines.map((i, k) => (
          <Area
            dataKey={i.dataKey}
            stackId={i.stackId}
            stroke={getColors(passiveFields.includes(i.dataKey) || k)}
            fill={getColors(passiveFields.includes(i.dataKey) || k)}
          />
        ))}
      </RechartsAreaChart>
    </ResponsiveContainer>
  )
}
