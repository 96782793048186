import {inject, observer} from 'mobx-react'
import React from 'react'
import {Route, useRouteMatch} from 'react-router'
import {Link, useLocation, useParams} from 'react-router-dom'
import useDeepCompareEffect from 'use-deep-compare-effect'
import {BsFillGearFill} from 'react-icons/all'
import {Loader, MoreOptions, qs} from '@evet/library'
import {Button, HeadLine} from 'components'
import {Card} from './Card'
import {
  ClientEdit,
  PublisherAdd,
  PublisherEdit,
  PropertyAdd,
  Advertisers,
  Contacts,
  Users,
  ContactAdd,
  UserAdd,
  UserPermissions,
  AdvertiserAdd,
} from './Modals'

export const Client = inject('ClientStore')(
  observer(props => {
    const match = useRouteMatch()
    const {search} = useLocation()
    console.log(match, match.path)

    const location = useLocation()
    const params = qs.parse(location.search)
    const {clientID: id} = useParams()

    const {read, state, item} = props.ClientStore
    useDeepCompareEffect(() => {
      read({params, id})
    }, [params])

    const {name, country = {}, publishers = [], publisher_count = 0} = item
    // const initialStep = parent ? 3 : 2
    return (
      <React.Fragment>
        <section className="vertical center">
          <HeadLine
            title={
              <div className="horizon left middle">
                {name}
                <Button
                  className="edit-button light circle ml-10"
                  label="Client settings "
                  icon={<BsFillGearFill />}
                  to={`${match.url}/edit${search}`}
                />
              </div>
            }
            className="sz-30 fw-300">
            <div className="options horizon right middle">
              <div className="item">
                <p>CID</p>
                <span>{id}</span>
              </div>
              <div className="item">
                <p>Contacts</p>
                <span className="horizon middle">
                  <Link to={`/clients/${id}/contacts${search}`}>0</Link>
                  <Button
                    to={`/clients/${id}/contacts/new${search}`}
                    className="add-button light  circle  small  ml-10"
                    label="Add New"
                  />
                </span>
              </div>
              <div className="item">
                <p>Region</p>
                <span>{country.name || '-'}</span>
              </div>
              <div className="item">
                <p>Publishers</p>
                <span>{publisher_count}</span>
              </div>
              <div className="item">
                <p>Uses</p>
                <span>
                  3
                  <Button
                    className="basic-button  ml-6"
                    to={`/clients/${id}/users${search}`}>
                    view
                  </Button>
                </span>
              </div>
            </div>
          </HeadLine>
          <div className="container mt-20 horizon top between">
            <div className="per-100">
              <div className="section-title horizon between middle">
                <Button
                  className="add-button"
                  label="Create New Publisher URL"
                  to={`/clients/${id}/publishers/new${search}`}
                />
                <MoreOptions
                  offset="c"
                  innerClass="top right"
                  links={
                    <ul>
                      <li>
                        <Button className="horizon">Approve</Button>
                      </li>
                      <li>
                        <Button className="horizon">Set Pending</Button>
                      </li>
                      <li>
                        <Button className="horizon">Delete</Button>
                      </li>
                    </ul>
                  }
                />
              </div>
              <div className="mt-20">
                {state === 'listing' ? (
                  <Loader />
                ) : (
                  <React.Fragment>
                    {/*TODO PUBLISHER UPDATE NOT WORKS*/}
                    {publishers.map(i => (
                      <Card key={'publisher-' + i.id} client_id={id} data={i} />
                    ))}
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </section>
        {/*  MODALS */}
        {/*  !!! DANGER ZONE */}
        {/*  Bu sayfa yapisal acidan tam bir kaos oldugu icin liste modallarinin icinden
        nested sekilde item modallari route edildiginde guzel problemler yasanmaktadir.
        Bu sebeple asagidaki uzun routing tanimlamalari yapilmistir.
        Degistirmeden once diklatlice inceleyiniz. [GC]*/}

        {/*{[grand, parent].includes('new') && (*/}
        {/*  <Add initialStep={initialStep} to={`/clients/${id}`} />*/}
        {/*)}*/}
        {/*{contactID === 'new' && <AddContactModal to={`/clients/${id}`} />}*/}
        {/*{prefix2 === 'contacts' && <ContactModal to={`/clients/${id}`} />}*/}
        {/*{advertisersPage && <AdvertiserModal to={`/clients/${id}`} />}*/}
        {/*{userID && <AddContactModal to={`/clients/${id}`} />}*/}
        {/*{subs.includes('users') && <UserModal to={`/clients/${id}`} />}*/}
        <Route path={`${match.path}/edit`} component={ClientEdit} />

        <Route
          path={`${match.path}/publishers/:publisherID(new)`}
          component={PublisherAdd}
        />
        <Route
          exact
          path={`${match.path}/publishers/:publisherID/edit`}
          component={PublisherEdit}
        />
        <Route
          path={`${match.url}/publishers/:publisherID/properties/:propertyID(new)`}
          component={PropertyAdd}
        />
        {/*<Route*/}
        {/*    path={`${match.url}/publishers/:publisherID/properties/:propertyID`}*/}
        {/*    component={PropertyEdit}*/}
        {/*/>*/}

        <Route
          exact
          path={`${match.path}/:publishers(publishers)?/:publisherID?/:properties(properties)?/:propertyID?/contacts`}
          component={Contacts}
        />
        <Route
          exact
          path={`${match.path}/:publishers(publishers)?/:publisherID?/:properties(properties)?/:propertyID?/contacts/:id(new)`}
          component={ContactAdd}
        />
        <Route
          exact
          path={`${match.path}/:publishers(publishers)?/:publisherID?/:properties(properties)?/:propertyID?/users`}
          component={Users}
        />
        <Route
          exact
          path={`${match.path}/:publishers(publishers)?/:publisherID?/:properties(properties)?/:propertyID?/users/:id(new)`}
          component={UserAdd}
        />
        <Route
          exact
          path={`${match.path}/:publishers(publishers)?/:publisherID?/:properties(properties)?/:propertyID?/users/:id/permissions`}
          component={UserPermissions}
        />
        <Route
          exact
          path={`${match.path}/:publishers(publishers)?/:publisherID?/:properties(properties)?/:propertyID?/advertisers`}
          component={Advertisers}
        />
        <Route
          exact
          path={`${match.path}/:publishers(publishers)?/:publisherID?/:properties(properties)?/:propertyID?/advertisers/:id(new)`}
          component={AdvertiserAdd}
        />

        {/* /MODALS */}
      </React.Fragment>
    )
  })
)
