import React, {useEffect, useState} from 'react'
import {Pagination} from '@evet/library'
import {Loader} from '@evet/library'
import {types} from 'components/Table'

export const List = ({
  model = null,
  params = null,
  loading: _loading = false,
}) => {
  const [loading, setLoading] = useState(_loading)
  const [module, setModule] = useState(model)

  useEffect(() => {
    setLoading(_loading)
  }, [_loading])

  useEffect(() => {
    if (model) setModule(model)
    console.log('useEffect', {model})
  }, [model])

  const handlePage = ({limit, offset}) => {
    console.log('handlePage', {limit, offset})
    setLoading(true)
    model
      .read({
        params: {limit, offset, ...params},
        module: '/' + model.id,
      })
      .then(model => {
        console.log('setModule', model.list)
        setModule(model)
        setLoading(false)
      })
  }

  const {meta = {columns: []}, list = []} = module
  const {columns} = meta

  return (
    <div className="per-100">
      {loading && <Loader background />}
      <table className="list-table ">
        <thead>
          <tr>
            {columns.map(i => (
              <td key={i.field + '-label'}>{i.name}</td>
            ))}
          </tr>
        </thead>
        <tbody>
          {list.map(row => (
            <tr
              key={model.id + '-item-' + row.id}
              id={model.id + '-item-' + row.id}>
              {columns.map(column => {
                return (
                  <td key={(row.key || row.id) + '_' + column.field}>
                    {(
                      types[column.field] ||
                      types[column.type] ||
                      (n => String(n == null ? '' : n))
                    )(row[column.field], row, column)}
                  </td>
                )
              })}
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        className="p-10"
        onClick={handlePage}
        count={meta.count}
        limit={meta.limit}
        offset={meta.offset}
      />
    </div>
  )
}
