import {Form, Input, Select, Submit} from '@evet/library'
import {Modal} from 'components'
import {inject, observer} from 'mobx-react'
import React from 'react'
import {useHistory, useParams} from 'react-router-dom'

export const UserAdd = inject('UserStore')(
  observer(props => {
    const history = useHistory()
    const {create} = props.UserStore
    const {
      clientID: client_id,
      publisherID: publisher_id,
      propertyID: property_id,
    } = useParams()

    const handleSubmit = async data => {
      await create({client_id, publisher_id, property_id, data}).then(() => {
        history.goBack()
      })
    }
    return (
      <Modal title="New User" className="per-60">
        <Form
          defaultValues={{}}
          onSubmit={handleSubmit}
          className="per-100 vertical between top p-10 border-box">
          <div className="horizon between per-100 top">
            <div className="vertical left per-48 mt-10">
              <Input className="per-100" label="First Name" name="first_name" />
              <Input className="per-100" label="Last Name" name="last_name" />
            </div>

            <div className="vertical left per-48 mt-10">
              <Input className="per-100" label="E-mail" name="email" />
              <Select
                className="per-100"
                label="Role"
                name="role"
                options={[
                  // {label: 'Manager', value: 'manager'},
                  // {label: 'User', value: 'user'},
                  // {label: 'Owner', value: 'owner'},
                  {label: 'Superuser', value: 'SUPERUSER'},
                  {label: 'Viewer', value: 'VIEWER'},
                ]}
              />
            </div>
          </div>

          <div className="vertical right per-100 mt-10">
            <Submit>Submit</Submit>
          </div>
        </Form>
      </Modal>
    )
  })
)
