import {request} from 'helpers'

export class Services {
  static create = async ({client_id, data}) =>
    await request.post(`/publishers/clients/${client_id}/users/`, data)

  static read = async ({client_id, params}) =>
    await request.get(`/publishers/clients/${client_id}/users/`, params)

  static update = async ({client_id, data}) =>
    await request.patch(
      `/publishers/clients/${client_id}/users/${data.id}/`,
      data
    )

  static delete = async ({client_id, id}) =>
    await request.delete(`/publishers/clients/${client_id}/users/${id}/`)
}
