import React, {useState} from 'react'
import {Button} from 'components'

export const TableSelector = props => {
  const [isOpen, setIsOpen] = useState(false)
  const handleOpen = () => {
    setIsOpen(!isOpen)
  }
  return (
    <div className={'table-selector ' + props.className}>
      <Button
        className={'secondary-button circle ' + (isOpen && 'open')}
        label="List"
        onClick={handleOpen}
        icon={props.icon}
      />
      {isOpen && (
        <div className="dropdown-menu shadow-box">
          <div className="dropdown-title">Column Filter</div>
          <div className="dropdown-body">{props.children}</div>
          {/*<div className="dropdown-action">*/}
          {/*  {props.columnFilter && (*/}
          {/*    <div className="horizon between middle p-10 per-100">*/}
          {/*      <Button label="Reset" className="btn per-49 secondary" />*/}
          {/*      <Button label="Apply" className="btn per-49 primary" />*/}
          {/*    </div>*/}
          {/*  )}*/}
          {/*</div>*/}
        </div>
      )}
    </div>
  )
}
