import {inject, observer} from 'mobx-react'
import React, {useState} from 'react'
import {useParams} from 'react-router-dom'
import useDeepCompareEffect from 'use-deep-compare-effect'
import {CheckBox, Form, Input, Submit} from '@evet/library'
import {Modal} from 'components'

export const AdvertiserAdd = inject(
  'PropertyAdvertisersStore',
  'UIStore'
)(
  observer(props => {
    const {elder, grand, parent} = useParams()
    const [list, setList] = useState([])

    useDeepCompareEffect(() => {
      props.PropertyAdvertisersStore.searchAdvertisers({
        elder,
        grand,
        parent,
        search_text: '',
      }).then(res => setList(res))
    }, [props.PropertyAdvertisersStore, elder, grand, parent])

    const handleSearchChange = async e => {
      const search_text = e.target.value || ''
      const x = await props.PropertyAdvertisersStore.searchAdvertisers({
        elder,
        grand,
        parent,
        search_text,
      })
      setList(x)
    }

    const handleSubmit = async data => {
      const res = await props.PropertyAdvertisersStore.assignAdvertisers({
        elder,
        grand,
        parent,
        data: {advertiser_ids: data.advertiser_ids.map(i => Number(i))},
      })
      if (res) props.onClose()
    }

    return (
      <Modal title="New Advertisers" onClose={props.onClose}>
        <Form className="vertical top left per-100" onSubmit={handleSubmit}>
          <div className="horizon left middle per-100 p-10">
            <Input
              placeholder="Search Advertiser"
              className="per-100"
              name="search"
              icon="search"
              onChange={handleSearchChange}
            />
          </div>
          <div className="advertisers-list-modal per-100">
            <table className="list-table">
              <thead>
                <tr>
                  <td />
                  <td>Advertiser</td>
                  <td>Advertiser Sale Amount</td>
                  <td>Advertiser Network</td>
                  <td>Advertiser Comm.Rate</td>
                  <td>Evet Comm.Rate</td>
                  <td>Earning Comm.Rate</td>
                </tr>
              </thead>
              <tbody>
                {list.map(({id, name}) => (
                  <tr key={'add-advertiser-' + id}>
                    <td>
                      <CheckBox name="advertiser_ids" value={id} />
                    </td>
                    <td>{name}</td>
                    <td>xxxx</td>
                    <td>xxxx</td>
                    <td>%xx</td>
                    <td>%xx</td>
                    <td>%xx</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="horizon center middle per-100 p-10">
            <Submit className="per-100">Add</Submit>
          </div>
        </Form>
      </Modal>
    )
  })
)
