import {request} from 'helpers'

export class Services {
  static signOut = (from = null) => {
    request.deleteCookies()
    const to = process.env.REACT_APP_AUTH + (from ? '?from=' + from : '')
    setTimeout(() => (window.location.href = to), 100)
  }

  static getProfile = async ({client_id = null, user_id = null}) => {
    //TODO endpoint not changed yet
    //if (!client_id || !user_id) return false
    //return await request.get2(`/v2/clients/${client_id}/users/${user_id}/profile`, null, true);
    return await request.get('/account/profile', null, true)
  }
}
