import {inject, observer} from 'mobx-react'
import React from 'react'
import {useHistory, useLocation, useRouteMatch} from 'react-router-dom'
import moment from 'moment/moment'
import {
  AiOutlineStop,
  BiCalendarCheck,
  BiCalendarMinus,
  ImPriceTag,
} from 'react-icons/all'
import {MoreLess} from '@evet/library'
import {Button} from 'components'
import {commissionParser} from 'helpers'
import {CardStatus} from 'pages/Advertiser/Offers/CardStatus'

export const Card = inject('OfferStore')(
  observer(({id, data, networkLabels, ...props}) => {
    const match = useRouteMatch()
    const {search} = useLocation()
    const history = useHistory()
    const [networkCommission, evetCommission] = commissionParser(data || {})

    const handleSimilarOffers = () => {
      if (!data.network_advertiser.id) return
      history.push('?network_advertiser_id=' + data.network_advertiser.id)
    }
    // const handleEditModal = () => {
    //   setOpenEdit(!openEdit)
    // }
    //
    // const handleEdit = async data => {
    //   if (await props.OfferStore.patch({id: data.id, data})) setOpenEdit(false)
    // }

    const handleActivate = async () => {
      await props.OfferStore.patch({id: data.id, data: {status: 'ACTIVE'}})
    }

    const handleDeactivate = async () => {
      await props.OfferStore.patch({id: data.id, data: {status: 'WAITING'}})
    }

    const handleIgnore = async () => {
      await props.OfferStore.patch({id: data.id, data: {status: 'IGNORED'}})
    }

    // const [openGetCode, setOpenGetCOde] = useState(false)
    // const handleGetCode = () => setOpenGetCOde(!openGetCode)

    return (
      <React.Fragment>
        {/*{openGetCode && <GetCode onClose={handleGetCode} data={data} />}*/}
        {/*{openEdit && (*/}
        {/*  <EditOffer*/}
        {/*    id={data.id}*/}
        {/*    onEdit={handleEdit}*/}
        {/*    title={data.network_advertiser.name}*/}
        {/*    onClose={handleEditModal}*/}
        {/*    name={data.name}*/}
        {/*    description={data.description}*/}
        {/*  />*/}
        {/*)}*/}

        <div className="offer-card ">
          <div className="card-head horizon between stretch">
            <span className="sz-12 coupon-code">
              {data.coupon_code && (
                <span>
                  <ImPriceTag className="mr-6" />
                  {data.coupon_code}
                </span>
              )}
            </span>

            <CardStatus
              status={data.status}
              internalStatus={(data.network_advertiser || {}).internal_status}
              networkStatus={(data.network_advertiser || {}).network_status}
            />
          </div>
          <div className="card-base">
            <div className="per-70 vertical left">
              <span className="sz-22 fw-400 c-link">
                {data.network_advertiser.name}
              </span>
              <p className="sz-16 fw-300 mt-6"> {data.name}</p>
              <MoreLess
                content={data.description}
                maxLimit={200}
                fontSize="sz-12 fw-300"
              />
              <a
                href={data.sharing.destination_url}
                className="sz-12 fw-400 mt-10"
                target="_blank"
                rel="noreferrer noopener">
                {data.sharing.destination_url}
              </a>
              <span className="sz-12">
                <BiCalendarCheck className="mr-4" />
                Start Date:{' '}
                {data.start_date
                  ? moment(data.start_date).format('D MMM YYYY')
                  : '-'}
                <BiCalendarMinus className="ml-10 mr-4" />
                End Date:{' '}
                {data.end_date
                  ? moment(data.end_date).format('D MMM YYYY')
                  : '-'}
              </span>
              <div className="tags mt-10">
                {data.tags.map(i => (
                  <span className="mr-6" key={i}>
                    {i}
                  </span>
                ))}
              </div>
            </div>

            <div className="per-30 horizon right">
              <div className="action horizon middle">
                {(data.network_advertiser || {}).internal_status ===
                  'CONNECTED' &&
                  data.status !== 'ACTIVE' && (
                    <Button
                      className="apply-button light "
                      label="Activate"
                      onClick={handleActivate}
                    />
                  )}

                {data.status === 'ACTIVE' && (
                  <React.Fragment>
                    <Button
                      icon={<AiOutlineStop />}
                      className="alert-button light ml-6"
                      label="Deactivate"
                      onClick={handleDeactivate}
                    />
                    <Button
                      className="apply-button ml-6"
                      label="Get Code"
                      to={`${match.url}/${id}/code${search}`}
                    />
                  </React.Fragment>
                )}
                <Button
                  className="edit-button ml-6"
                  to={`${match.url}/${id}/edit${search}`}
                  label="Edit"
                />
              </div>
            </div>
          </div>
          <div className="card-foot">
            <div className="detail per-70">
              <span>
                ID
                <p>: {data.link_id}</p>
              </span>
              <span>
                Network
                <p>: {networkLabels[data.network_advertiser.network_id]}</p>
              </span>
              <span>
                Commission<p>: {networkCommission}</p>
              </span>
              <span>
                Shared Commission<p>: {evetCommission}</p>
              </span>
              <span>
                # of Advertiser Offer
                {data.network_advertiser_link_count > 1 ? (
                  <Button
                    onClick={handleSimilarOffers}
                    className="basic-button">
                    : {data.network_advertiser_link_count}
                  </Button>
                ) : (
                  ' : ' + data.network_advertiser_link_count
                )}
              </span>
            </div>
            <div className="pr-20 vertical middle">
              {data.status !== 'IGNORED' && (
                <Button
                  className="basic-button c-alert"
                  label="Ignore this offer"
                  onClick={handleIgnore}
                />
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  })
)
