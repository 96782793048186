import React, {useState} from 'react'
import {Row} from 'pages/Publisher/Clients/Row'
import useDeepCompareEffect from 'use-deep-compare-effect'
import {useLocation} from 'react-router-dom'
import {Loader, qs} from '@evet/library'
import {inject, observer} from 'mobx-react'

export const Item = inject('ClientAdvertiserStore')(
  observer(props => {
    const location = useLocation()
    const params = qs.parse(location.search)
    const {id} = props
    // const {name, uri, advertiser_count, publisher_type} = data

    const [loading, setLoading] = useState(false)

    useDeepCompareEffect(() => {
      ;(async () => {
        setLoading(true)
        await props.ClientAdvertiserStore.read({client_id: id, params})
        setLoading(false)
      })()
    }, [props.ClientAdvertiserStore, params, id])
    const {list} = props.ClientAdvertiserStore
    //list.filter(i=>i.client_id===client_id)

    return (
      <div className="dropdown" id="scrollBar">
        {loading ? (
          <Loader padding />
        ) : (
          <table className="list-table">
            <thead>
              <tr>
                <td>Publisher</td>
                <td>Property</td>
                <td>Advertiser</td>
                <td />
              </tr>
            </thead>
            <tbody>
              {list
                .filter(i => i.status === 'PENDING')
                .map(i => (
                  <Row key={i.id} id={i.id} data={i} parent={id} />
                ))}
            </tbody>
          </table>
        )}
      </div>
    )
  })
)
